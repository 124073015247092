<template>
  <banner-v3>
    <template #title>
      Turn on Auto Deposits
    </template>
    <template #content>
      Now you can automatically deposit funds into your PeerStreet account.
      <promo-link url="/transactions/auto_deposits/settings"
                  label="Auto Deposits">
        Set up now
      </promo-link>
    </template>
  </banner-v3>
</template>
<script>
import PromoLink from "components/promo/link";
import BannerV3 from "../../banner_v3";

export default {
  name: "PromoBannerAutoDeposits",
  components: { BannerV3, PromoLink },
};
</script>

