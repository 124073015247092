class SocialSharing {
  windowPopup(url, width, height) {
    var left, top, opened;

    left = ($(window).width() / 2) - (width / 2);
    top = (screen.height / 2) - (height / 2);
    opened = window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
    opened.opener = null;
  }

  onDomReady($element) {
    var self = this;

    $element.on("click", function(e) {
      e.preventDefault();
      self.windowPopup($(this).attr("href"), 700, 500);
    });
  }
}

PS.Components.register(SocialSharing, { applyOnlyTo: ".social-share-link" });
window.PS.Components.SocialSharing = SocialSharing;
