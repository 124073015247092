export default class BankStore {
  static data = {
    amount: null,
    predefinedAmount: "",
    serverErrors: "",
  }

  static cleanServerErrors() {
    this.data.serverErrors = "";
  }
}
