<template>
  <div class="order-info">
    <div class="order-info-caption">
      <i class="fa fa-check-circle" />
      <h4>Your order was received!</h4>
    </div>

    <div class="order-info-details-list">
      <div class="order-info-details-list-item">
        <div class="order-info-details-list-item-label">
          Investment Amount
        </div>
        <div class="order-info-details-list-item-value">
          {{ amount | unmask | money(0) }}
        </div>
      </div>
      <div class="order-info-details-list-item">
        <div class="order-info-details-list-item-label">
          Status
        </div>
        <div class="order-info-details-list-item-value">
          <span class="badge badge-initiated">
            AWAITING LOAN CLOSING
          </span>
        </div>
      </div>
    </div>

    <div class="form-buttons">
      <button type="button"
              class="btn-block btn-secondary-outlined"
              @click="reloadPage">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    amount: { type: String, default: "" },
  },

  methods: {
    reloadPage() {
      location.reload();
    },
  },
};
</script>
