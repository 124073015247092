<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="control-wrapper"
             :class="{'has-error': form.errors.phoneNumber}">
          <label class="control-label"
                 for="user-two-factor-auth-phone">
            Phone
          </label>
          <input id="user-two-factor-auth-phone"
                 v-model="phoneNumber"
                 v-mask:us_phone="{clearMaskOnLostFocus: false}"
                 class="form-control"
                 required
                 @blur="updatePhoneNumber($event)">
          <label v-show="form.errors.phoneNumber"
                 class="error control-label">
            {{ form.errors.phoneNumber }}
          </label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="control-wrapper">
          <label class="d-none d-md-block">&nbsp;</label>

          <SendVerificationCodeButton :disabled="sendVerificationDisabled"
                                      :phone-number="form.phoneNumber" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SendVerificationCodeButton from "../../send_verification_code_button";
import form from "../form.js";

export default {
  name: "SmsFields",

  components: {
    SendVerificationCodeButton,
  },

  data() {
    return {
      form: form,
      phoneNumber: "",
    };
  },

  computed: {
    sendVerificationDisabled() {
      return this.form.phoneNumberLengthValid(this.phoneNumber);
    },
  },

  methods: {
    updatePhoneNumber(event) {
      this.form.phoneNumber = event.target.value;
    },
  },
};
</script>
