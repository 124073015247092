Vue.component("LoanDetailsTable", {
  props: {
    loan: { type: Object, required: true },
  },
  computed: {
    loanPath: function() {
      return `/loans/${this.loan.psId}?ps_src=table`;
    },
    propertyKind: function() {
      const kind = this.loan.propertyKind;

      if (kind === "single_family") {
        return "single";
      }
      else if (kind === "hospitality" || kind === "multifamily") {
        return "multi";
      }
      else {
        return "other";
      }
    },
  },
  template: "#v-loan-details-table-template",
});
