import MarketingBanner from "components/marketing_banner";
import PromoBanner from "components/promo/banner";
import BankruptcyClaimBanner from "components/bankruptcy_claim_banner";
import BannerV3Slider from "components/banner_v3_slider";
import AutoInvestmentsIndicator from "components/auto_investments/indicator";
import LearningResourcesSlider from "components/learning_resources_slider";

$(() => {
  new Vue({
    el: document.querySelector(".v-dashboard-controller"),
    components: {
      MarketingBanner,
      PromoBanner,
      BankruptcyClaimBanner,
      BannerV3Slider,
      AutoInvestmentsIndicator,
      LearningResourcesSlider,
    },
  });
});
