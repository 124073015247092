<template>
  <div class="auto-investments-overview">
    <div v-if="flashError"
         class="alert alert-danger"
         role="alert">
      <p>{{ flashError }}</p>
    </div>
    <div class="auto-investments-card">
      <h2>Build a Diversified Portfolio with Ease.</h2>
      <div class="row">
        <div class="col-lg-10 col-md-9">
          <p>
            Reserve your spot in investments that meet your loan criteria &mdash;
            and easily diversify your portfolio &mdash; with just a few clicks.
            We take care of the rest.
          </p>
        </div>
        <div class="col-lg-2 col-md-3">
          <a :class="buttonClass()"
             @click="showSettings">Get Started</a>
        </div>
      </div>
    </div>

    <how-it-works-section />

    <div class="auto-investments-card">
      <h2>Read More</h2>
      <ul class="auto-investments-faq-list">
        <li>
          <a href=" https://info.peerstreet.com/faqs/will-money-invested-automated-investing/"
             target="_blank">
            How will my money be invested?
          </a>
        </li>
        <li>
          <a href="https://info.peerstreet.com/faqs/will-know-automated-order-placed/"
             target="_blank">
            How will I know that an investment was made for me?
          </a>
        </li>
        <li>
          <a href="https://info.peerstreet.com/faqs/can-i-cancel-a-specific-investment/"
             target="_blank">Can I cancel a specific investment?</a>
        </li>
        <li>
          <a href="https://info.peerstreet.com/faqs/will-get-every-loan-matches-investment-criteria/"
             target="_blank">
            Will I get into every loan that matches my investment criteria?
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import HowItWorksSection from "components/auto_investments/how_it_works_section";

  export default {
    name: "AutoInvestmentsOverview",
    components: {
      HowItWorksSection,
    },
    data() {
      return {
        flashError: PSData.flashErrorMessage,
      };
    },
    methods: {
      showSettings() {
        this.$emit("show-settings");
      },
      buttonClass() {
        return this.flashError ? "btn btn-primary-cta disabled" : "btn btn-primary-cta";
      },
    },
  };
</script>
