/* eslint-disable complexity */
$(function() {
  $.fn.dataTable.moment("MM/DD/YY - MM/DD/YY");
  $.fn.dataTable.ext.classes.sFilter = "col-lg-4";
  $.fn.dataTable.ext.classes.sFilterInput = "form-control filter-input";
  var $table = $(".has-datatable"),
    resourcePath = $table.data("source"),
    requestMethod = $table.data("method") || "GET",
    primaryIndex = primarySortColumnIndex($table),
    pageLength = $table.data("per-page") || 25,
    pagingType = $table.data("paging-type") || "simple_numbers",
    searchDelay = $table.data("search-delay") || 1000,
    domWeb = $table.data("dom-web"),
    searchbarPlaceholder = $table.data("searchbar-placeholder") || "";

  if (window.screen.width < 768) {
    // eslint-disable-next-line no-redeclare
    var domWeb = "<\"row col-12\" f> t <\"row col-12\" <\"bottom-info col-lg-6\" i><\"pagination-bottom-control col-lg-6\" p>>";
  }

  function subscribeOnChangeEvents(table) {
    $("#start-date, #end-date, #transaction-type").on("change", function() {
      table.DataTable().draw();
    });
  }

  var defaultOpts = {
    order: [],
    pageLength: pageLength,
    bAutoWidth: false,
    pagingType: pagingType,
    searchDelay: searchDelay,
    oLanguage: {
      "sSearch": "Search",
    },
    stateLoadParams: function() {
      if (window.location.href.indexOf(KEEP_FLAG) === -1) {
        return false;
      }
    },
  };

  if (domWeb) {
    defaultOpts.dom = domWeb;
  }

  if (searchbarPlaceholder) {
    defaultOpts.oLanguage.sSearchPlaceholder = searchbarPlaceholder;
  }

  // If a data source is provided, we should fetch
  // the data for display. Otherwise, assume that it
  // already exists on the page.
  if (typeof resourcePath !== "undefined") {
    var extOpts = {
      columnDefs: columnOptions($table, primaryIndex),
      order: [
        [primaryIndex, "desc"]
      ],
      serverSide: true,
      ajax: {
        url: resourcePath,
        type: requestMethod,
        data: function(data) {
          var fromDateField = $("#start-date");
          var endDateField = $("#end-date");
          var transactionTypeField = $("#transaction-type");

          if (fromDateField && endDateField) {
            data.date_from = fromDateField.val();
            data.date_to = endDateField.val();
          }

          data.transaction_type = transactionTypeField.val();
        },
        error: function(xhr, error, code) {
          if (xhr.status === 401) {
            location.reload();
          } else {
            let error = new Error(`Datatable loading error: (${xhr?.status} ${error} ${code})`);

            if (window.bugsnag) {
              window.bugsnag.notify(error);
            } else {
              console.log(error);
            }
          }
        },
      },
      drawCallback: function() {
        $("#date-filter").insertAfter("#other-filters");
        $("#type-filter").insertAfter("#date-filter");
        $("#transactions-datatable_wrapper, #date-filter, #type-filter").show();
      },
    };

    var opts = $.extend({}, defaultOpts, extOpts);

    $table.dataTable(opts);
    $table.on("init.dt", function() {
      subscribeOnChangeEvents($table);
    });
  } else {
    $table.dataTable(defaultOpts);
    subscribeOnChangeEvents($table);
  }

  // Looks for sortable columns and returns a set
  // of column options complete with primary and
  // secondary sorting for each.
  function columnOptions(table, primaryIndex) {
    var $columns = $("th", table);

    return $columns
      .map(function(index) {
        // eslint-disable-next-line eqeqeq
        var sortable = $(this).data("sortable") != false;
        var columnName = $(this).data("column") || toUnderscoreLower($(this).text());
        var className = $(this).data("classname");
        var orderSequence = $(this).data("orderSequence") || ["desc", "asc"];

        if (sortable) {
          return {
            orderData: [index, primaryIndex],
            targets: index,
            data: columnName,
            orderSequence: orderSequence,
          };
        } else {
          return {
            orderData: [],
            orderable: false,
            targets: index,
            data: columnName,
            className: className,
          };
        }
      })
      .get();
  }

  function primarySortColumnIndex(table) {
    var columns = $("th", table),
      primary = $("th[data-primary='true']", table),
      index = columns.index(primary);

    // eslint-disable-next-line eqeqeq
    return index == -1 ? 0 : index;
  }

  function toUnderscoreLower(text) {
    return text
      .replace(/[\s-]+/g, "_")
      .replace(/\W+/g, "")
      .toLowerCase();
  }
});
