$(window).on("load draw.dt", function() {
  $("body").on("hidden.bs.modal", ".modal", function() {
    $(this).removeData("bs.modal");
  });

  startTooltips();

  // Reference: http://www.alfajango.com/blog/rails-3-remote-links-and-forms/
  $("body").on("ajax:success", ".remote-form", function(event) {
    const ref = event.detail;
    const doc = ref[0];
    const xhr = ref[2];
    const contentType = doc.contentType;

    if (contentType && contentType.indexOf("html") > -1) {
      const ajaxTarget = $(this).parent();

      ajaxTarget.replaceWith(xhr.responseText);

      setupPageListeners();

      onload();
    }
  }).on("ajax:error", ".remote-form", function(e, hrx, status, error) {
    // eslint-disable-next-line no-alert
    alert("Something went wrong...");
  });
});
