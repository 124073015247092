<template>
  <div class="offer-review">
    <h1>Buyout Offer</h1>
    <offer-details :buyout-offer="buyoutOffer" />
    <div v-if="offerAccepted"
         class="offer-review-banner offer-accepted">
      <p>Buyout offer accepted. You can expect the funds to settle in your account within 3 business days.</p>
    </div>
    <ul v-else
        class="offer-actions-list">
      <li>
        <div class="offer-terms checkbox-control">
          <input id="offer-terms-checkbox"
                 v-model="offerTermsAccepted"
                 type="checkbox">
          <label for="offer-terms-checkbox">
            By accepting this offer, I agree to convey my MDPN in exchange
            for the Buyout Price and to surrender and forfeit any outstanding or future cash
            flows associated with this loan and my MDPN. I understand that future proceeds
            on the loan may exceed the Buyout Price and that I will not have a claim to any
            of those future distributions. I also understand that PeerStreet is not an
            investment adviser, is not opining on the fair market value of my position,
            and is neither recommending nor discouraging me from accepting this Buyout Offer.
          </label>
        </div>
      </li>
      <li v-if="hasErrors"
          class="error-messages">
        <div class="offer-review-banner">
          <p>Error while accepting buyout offer.</p>
        </div>
      </li>
      <li>
        <button type="button"
                :disabled="!offerTermsAccepted"
                class="btn btn-primary-cta accept-offer-button"
                @click="acceptOffer">
          Accept offer
        </button>
      </li>
      <li>
        <button type="button"
                class="btn reject-offer-button"
                @click="rejectOffer">
          Reject offer
        </button>
      </li>
    </ul>
  </div>
</template>

<script>

import BuyoutOfferService from "services/secondary_market/buyout_offer_service";
import OfferDetails from "components/secondary_market/offer_details";
import Segment from "services/segment";

export const OFFER_REJECT_EVENT = "offer-reject";
export const OFFER_ACCEPTED_EVENT = "offer-accepted";

const TRACK_OFFER_REVIEW = "Offer Review";

export default {
  name: "OfferReview",
  components: {
    OfferDetails,
  },
  props: {
    buyoutOffer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      offerTermsAccepted: false,
      offerAccepted: false,
      errors: [],
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    this.trackShowOfferReview();
  },
  methods: {
    async acceptOffer() {
      this.trackAcceptOfferClick();
      let data = await BuyoutOfferService.accept(this.buyoutOffer);

      if (data.success) {
        this.errors = [];
        this.offerAccepted = true;
        this.$emit(OFFER_ACCEPTED_EVENT);
      } else {
        this.errors = data.errors;
      }
    },
    rejectOffer() {
      this.$emit(OFFER_REJECT_EVENT);
    },
    trackShowOfferReview() {
      Segment.track("pageView", { category: TRACK_OFFER_REVIEW, label: "Offer Review Displayed" });
    },
    trackAcceptOfferClick() {
      Segment.track("click", { category: TRACK_OFFER_REVIEW, label: "Accept Offer Clicked" });
    },
  },
};
</script>
