Vue.component("LoansListItem", {
  props: {
    loan: { type: Object, required: true },
  },
  data: function() {
    return {
      expanded: false,
      popoverActive: false,
      loanPath: `/loans/${this.loan.psId}`,
    };
  },
  created: function() {
    PS.globalBus.$on("closeOthers", this.collapseDetails.bind(this));
    PS.globalBus.$on("popoverActive", this.handlePopoverActive.bind(this));
  },
  beforeDestroy: function() {
    PS.globalBus.$off("closeOthers", this.collapseDetails.bind(this));
  },
  methods: {
    handlePopoverActive(isActive) {
      this.popoverActive = isActive;
    },
    toggleDetails: function() {
      if (this.popoverActive) return;

      this.expanded = !(this.expanded);

      if (this.expanded) {
        PS.globalBus.$emit("loanExpanded", this.loan.psId);
      } else {
        PS.globalBus.$emit("loanExpanded", undefined);
      }
    },
    collapseDetails: function(loanId) {
      // eslint-disable-next-line eqeqeq
      if (this.loan.psId != loanId) {
        this.expanded = false;
      }
    },
  },
  template: "#v-loans-list-item-template",
});
