<template>
  <a class="close"
     aria-hidden="true"
     href="#"
     data-dismiss="modal">
    <i class="fa fa-times" />
  </a>
</template>

<script>
export default {
};
</script>
