<template>
  <div v-cloak
       class="pocket-modal modal fade">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <Header />

        <div class="modal-body">
          <component :is="currentComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./header";
import Form from "./form";

import RecurringDepositsConfirmation from "./recurring_deposits/confirmation";
import RecurringDepositsSuccess from "./recurring_deposits/success";

import OneTimeBankDepositConfirmation from "./one_time_deposits/bank/confirmation";
import OneTimeBankDepositSuccess from "./one_time_deposits/bank/success";

import OneTimeCashDepositConfirmation from "./one_time_deposits/cash/confirmation";
import OneTimeCashDepositSuccess from "./one_time_deposits/cash/success";

import InvestmentOfferingsService from "services/investment_offerings_service";
import ModalStore from "./store";

const OPEN_MODAL_EVENT = "openPocketModal";

export default {
  components: {
    Header,
    Form,
    RecurringDepositsConfirmation,
    RecurringDepositsSuccess,
    OneTimeBankDepositConfirmation,
    OneTimeBankDepositSuccess,
    OneTimeCashDepositConfirmation,
    OneTimeCashDepositSuccess,
  },

  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    currentComponent() {
      return this.modalStoreData.currentComponent;
    },
  },

  mounted() {
    ModalStore.modalElement = this.$el;

    $(this.$el).on("show.bs.modal", function() {
      ModalStore.onModalOpen();
    });

    $(this.$el).on("hidden.bs.modal", function() {
      ModalStore.onModalClose();
    });

    PS.globalBus.$on(OPEN_MODAL_EVENT, this.openModal);

    if (ModalStore.isInstantOpen()) {
      this.openModal();
      ModalStore.removeInstantOpenFragment();
    }
  },

  methods: {
    openModal(offeringGlobalId) {
      offeringGlobalId = offeringGlobalId ||
        new URLSearchParams(window.location.search).get("offering") ||
          InvestmentOfferingsService.pocketClassicOffering()?.globalId;

      ModalStore.openModal(offeringGlobalId);
    },
  },
};
</script>
