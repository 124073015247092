<template>
  <banner-v3 slider-class="bx-wrapper banner-v3-slider-wrapper">
    <template #title>
      <span aria-label="banner title">
        Bankruptcy Claims
      </span>
    </template>
    <template #image>
      <img class="banner-v3-image"
           :src="require(`../../assets/images/icons/icon-banner.svg`)">
    </template>
    <template #content>
      <p>
        The Bankruptcy Court has entered an order establishing February 22, 2024
        at 5:00 p.m. (Eastern Time) as the general deadline by which creditors
        of Peer Street must file proofs of claim (the “Bar Date”).  Please
        follow <a href="https://cases.stretto.com/public/x247/12208/CORRESPONDENCE/1220801222450000000004.pdf" target="_blank">this link</a>
        to access the Notice of Bar Date. Additional
        information, including the proof of claim form and instructions for
        filing a proof of claim, can be found on Peer Street’s bankruptcy
        website,
        <a href="https://cases.stretto.com/peerstreet/" target="_blank">
          https://cases.stretto.com/peerstreet/
        </a>.
      </p>

      <br />

      <ul>
        <li>
          <a href="https://cases.stretto.com/public/x247/12208/CORRESPONDENCE/1220801222450000000004.pdf" target="_blank">
            PeerStreet Bar Date Notice
          </a>
        </li>
        <li v-if="hasClaim">
          <a :href="url" target="_blank">
            PeerStreet Claims Schedule
          </a>
        </li>
      </ul>
    </template>
  </banner-v3>
</template>

<script>
import BannerV3 from "./banner_v3";

export default {
  components: { BannerV3 },
  props: {
    isVisible: { type: Boolean, default: false },
    url: { type: String, default: null },
  },
  computed: {
    hasClaim() {
      return this.isVisible && this.url;
    },
  }
};
</script>

