$(() => {
  const NumberFormatter = PS.Models.NumberFormatter;

  let pristine = {
    normalizeRules: $.validator.normalizeRules,
    methods: {
      min: $.validator.methods.min,
      max: $.validator.methods.max,
      range: $.validator.methods.range,
    },
  };

  $.validator.normalizeRules = function(rules, element) {
    $.each(rules, function(prop, val) {
      if (val === "false") {
        rules[prop] = false;
      }
    });

    return pristine.normalizeRules.call(this, rules, element);
  };

  $.validator.methods.min = function(value, element, param) {
    let val = NumberFormatter.unmaskNumber(value);

    return pristine.methods.min.call(this, val, element, param);
  };

  $.validator.methods.max = function(value, element, param) {
    let val = NumberFormatter.unmaskNumber(value);

    return pristine.methods.max.call(this, val, element, param);
  };

  $.validator.methods.range = function(value, element, param) {
    let val = NumberFormatter.unmaskNumber(value);

    return pristine.methods.range.call(this, val, element, param);
  };

  // modified checkForm per http://web-funda.blogspot.com/2009/05/jquery-validation-for-array-of-input.html
  // in order to allow validating multiple elements with the same name
  $.validator.methods.checkForm = function() {
    this.prepareForm();
    for (let i = 0, elements = (this.currentElements = this.elements()); elements[i]; i++) {
      if (this.findByName(elements[i].name).length !== undefined && this.findByName(elements[i].name).length > 1) {
        for (let cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
          this.check(this.findByName(elements[i].name)[cnt]);
        }
      } else {
        this.check(elements[i]);
      }
    }

    return this.valid();
  };
});
