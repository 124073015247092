<template>
  <div class="auto-deposits-success-page">
    <div class="auto-deposits-success">
      <h1>Deposits Scheduled!</h1>
      <img :src="require('icons/icon_calendar.svg')">
      <p>Your recurring deposit of {{ settings.amount | money }} has been successfully scheduled. </p>
      <p>
        If you have any questions, please contact us at <a href="mailto:support@peerstreet.com"
                                                           target="_blank">support@peerstreet.com</a> or 844-733-7787.
      </p>
      <a href="/loans"
         class="btn-primary-cta btn-block">View Investments</a>
    </div>
  </div>
</template>
<script>
  import { money } from "filters";

  export default {
    filters: {
      money,
    },
    props: {
      settings: { type: Object, default: null },
    },
  };
</script>
