<template>
  <div class="main-form-block">
    <div class="child-form-block">
      <input id="discount-input"
             v-model="discount"
             class="form-control required form-control-md"
             :disabled="isFormDisabled"
             @keypress="isNumber"
             type="text"
             placeholder="i.e. 85">
    </div>
    <div class="child-form-block">
      <input id="allocation-input"
             v-model="allocation"
             class="form-control required form-control-md"
             :disabled="isFormDisabled"
             @keypress="isNumber"
             type="text"
             placeholder="i.e. 100000">
    </div>
    <div class="child-form-block">
      <select id="expiration-date-input"
              v-model="expiration"
              class="form-control required form-control-md date-input"
              :disabled="isFormDisabled">
        <option value=""
                selected />
        <option v-for="date in expirationDates"
                :key="date"
                :value="date">
          {{ date }} days
        </option>
      </select>
    </div>
    <div class="child-form-block">
      <button id="create-bid-button"
              type="submit"
              :disabled="isSubmitDisabled"
              class="btn btn-primary-cta"
              @click="showConfirmationModal">
        Bid
      </button>
    </div>
  </div>
</template>

<script>
import { OPEN_BID_CONFIRMATION_MODAL_EVENT } from "components/secondary_market/bid_confirmation_modal";

import { formatMoney, formatPercent } from "./bid_confirmation_modal";

const DISCOUNT_MIN = 65;
const DISCOUNT_MAX = 200;

export default {
  name: "BidForm",
  props: {
    loanId: {
      type: String,
      default: null,
      required: true,
    },
    loanAmount: {
      type: String,
      default: null,
      required: true,
    },
    loanTitle: {
      type: String,
      default: null,
      required: true,
    },
    disableForm: {
      type: Boolean,
      required: true,
      default: false,
    },
    toggleForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      discount: null,
      allocation: null,
      expiration: null,
      expirationDates: [3,5,7,10],
    };
  },
  computed: {
    isInvalidForm() {
      return !(this.validDiscount &&
             this.validAllocation &&
             this.validExpiration);
    },
    isSubmitDisabled() {
      return this.isInvalidForm || this.isFormDisabled;
    },
    isFormDisabled() {
      return this.disableForm;
    },
    validDiscount() {
      const discount = parseInt(this.discount, 10);

      return discount >= DISCOUNT_MIN && discount <= DISCOUNT_MAX;
    },
    validAllocation() {
      const allocation = parseFloat(this.allocation, 2);

      return allocation > 0 && allocation <= parseFloat(this.loanAmount, 2);
    },
    validExpiration() {
      const expiration = parseInt(this.expiration, 10);

      return this.expirationDates.includes(expiration);
    },
    expirationDate() {
      const expirationDate = new Date();
      const numOfDays = parseInt(this.expiration, 10);

      expirationDate.setDate(expirationDate.getDate() + numOfDays);

      return expirationDate;
    },
    bidPercentage() {
      return formatPercent((parseFloat(this.offerRate) * 100), 0);
    },
    maxAllocationFormatted() {
      return formatMoney(this.maxAllocation, 0);
    },
    offerRate() {
      return parseFloat(this.discount, 2) / 100.00;
    },
    allocationAmount() {
      return parseFloat(this.allocation, 2);
    },
  },
  methods: {
    isNumber(e) {
      if (!/\d/.test(e.key) && e.key !== ".") {
        return e.preventDefault();
      }
    },
    showConfirmationModal() {
      this.toggleForm();

      PS.globalBus.$emit(OPEN_BID_CONFIRMATION_MODAL_EVENT,
          { loanId: this.loanId,
                 loanTitle: this.loanTitle,
                 offerRate: this.offerRate,
                 allocationAmount: this.allocationAmount,
                 expirationDate: this.expirationDate,
          });
    },
  },
};
</script>
