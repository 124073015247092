<template>
  <div class="loan-invest-description">
    <div class="loan-image-container">
      <img :src="loan.imageUrl"
           class="loan-image">
    </div>
    <div class="loan-details-list-header">
      {{ loan.loanTitle }}
    </div>
    <div class="loan-details-list-wrapper">
      <div class="loan-details-list">
        <div class="loan-details-list-item">
          <div class="loan-details-list-value"
               :class="{'bumped-rate': loan.hasYieldBump}">
            {{ formattedBumpedRate }}<span>%</span>
          </div>
          <div class="loan-details-list-label">
            YTM
          </div>
        </div>

        <div class="loan-details-list-item">
          <div class="loan-details-list-value">
            {{ formattedLtv }}<span>%</span>
          </div>
          <div class="loan-details-list-label">
            LTV
          </div>
        </div>
        <div class="loan-details-list-item">
          <div class="loan-details-list-value">
            {{ formattedRemainingTerm }}<span>Mo.</span>
          </div>
          <div class="loan-details-list-label">
            Term
          </div>
        </div>
      </div>
      <div class="loan-progress-wrapper">
        <div class="loan-progress progress">
          <div class="progress-bar progress-bar-info"
               :style="loanProgressStyle" />
        </div>
        <div class="loan-progress-description">
          <div class="loan-progress-description-item">
            {{ formattedPercentageFunded }} Funded
          </div>
          <div v-if="doShowAmountLeft"
               class="loan-progress-description-item">
            {{ formattedAmountLeft }} Left
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "formatters/number_formatter";

export const { formatNumber, formatPercent, formatMoney } = NumberFormatter;

export default {
  name: "InvestLoanCard",

  props: {
    loan: { type: Object, default: () => {} },
  },

  computed: {
    formattedAmountLeft() {
      return formatMoney(this.loan.amountLeft, 0);
    },

    formattedPercentageFunded() {
      return formatPercent(this.loan.percentageFunded, 0);
    },

    formattedRemainingTerm() {
      return formatNumber(this.loan.remainingTerm, 0);
    },

    formattedLtv() {
      return formatNumber(this.loan.ltv, 0);
    },

    formattedBumpedRate() {
      return formatNumber(this.loan.bumpedRate, 2);
    },

    loanProgressStyle() {
      return {
        width: `${this.loan.percentageFunded}%`,
      };
    },

    doShowAmountLeft() {
      return this.loan.percentageFunded < 100;
    },
  },
};
</script>
