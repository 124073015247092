Vue.component("LoanInvestedAmount", {
  props: {
    standardInvestment: { type: Boolean, default: true },
    positionAmount: { type: [String, Number], default: 0 },
    seriesAmount: { type: [String, Number], default: 0 },
  },
  computed: {
    amount: function() {
      return this.standardInvestment ? this.seriesAmount : this.positionAmount;
    },
    isDisplayed: function() {
      return this.amount > 0;
    },
  },
  template: "#v-loan-invested-amount-template",
});
