<template>
  <div>
    <label>From</label>
    <div class="pocket-funding-options">
      <div class="funding-option">
        <input id="funding-source-bank"
               v-model="modalStoreData.fundingSource"
               type="radio"
               name="funding-source"
               value="BANK">
        <label for="funding-source-bank">
          Bank Account ***{{ modalStoreData.bankAccountLastFour }}
        </label>
      </div>
      <div v-if="hasCashAvailable"
           class="funding-option">
        <input id="funding-source-cash"
               v-model="modalStoreData.fundingSource"
               type="radio"
               name="funding-source"
               value="CASH">
        <label for="funding-source-cash">PeerStreet Account</label>
        <div class="amount-available">
          {{ modalStoreData.availableToInvest | money }} Available to Invest
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalStore from "../store";

export default {
  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    hasCashAvailable() {
      return +this.modalStoreData.availableToInvest > 0;
    },
  },
};
</script>
