<template>
  <div v-if="hasInvestments"
       class="account-portfolio-chart">
    <div class="positions-header">
      <h4>All Investments</h4>
    </div>

    <div class="row">
      <div class="col-md-auto">
        <ul class="legend">
          <li class="paid-off">
            <b>{{ counts.paidOff }}</b> Paid Off
          </li>
          <li class="current">
            <b>{{ counts.current }}</b> Active &ndash; Current
          </li>
          <li class="deferred">
            <b>{{ counts.deferred }}</b> Active &ndash; Deferred
          </li>
          <li class="late">
            <b>{{ counts.late }}</b> Active &ndash; Late
          </li>
          <li class="short-pay">
            <b>{{ counts.shortPay }}</b> Short Pay
          </li>
          <li class="early-liquidation">
            <b>{{ counts.earlyLiquidation }}</b> Early Liquidation
          </li>
        </ul>
      </div>
      <div class="col">
        <div class="bar-chart">
          <div
            v-if="counts.paidOff"
            class="chart-section paid-off"
            :style="{ 'flex-grow': counts.paidOff }" />
          <div
            v-if="counts.current"
            class="chart-section current"
            :style="{ 'flex-grow': counts.current }" />
          <div
            v-if="counts.deferred"
            class="chart-section deferred"
            :style="{ 'flex-grow': counts.deferred }" />
          <div
            v-if="counts.late"
            class="chart-section late"
            :style="{ 'flex-grow': counts.late }" />
          <div
            v-if="counts.shortPay"
            class="chart-section short-pay"
            :style="{ 'flex-grow': counts.shortPay }" />
          <div
              v-if="counts.earlyLiquidation"
              class="chart-section early-liquidation"
              :style="{ 'flex-grow': counts.earlyLiquidation }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pick from "lodash/pick";

export default {
  name: "AccountPortfolioChart",
  data() {
    return {
      counts: PSData.accountPositionsCount,
    };
  },
  computed: {
    hasInvestments() {
      const counts = pick(this.counts, ["current", "late", "paidOff", "shortPay", "deferred", "earlyLiquidation"]);

      return Object.values(counts).some(count => count > 0);
    },
  },
};
</script>
