<template>
  <span>
    <a v-popover="{ placement: 'top' }"
       href="#">
      <i class="fa fa-question-circle" />
    </a>
    <span class="d-none">
      {{ tooltipText }}
    </span>
  </span>
</template>

<script>
import Popover from "directives/popover";

export default {
  name: "RememberDeviceTooltip",

  directives: {
    popover: {
      inserted: Popover.inserted,
      unbind: Popover.unbind,
    },
  },

  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
</script>
