import RecurringStore from "./recurring_deposits/store";
import InvestmentOfferingsService from "services/investment_offerings_service";

export const TABS = {
  ONE_TIME: "ONE_TIME",
  RECURRING: "RECURRING",
};

export const FUNDING_SOURCES = {
  BANK: "BANK",
  CASH: "CASH",
};

const INSTANT_OPEN_FRAGMENT = "#invest";

export default class ModalStore {
  static modalElement = null;

  static data = {
    title: null,
    subTitle: null,
    currentComponent: null,
    activeTab: TABS.ONE_TIME,
    fundingSource: null,
    selectedOfferingGlobalId: null,
    availableToInvest: this.getAvailableToInvest(),
    bankAccountLastFour: this.getBankAccountLastFour(),
    investmentsFromBankEnabled: this.getInvestmentsFromBankEnabled(),
    offerings: this.getOfferings(),
    allowBankFunding: this.isAllowBankFunding(),
    isSuccessfulInvestment: false,
  }

  static getAvailableToInvest() {
    return PSData.user?.properties?.savingsInvestment?.availableToInvest;
  }

  static getBankAccountLastFour() {
    return PSData.user?.properties?.bankAccountNumberLast4;
  }

  static getInvestmentsFromBankEnabled() {
    return PSData.savings?.investmentConfigs?.investmentsFromBankEnabled;
  }

  static getOfferings() {
    return PSData.user?.properties?.savingsInvestment?.offerings;
  }

  static isAllowBankFunding() {
    return this.getInvestmentsFromBankEnabled() && this.getBankAccountLastFour();
  }

  static defaultData = {
    title: null,
    subTitle: null,
    currentComponent: null,
    activeTab: TABS.ONE_TIME,
    fundingSource: null,
    selectedOfferingGlobalId: null,
    availableToInvest: this.getAvailableToInvest(),
    bankAccountLastFour: this.getBankAccountLastFour(),
    investmentsFromBankEnabled: this.getInvestmentsFromBankEnabled(),
    offerings: this.getOfferings(),
    allowBankFunding: this.isAllowBankFunding(),
  }

  static setData(data) {
    Object.assign(this.data, data);
  }

  static nullifyData() {
    Object.assign(this.data, this.defaultData);
  }

  static showInvestForm() {
    this.setTitle(
      "Invest Funds",
      "Invest funds from your PeerStreet account " +
        ` ${this.data.allowBankFunding ? "or Bank Account" : ""} into Pocket.`
    );

    this.data.currentComponent = "Form";
  }

  static showRecurringDepositsConfirmation() {
    this.setTitle("Confirm Recurring Pocket Investment");

    this.data.currentComponent = "RecurringDepositsConfirmation";
  }

  static showRecurringDepositsSuccess() {
    this.setTitle("Recurring Pocket Investment Scheduled");

    this.data.currentComponent = "RecurringDepositsSuccess";
    this.data.isSuccessfulInvestment = true;
  }

  static showOneTimeBankConfirmation() {
    this.setTitle("Confirm Pocket Investment");

    this.data.currentComponent = "OneTimeBankDepositConfirmation";
  }

  static showOneTimeBankSuccess() {
    this.setTitle("Pocket Investment Initiated");

    this.data.currentComponent = "OneTimeBankDepositSuccess";
    this.data.isSuccessfulInvestment = true;
  }

  static showOneTimeCashConfirmation() {
    this.setTitle("Confirm Pocket Investment");

    this.data.currentComponent = "OneTimeCashDepositConfirmation";
  }

  static showOneTimeCashSuccess() {
    this.setTitle("Pocket Investment Completed");

    this.data.currentComponent = "OneTimeCashDepositSuccess";
    this.data.isSuccessfulInvestment = true;
  }

  static setTitle(title, subTitle = "") {
    this.data.title = title;
    this.data.subTitle = subTitle;
  }

  static onModalOpen() {
    this.pickActiveTab();
    this.pickActiveFundingSource();
    this.showInvestForm();
  }

  static pickActiveTab() {
    if (this.isInstantOpen() && !this.selectedOfferingIsPocketClassic()) {
      this.data.activeTab = TABS.ONE_TIME;

      return;
    }

    this.data.activeTab =
      (this.data.allowBankFunding &&
        RecurringStore.data.settings.disabled) ? TABS.RECURRING : TABS.ONE_TIME;
  }

  static selectedOfferingIsPocketClassic() {
    return InvestmentOfferingsService.isPocketClassic(this.data.selectedOfferingGlobalId);
  }

  static isInstantOpen() {
    return window.location.pathname.endsWith("pocket")
      && window.location.hash === INSTANT_OPEN_FRAGMENT;
  }

  static pickActiveFundingSource() {
    this.data.fundingSource = this.data.allowBankFunding ?
      FUNDING_SOURCES.BANK : FUNDING_SOURCES.CASH
  }

  static openRecurringTab() {
    this.data.activeTab = TABS.RECURRING;
  }

  static openOneTimeTab() {
    this.data.activeTab = TABS.ONE_TIME;
  }

  static openModal(offeringGlobalId) {
    if (this.data.offerings.find(o => o.globalId === offeringGlobalId)) {
      this.data.selectedOfferingGlobalId = offeringGlobalId;
    } else {
      this.data.selectedOfferingGlobalId = this.data.offerings[0].globalId;
    }

    $(this.modalElement)?.modal("show");
  }

  static removeInstantOpenFragment() {
    window.location.replace("#");
  }

  static closeModal() {
    $(this.modalElement).modal("hide");
  }

  static onModalClose() {
    RecurringStore.data.settings.resetToOriginal();
    RecurringStore.data.inEditMode = false;

    if (this.data.isSuccessfulInvestment) {
      document.location.reload();
    }

    this.data.isSuccessfulInvestment = false;
  }
}
