<template>
  <div class="auto-deposits-status">
    <a v-if="shouldLink"
       class="auto-deposits-status-link"
       :href="autoDepositUrl">{{ label }}</a>
    <span v-if="!shouldLink">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "AutoDepositsStatus",
  data() {
    return {
      autoDepositUrl: PSData.autoDepositsLink.autoDepositUrl,
      enabled: PSData.autoDepositsLink.enabled,
    };
  },
  computed: {
    shouldLink() {
      return !(this.enabled === null);
    },
    label() {
      switch (this.enabled) {
        case true: return "Enabled";

        case false: return "Disabled";

        default: return "Not Configured";
      }
    },
  },
};
</script>
