<template>
  <div>
    <div v-show="hasTooManyRecords"
         class="error alert alert-warning">
      <p>More than 1,000 records returned.</p>
    </div>
    <table
      class="table table-striped table-bordered table-hover unlinked-payments-table has-datatable"
      :data-source="sourcePath"
      data-method="POST"
      data-paging="false">
      <thead>
        <tr>
          <th data-sortable="false">
            Select
          </th>
          <th data-sortable="false">
            Type
          </th>
          <th data-sortable="false">
            Loan
          </th>
          <th data-sortable="false"
              data-classname="amount">
            Amount
          </th>
          <th data-sortable="false">
            Effective Date
          </th>
        </tr>
      </thead>
      <tbody />
    </table>
  </div>
</template>

<script>
import Events from "components/manage/transactions/unlinked_payment/events";
import moment from "moment";
import pick from "lodash/pick";

const BASE_PATH = "/manage/transactions/linked_withdrawals/unlinked_payments";
const RECORDS_LIMIT = 1000;
const US_DATE = "MM/DD/YYYY";

export default {
  name: "UnlinkedPaymentList",
  props: {
    userId: { type: String, default: null },
  },
  data() {
    return {
      datatable: null,
      list: null,
      totalRecordsCount: 0,
      totalWireAmount: 0,
      filter: {
        loanIds: "",
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    beginningOfLastMonth() {
      return moment()
        .subtract(1, "months")
        .startOf("month")
        .format(US_DATE);
    },
    endOfLastMonth() {
      return moment()
        .subtract(1, "months")
        .endOf("month")
        .format(US_DATE);
    },
    hasTooManyRecords() {
      return this.totalRecordsCount > RECORDS_LIMIT;
    },
    sourcePath() {
      const searchParams = [
        `user_id=${this.userId}`,
        `startdate=${this.filterStartDate}`,
        `finishdate=${this.filterEndDate}`,
        `loan_ids=${this.filterLoanIds}`
      ].join("&");

      return `${BASE_PATH}?${searchParams}`;
    },
    filterStartDate() {
      const date = this.filter.startDate;

      return date === null ? this.beginningOfLastMonth : date;
    },
    filterEndDate() {
      const date = this.filter.endDate;

      return date === null ? this.endOfLastMonth : date;
    },
    filterLoanIds() {
      return this.filter.loanIds;
    },
  },
  mounted() {
    this.list = $(".unlinked-payments-table");

    this.list.one("init.dt", () => {
      this.datatable = this.list.DataTable();
    });

    this.list.on("draw.dt", () => {
      PS.globalBus.$emit(Events.recordsUpdated);
      this.calculatePaymentsAmount();
      this.watchForPaymentsList();
    });

    this.list.on("xhr.dt", this.setTotalRecordsCount);

    PS.globalBus.$on(Events.filter, this.filterList);
  },
  methods: {
    calculatePaymentsAmount() {
      let amount;

      if (this.hasTooManyRecords) {
        amount = this.totalWireAmount;
      } else {
        const payments = Array.from(this.list.find("input[type=checkbox]:checked"));

        amount = payments.reduce((sum, payment) => sum + parseFloat(payment.dataset.amount), 0);
      }

      PS.globalBus.$emit(Events.amountUpdated, amount);
    },
    filterList(params) {
      const allowedParams = ["startDate", "endDate", "loanIds"];

      Object.assign(this.filter, pick(params, allowedParams));
      this.datatable.ajax.url(this.sourcePath).load();
    },
    setTotalRecordsCount(event, settings, json, xhr) {
      if (json) {
        this.totalRecordsCount = json.recordsTotal;
        this.totalWireAmount = json.totalWireAmount;
      }

      if (this.datatable) {
        const container = $(this.datatable.table().container());

        // eslint-disable-next-line no-unused-expressions
        this.hasTooManyRecords ? container.hide() : container.show();
      }
    },
    watchForPaymentsList() {
      this.list.find("input[type=checkbox]").change(() => this.calculatePaymentsAmount());
    },
  },
};
</script>
