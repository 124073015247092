<template>
  <div>
    <OfferingPicker />
    <FundingSourcePicker />

    <BankInvestmentForm v-if="fundingFromBank" />
    <CashInvestmentForm v-else />
  </div>
</template>

<script>
import ModalStore, { FUNDING_SOURCES } from "../store";

import OfferingPicker from "../offering_picker.vue";
import FundingSourcePicker from "./funding_source_picker.vue";
import BankInvestmentForm from "./bank/form.vue";
import CashInvestmentForm from "./cash/form.vue";

export default {
  components: {
    OfferingPicker,
    FundingSourcePicker,
    BankInvestmentForm,
    CashInvestmentForm,
  },

  data: function() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    fundingFromBank() {
      return this.modalStoreData.fundingSource === FUNDING_SOURCES.BANK;
    },
  },
};
</script>
