<template>
  <div class="modal-content">
    <div class="loading-icon">
      <div class="loading-icon-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingIcon",
};
</script>
