<template>
  <div>
    <RecurringDepositEditor v-if="showEditRecurring" />
    <RecurringDepositSummary v-else />
  </div>
</template>

<script>
import RecurringDepositEditor from "./editor.vue";
import RecurringDepositSummary from "./summary_form.vue";

import RecurringStore from "./store";

export default {
  name: "RecurringDepositForm",

  components:{
    RecurringDepositEditor,
    RecurringDepositSummary,
  },

  data: function() {
    return {
      storeData: RecurringStore.data,
    };
  },

  computed: {
    settings() {
      return this.storeData.settings;
    },

    showEditRecurring() {
      return this.settings.disabled || this.inEditMode;
    },

    inEditMode() {
      return this.storeData.inEditMode;
    },
  },
};
</script>
