<template>
  <div class="pocket-investment-confirmation">
    <Summary :investment-source-name="investmentSourceName"
             :amount="bankStoreData.amount" />

    <ConfirmationNote />

    <div class="terms-and-conditions">
      <p>
        I authorize PeerStreet to initiate the above-referenced bank transfer on my behalf,
        in the amount set forth above (“the Transfer”), and to, thereafter,
        promptly invest Transfer into PeerStreet Pocket.
      </p>
    </div>

    <AcknowledgementCheckbox v-model="investmentAcknowledged"
                             @acknowledgement-required="onAcknowledgementRequired" />

    <button class="btn-primary-cta btn-block"
            :disabled="investButtonDisabled"
            @click="investFunds">
      Invest Funds
    </button>
    <button class="btn btn-block"
            @click="leaveEditMode">
      Edit
    </button>
  </div>
</template>

<script>
import BankStore from "./store";
import OneTimeStore from "../store";
import ModalStore from "../../store";

import Summary from "../summary.vue";
import AcknowledgementCheckbox from "../acknowledgement_checkbox.vue";
import ConfirmationNote from "../../confirmation_note.vue";

export default {
  components: {
    Summary,
    AcknowledgementCheckbox,
    ConfirmationNote,
  },

  data() {
    return {
      isLoading: false,
      bankStoreData: BankStore.data,
      modalStoreData: ModalStore.data,
      acknowledgementRequired: false,
      investmentAcknowledged: false,
    };
  },

  computed: {
    investmentSourceName() {
      return `Bank Account ***${this.modalStoreData.bankAccountLastFour}`;
    },

    investButtonDisabled() {
      return this.isLoading || (this.acknowledgementRequired && !this.investmentAcknowledged);
    },
  },

  methods: {
    onAcknowledgementRequired(isRequired) {
      this.acknowledgementRequired = isRequired;
    },

    leaveEditMode() {
      ModalStore.showInvestForm();
    },

    investFunds() {
      this.isLoading = true;

      OneTimeStore.fundPocket({
        amount: this.bankStoreData.amount,
        investment_offering_global_id: this.modalStoreData.selectedOfferingGlobalId,
        source: "bank",
      }, () => {
        this.isLoading = false;

        ModalStore.showOneTimeBankSuccess();

        return true;
      }, ({ data }) => {
        let errorMessage = "";

        if (data && data.errors) {
          errorMessage = data.errors;
        } else {
          errorMessage = "There was an unknown error";
        }

        this.bankStoreData.serverErrors = errorMessage;
        this.isLoading = false;

        ModalStore.showInvestForm();

        return false;
      });
    },
  },
};
</script>
