const STICKY_CLASS = "allow-sticky-elements";

/**
 * This listener looks for elements that are given the CSS
 * class js-sticky-element, and which also have a data-offset
 * attribute with a non-negative numeric value.
 *
 * Such elements will become sticky when the page-y position
 * is greater than the data-offset value.
 *
 * TODO: This approach should be replaced with "position: sticky",
 * which does not require a hard-coded offset value.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/position
 */
document.addEventListener("DOMContentLoaded", () => {
  function scrollHandler() {
    if (window.pageYOffset > offset) {
      document.body.classList.add(STICKY_CLASS);
    } else {
      document.body.classList.remove(STICKY_CLASS);
    }
  }

  const stickyElement = document.querySelector(".js-sticky-element");

  if (!stickyElement) { return; }

  const offset = parseInt(stickyElement.dataset.offset, 10);

  if (offset <= 0) { return; }

  window.addEventListener("scroll", () => {
    window.requestAnimationFrame(scrollHandler);
  });
});
