<template>
  <div class="beneficiary-information account-information">
    <div class="card">
      <div class="card-heading">
        <h4>Transfer on Death Beneficiary</h4>
      </div>
      <div class="card-body">
        <p>
          Designate a beneficiary to receive your account assets upon your death. Adding a
          beneficiary to this account will supersede any conflicting provisions in any will,
          trust, or agreement.
        </p>

        <h5>Primary Beneficiary</h5>

        <div v-if="isForm"
             class="beneficiary-information-edit">
          <form>
            <div class="control-wrapper"
                 :class="{'has-error': beneficiary.errors.name}">
              <label class="control-label">Name</label>

              <input v-model="beneficiary.name"
                     class="form-control"
                     type="text"
                     name="name">

              <label v-show="beneficiary.errors.name"
                     class="error control-label">
                {{ beneficiary.errors.name }}
              </label>
            </div>

            <div class="control-wrapper"
                 :class="{'has-error': beneficiary.errors.relationship}">
              <label class="control-label">Relationship</label>

              <select v-model="beneficiary.relationship"
                      class="form-control"
                      name="relationship">
                <option v-for="(key, value) in relationshipOptions"
                        :key="value"
                        :value="value">
                  {{ key }}
                </option>
              </select>

              <label v-show="beneficiary.errors.relationship"
                     class="error control-label">
                {{ beneficiary.errors.relationship }}
              </label>
            </div>

            <div class="control-wrapper"
                 :class="{'has-error': beneficiary.errors.dateOfBirth}">
              <label class="control-label">Birthdate</label>

              <input
                id="startDate"
                v-model="beneficiary.dateOfBirth"
                v-mask:date
                v-datepicker
                class="date-picker form-control"
                type="text"
                placeholder="MM/DD/YYYY">

              <label v-show="beneficiary.errors.dateOfBirth"
                     class="error control-label">
                {{ beneficiary.errors.dateOfBirth }}
              </label>
            </div>
          </form>
          <div class="account-information-controls">
            <p>
              By saving the Transfer on Death Beneficiary, I agree and covenant to
              <a :href="termsPath">PeerStreet's Terms for Transfer on Death Beneficiary</a>
            </p>

            <div class="links">
              <button v-if="!beneficiary.isEmpty()"
                      class="btn btn-link-delete"
                      @click="deleteBeneficiary">
                Delete
              </button>
            </div>
            <div class="buttons">
              <button class="btn btn-link-sm"
                      @click="setShowMode">
                Cancel
              </button>
              <button :disabled="disableSubmit()"
                      class="btn btn-primary-cta btn-primary-small"
                      @click="save">
                Save
              </button>
            </div>
          </div>
        </div>

        <div v-if="isShow"
             class="beneficiary-information-show">
          <table v-if="!beneficiary.isEmpty()"
                 class="table">
            <tbody>
              <tr>
                <td class="control-label">
                  Name
                </td>
                <td>{{ beneficiary.name }}</td>
              </tr>
              <tr>
                <td class="control-label">
                  Relationship
                </td>
                <td>{{ beneficiary.relationshipText }}</td>
              </tr>
              <tr>
                <td class="control-label">
                  Birthdate
                </td>
                <td>{{ beneficiary.dateOfBirth }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="beneficiary.isEmpty()">
            None
          </div>

          <div class="account-information-controls">
            <div class="links" />
            <div class="buttons">
              <button class="btn btn-primary-cta btn-primary-small"
                      @click="setEditMode">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Mask, { DatePickerDirective } from "directives/inputs";

  import Ajax from "services/ajax";
  import Beneficiary from "models/beneficiary";
  import camelCase from "lodash/camelCase";

  const MODE = {
    form: "form",
    show: "show",
  };

  const LOADING = {
    failure: "failure",
    success: "success",
    inProgress: "inProgress",
  };

  export default {
    name: "BeneficiaryInformation",
    directives: {
      mask: {
        bind:   Mask.bind,
        unbind: Mask.unbind,
      },
      datepicker: {
        bind: DatePickerDirective.bind,
        unbind: DatePickerDirective.unbind,
      },
    },
    data() {
      return {
        state: MODE.show,
        loadingState: LOADING.success,
        beneficiary: new Beneficiary(PSData.beneficiary.model),
        beneficiaryUpdatePath: PSData.beneficiary.updatePath,
        beneficiaryCreatePath: PSData.beneficiary.createPath,
        beneficiaryDeletePath: PSData.beneficiary.deletePath,
        termsPath: PSData.beneficiary.termsPath,
        relationshipOptions: PSData.beneficiary.relationshipOptions,
      };
    },
    computed: {
      isForm() {
        return this.state === MODE.form;
      },
      isShow() {
        return this.state === MODE.show;
      },
      isLoading() {
        return this.loadingState === LOADING.inProgress;
      },
    },
    methods: {
      disableSubmit() {
        return this.isLoading || !this.beneficiary.valid;
      },
      setEditMode() {
        this.state = MODE.form;
      },
      setShowMode() {
        this.beneficiary.revert();
        this.state = MODE.show;
      },
      save() {
        this.loadingState = LOADING.inProgress;
        const beneficiary = this.beneficiary.toServer();
        let savePath;

        if (this.beneficiary.isEmpty()) {
          savePath = this.beneficiaryCreatePath;
        } else {
          savePath = this.beneficiaryUpdatePath;
        }

        Ajax
          .patch(savePath, beneficiary)
          .then(data => {
            this.beneficiary = new Beneficiary(data.beneficiary);
            this.state = MODE.show;
            this.loadingState = LOADING.success;
          })
          .catch(({ data }) => {
            Object.keys(data.errors).forEach((key) => {
              this.beneficiary.errors[camelCase(key)] = data.errors[key].join(", ");
            });
            this.loadingState = LOADING.failure;
          });

      },
      deleteBeneficiary() {
        this.loadingState = LOADING.inProgress;
        const beneficiary = this.beneficiary;

        Ajax
          .delete(this.beneficiaryDeletePath, beneficiary)
          .then(_ => {
            this.beneficiary.errors = {};
            this.beneficiary = new Beneficiary({});
            this.state = MODE.show;
            this.loadingState = LOADING.success;
          })
          .catch(() => {
            this.loadingState = LOADING.failure;
          });
      },
    },
  };
</script>
