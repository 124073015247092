const VIDEO_URL = "https://www.youtube.com/embed/LfufsQSEVyg?showinfo=0&rel=0";

class VideoModal {
  onDomReady($element) {
    const $modal = $("#explainer-video-modal");
    const $iframe = $modal.find("iframe");

    $element.on("click", () => {
      $iframe.attr("src", VIDEO_URL + "&autoplay=1");
      $modal.modal();
    });

    $modal.on("hide.bs.modal", () => {
      $iframe.attr("src", VIDEO_URL);
    });

    $modal.on("click", () => {
      $modal.modal("hide");
    });
  }
}

PS.Components.register(VideoModal, { applyOnlyTo: ".explainer-video" });
window.PS.Components.VideoModal = VideoModal;
