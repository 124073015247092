<template>
  <tr>
    <td class="position-loan">
      <a :href="loanPath">{{ position.title }}</a>
    </td>
    <td class="position-investment">
      {{ position.amount | money(2) }}
    </td>
    <td class="position-principal">
      {{ position.remainingPrincipal | money(2) }}
    </td>
    <td class="position-rate">
      <span v-if="hasYieldBump"
            class="yield-bump-override">
        {{ position.bumpedInvestmentReturn | percent }}
      </span>

      <span class="investment-return">
        {{ position.investmentReturn | percent }}
      </span>
    </td>
    <td class="position-earnings"
        :class="earningsClass">
      {{ displayEarningsText }}
    </td>
    <td class="position-start-date position-date">
      {{ position.formattedStartDate }}
    </td>
    <td class="position-end-date position-date"
        :class="[pastMaturityClass]">
      {{ endDate }}
      <div>
        <span v-if="position.activeAndMatured">
          {{ pastMaturityStatus }}
        </span>
        <span v-if="position.activeAndExtended">
          {{ extendedMaturityStatus }}
        </span>
      </div>
    </td>
    <td class="position-status">
      <payment-status
        :loan-servicing-state="position.loanServicingState"
        :show-faq="false"
        :loan-position-path="loanPositionPath" />
    </td>
    <td class="position-notices-count">
      <template v-if="hasNoticies">
        <a :href="loanPathWithAnchor">
          {{ position.loanServicingState.notificationsCount }}&nbsp;
          <i class="fa fa-comment" />
        </a>
      </template>
    </td>
    <td class="position-notices">
      <a v-if="position.loanServicingState.currentMilestone"
         class="position-milestone"
         @click="openForeclosureModal(position)">
        {{ position.loanServicingState.currentMilestone.title }}
      </a>
      {{ lastNotification }}
    </td>
  </tr>
</template>

<script>
import NumberFormatter from "formatters/number_formatter";
import PaymentStatus from "components/loans_v2/payment_status";

export const { formatMoney } = NumberFormatter;

export default {
  name: "AccountPositionsDashboardListItem",
  components: { PaymentStatus },
  props: { position: Object },
  computed: {
    hasNoticies() {
      return this.position.loanServicingState.notificationsCount > 0;
    },
    hasYieldBump() {
      return this.position.bumpedInvestmentReturn > this.position.investmentReturn;
    },
    loanPath() {
      return `/loans/${this.position.loan.psId}/position`;
    },
    loanPathWithAnchor() {
      return `/loans/${this.position.loan.psId}/position#loan-details`;
    },
    displayEarningsText() {
      return this.position.earnings ? formatMoney(this.position.earnings, 2, { plus: true }) : null;
    },
    earningsClass() {
      return {
        "positive-amount": this.position.earnings > 0,
        "negative-amount": this.position.earnings < 0,
      };
    },
    pastMaturityClass() {
      return this.position.activeAndMatured ? "past-maturity" : "";
    },
    lastNotification() {
      const notice = this.position.loanServicingState.lastNotificationAge;

      return notice === "" ? null : notice;
    },
    endDate() {
      const { paidOff, formattedPaidOffDate, formattedMaturityDate } = this.position;

      return paidOff ? formattedPaidOffDate : formattedMaturityDate;
    },
    pastMaturityStatus() {
      return "Past Maturity";
    },
    extendedMaturityStatus() {
      return "Extended";
    },
    loanPositionPath() {
      return `/loans/${this.position.loan.psId}/position#loan-summary`;
    },
  },
  methods: {
    openForeclosureModal(position) {
      PS.globalBus.$emit("openForeclosureModal", position);
    },
  },
};
</script>
