<template>
  <div id="error_explanation"
       class="error-list">
    <h2>Please fix the following to continue:</h2>

    <ul>
      <li v-for="error in formattedErrors"
          :key="error">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ServerErrors",
  props: {
    errors: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedErrors() {
      return this.errors.split("  ");
    },
  },
};
</script>
