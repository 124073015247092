<template>
  <div class="modal-header">
    <h2>
      {{ modalStoreData.title }}

      <div v-if="modalStoreData.subTitle"
           class="subtitle">
        {{ modalStoreData.subTitle }}
      </div>
    </h2>

    <a class="close"
       aria-hidden="true"
       href="#"
       data-dismiss="modal">
      <i class="fa fa-times" />
    </a>
  </div>
</template>

<script>
import ModalStore from "./store";

export default {
  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },
};
</script>
