const ORDINAL_FORMS = ["st", "nd", "rd", "th"];

export default class StringFormatter {
  static pluralize(count, singular, plural) {
    if (!singular || !plural) {
      throw new Error("Please provide singular and plural forms!");
    }

    return count === 1 ? singular : plural;
  }

  static ordinalize(count, ordinalForms = ORDINAL_FORMS) {
    if (ordinalForms.length !== 4) {
      throw new Error("Please provide four ordinal forms!");
    }

    let ordinalForm;

    if (count >= 11 && count <= 13) {
      ordinalForm = 4;
    } else if (count % 10 === 0 || count % 10 > 4) {
      ordinalForm = 4;
    } else {
      ordinalForm = count % 10;
    }

    return `${count}${ordinalForms[ordinalForm - 1]}`;
  }
}

window.PS.Models.StringFormatter = StringFormatter;
