class VerificationRequestsController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      data: {
        selectedStatus: undefined,
      },
      computed: {
        isAccepted: function() { return this.selectedStatus === "accepted"; },
        isRejected: function() { return this.selectedStatus === "rejected"; },
      },
      methods: {
        submitForm: function(e) {
          var form = this.$el.querySelector("form");
          var files = e.target.files;

          if (files.length > 0) {
            form.submit();
          }
        },
        accept: function() {
          this.selectedStatus = "accepted";
        },
        reject: function() {
          this.selectedStatus = "rejected";
        },
      },
    });
  }
}

window.PS.Controllers.VerificationRequestsController = VerificationRequestsController;
PS.Components.register(VerificationRequestsController, { applyOnlyTo: ".v-verification-requests-controller" });
