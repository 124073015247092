((document) => {
  class AnonymousUserInfo {
    onDomReady() {
      // track users without js with each request in Bugsnag
      // if there is no "js=1" line in cookies — js was disabled by user
      document.cookie = "js=1";
    }
  }

  window.PS.Components.AnonymousUserInfo = AnonymousUserInfo;
  PS.Components.register(AnonymousUserInfo);
}).call(this, document);
