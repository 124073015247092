<template>
  <div class="loan-notification-disclaimer">
    <h2>{{ title }}</h2>
    <p class="date">
      Updated {{ date }}
    </p>
    <p class="milestone-text">
      {{ message }}
    </p>
  </div>
</template>
<script>
export default {
  name: "ForeclosureCurrentMilestone",
  props: {
    title: { type: String, default: null },
    date: { type: String, default: null },
    message: { type: String, default: null },
  },
};
</script>
