import SavingsInvestModal from "components/savings/invest_modal/modal";

class SavingsInvestModalController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        SavingsInvestModal,
      },
    });
  }
}

window.PS.Controllers.SavingsInvestModalController = SavingsInvestModalController;
PS.Components.register(SavingsInvestModalController, { applyOnlyTo: ".v-savings-invest-modal" });
