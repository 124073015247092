import BannerV3Slider from "components/banner_v3_slider";
import MarketingBanner from "components/marketing_banner";
import PromoBanner from "components/promo/banner";
import BankruptcyClaimBanner from "components/bankruptcy_claim_banner";
import LearningResourcesSlider from "components/learning_resources_slider";
import InvestmentCatalog from "components/investment_catalog/catalog";
import MfeInvestmentFunds from "components/investment_fund";
import Savings from "components/savings";
import InvestmentCatalogItemFrame from "components/investment_catalog/item_frame";

$(() => {
  new Vue({
    el: document.querySelector(".v-investments-controller"),
    components: {
      MarketingBanner,
      PromoBanner,
      BankruptcyClaimBanner,
      BannerV3Slider,
      LearningResourcesSlider,
      MfeInvestmentFunds,
      Savings,
      InvestmentCatalog,
      InvestmentCatalogItemFrame,
    },
  });
});
