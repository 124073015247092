<template>
  <div class="pocket-investment-confirmation">
    <Total />

    <ConfirmationNote />

    <div class="terms-and-conditions">
      <p>
        {{ termsAndConditions }}
      </p>
    </div>
    <button class="btn-primary-cta btn-block"
            @click="createRecurringDeposits">
      Schedule
    </button>
    <button class="btn btn-block"
            @click="leaveEditMode">
      Edit
    </button>
  </div>
</template>

<script>
import CalendarService from "services/auto_deposits/calendar_service";
import { FREQUENCY_MAPPING } from "models/auto_deposits/frequency";
import Total from "./summary.vue";
import ConfirmationNote from "../confirmation_note.vue";

import RecurringStore from "./store";
import ModalStore from "../store";

export default {
  components: {
    Total,
    ConfirmationNote,
  },

  data() {
    return {
      recurringStoreData: RecurringStore.data,
    };
  },

  computed: {
    settings() {
      return this.recurringStoreData.settings;
    },

    termsAndConditions() {
      let startDate = CalendarService.convertToShortDate(this.settings.startDate);

      if (this.settings.frequency === FREQUENCY_MAPPING.monthly) {
        startDate = CalendarService.nextMonthlyStartDate(this.settings.dayOfMonth);
      } else if (this.settings.frequency === FREQUENCY_MAPPING.monthly1st15th) {
        startDate = CalendarService.nextMonthly1st15thStartDate();
      }

      return "I authorize PeerStreet to initiate the above-referenced recurring bank " +
          "transfer on my behalf, in the amount and frequency set forth above " +
          ` (“Recurring Transfer”), starting on ${startDate}, and to, thereafter, ` +
          "promptly invest each Recurring Transfer into PeerStreet Pocket.";
    },
  },

  methods: {
    leaveEditMode() {
      ModalStore.showInvestForm();
    },

    createRecurringDeposits() {
      RecurringStore.makeUpdateRecurringSettingsRequest({
        resource: this.settings.toServer(),
      },
      (settings) => {
        RecurringStore.updateRecurringSettingsFromServer(settings);
        ModalStore.showRecurringDepositsSuccess();
      });
    },
  },
};
</script>
