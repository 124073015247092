import InvestButton from "components/invest/button";

Vue.component("LoanInvestmentStatus", {
  components: {
    "invest-button": InvestButton,
  },
  props: {
    loan: { type: Object, required: true },
  },
  computed: {
    displayRemainingTerm: function() {
      return (
        !this.loan.matured &&
        (this.loan.performanceStatus === "performing" || this.loan.performanceStatus === "late")
      );
    },
    newUserRegistrationPath: function() {
      var loanUrl = btoa(`/loans/${this.loan.psId}`);

      return `/users/sign_up?rt=${loanUrl}`;
    },
    newOrderPath: function() {
      return `/orders/new?loan_id=${this.loan.psId}`;
    },
    userSignedIn: function() {
      return PSData.user && PSData.user.signedIn === "yes";
    },
    availableForInvestments() {
      return this.loan.availableForInvestments && !this.loan.isFull;
    },
  },
  template: "#v-loan-investment-status-template",
});
