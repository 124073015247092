<template>
  <div class="two-factor-info">
    <div class="form-group">
      <label class="control-label">
        Authentication Method
      </label>

      <p class="two-factor-info-item">
        {{ strategy }}
      </p>
    </div>

    <SmsShow v-if="form.isSms" />

    <div class="alert alert-info"
         role="alert">
      To change authentication methods, turn off then choose settings.
    </div>

    <button class="two-factor-turn-off-button btn btn-block btn-info-cta"
            @click="turnOff()">
      Turn Off
    </button>
  </div>
</template>

<script>
import SmsShow from "./show/sms_show.vue";

import Client from "../client";
import form from "./form.js";

const STRATEGY_TITLES = {
  sms: "Text Message",
  app: "Authenticator App",
};

export default {
  name: "TwoFactorAuthSettingsShow",

  components: {
    SmsShow,
  },

  data() {
    return {
      form: form,
    };
  },

  computed: {
    strategy() {
      return STRATEGY_TITLES[form.strategy];
    },
  },

  methods: {
    async turnOff() {
      const responseJSON = await Client.disable();

      if (responseJSON.success) {
        this.$emit("disable");
      }
    },
  },
};
</script>
