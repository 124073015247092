<template>
  <div>
    <div class="catalog-container">
      <investment-catalog-item v-for="itemData in items"
                               v-bind="itemData"
                               :key="itemData.title" />
    </div>
  </div>
</template>

<script>
import InvestmentCatalogItem from "components/investment_catalog/item";

export default {
  name: "InvestmentCatalog",
  components:  {
    InvestmentCatalogItem,
  },
  data() {
    return {
      items: PSData.investmentCatalog.items,
    };
  },
};
</script>
