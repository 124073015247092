import {
  Chart
} from "chart.js";

class originatorSkinChart {
  setup(originatorSkin, context) {
    var data = [{
        value: Number(originatorSkin),
        color: "#819297",
      },
      {
        value: 100 - Number(originatorSkin),
        color: "#dddddd",
      }
    ];

    var options = {
      segmentShowStroke: false,
      percentageInnerCutout: 90,
      showTooltips: false,
    };

    new Chart(context, {
      type: "doughnut",
      data: data,
      options: options,
    });
  }

  hideTooltip(hidePosition) {
    let tooltip = $(".popover").eq(0);

    if (tooltip.length) {
      let tooltipPos = tooltip.offset().top - $(window).scrollTop();

      if (tooltipPos < hidePosition) {
        tooltip.popover("hide");
      }
    }
  }

  onDomReady($element) {
    var canvas = $element.find(".chart canvas");

    if (canvas.length > 0) {
      var originatorSkin = $(".skin-in-the-game .chart").data("skin");

      this.setup(originatorSkin, canvas[0].getContext("2d"));
    }

    let mediumScreenSize = 992;

    if (window.innerWidth >= mediumScreenSize) {
      let tooltipArrowHeight = 11;
      let hidePosition = $(".page-banner").outerHeight() + tooltipArrowHeight;

      $(window).on("scroll", () => {
        this.hideTooltip(hidePosition);
      });
    }
  }
}

PS.Components.register(originatorSkinChart, {
  applyOnlyTo: ".skin-in-the-game",
});

window.PS.Components.originatorSkinChart = originatorSkinChart;
