import PaymentStatus from "components/loans_v2/payment_status";

class LoanDetailsCtaBoxController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        PaymentStatus,
      },
      data: {
        loanServicingState: PSData.loanServicingState,
      },
    });
  }
}

window.PS.Controllers.LoanDetailsCtaBoxController = LoanDetailsCtaBoxController;
PS.Components.register(LoanDetailsCtaBoxController, { applyOnlyTo: ".v-loan-details-cta-box-controller" });
