<template>
  <div>
    <slot :open-plaid="openPlaidDialog" />
  </div>
</template>
<script>
export default {
  props: {
    clientName: {
      type: String,
      default: "Peerstreet",
    },
    env: {
      type: String,
      default: PSData.global.plaidEnv,
    },
    onEvent: { type: Function, required: true } ,
    onExit: { type: Function, required: true } ,
    onSuccess: { type: Function, required: true } ,
    plaidUrl: {
      type: String,
      default: "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
    },
    product: {
      type: String,
      default: "auth",
    },
    publicKey: {
      type: String,
      default: PSData.global.plaidPublicKey,
    },
  },

  created() {
    this.loadScript(this.plaidUrl)
      .then(this.initPlaidLink);
  },

  beforeDestroy() {
    if (window.linkHandler) {
      window.linkHandler.exit();
    }
  },

  methods: {
    initPlaidLink() {
      window.linkHandler = window.Plaid.create({
        apiVersion: "v2",
        clientName: this.clientName,
        env: this.env,
        key: this.publicKey,
        onEvent: this.onEvent,
        onExit: this.onExit,
        onSuccess: this.onSuccess,
        product: this.product,
      });
    },

    openPlaidDialog() {
      if (window.linkHandler) {
        window.linkHandler.open();
      }
    },

    loadScript(src) {
      return new Promise(function(resolve, reject) {
        if (document.querySelector("script[src=\"" + src + "\"]")) {
          resolve();

          return;
        }

        const el = document.createElement("script");

        el.type = "text/javascript";
        el.async = true;
        el.src = src;
        el.addEventListener("load", resolve);
        el.addEventListener("error", reject);
        el.addEventListener("abort", reject);
        document.head.appendChild(el);
      });
    },
  },
};
</script>
