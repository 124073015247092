import NumberFormatter from "formatters/number_formatter";

const ERRORS = { amount: undefined, maxAllocation: undefined };

export default class UserSettings {
  constructor(data, autoInvestmentSettings) {
    this.errors = Object.assign({}, ERRORS);
    this.autoInvestmentSettings = autoInvestmentSettings;
    this.investmentType = data.investmentType;

    this._originalUserSettings = UserSettings.default(data, this.autoInvestmentSettings);
    this._userSettings = Object.assign({}, this._originalUserSettings);

    this.resolveShowCustomOptions();
  }

  static default(data, autoInvestmentSettings) {
    const defaultSettings = {
      active: data.active,
      amount: NumberFormatter.unmaskNumber(data.amount || autoInvestmentSettings.minAmount),
      maxAllocation: NumberFormatter.unmaskNumber(data.maxAllocation),
    };

    if (autoInvestmentSettings.customSettings) {
      defaultSettings.minRate = data.minRate || autoInvestmentSettings.minRate.default;
      defaultSettings.maxLtv = data.maxLtv || autoInvestmentSettings.maxLtv.default;
      defaultSettings.maxTerm = data.maxTerm || autoInvestmentSettings.maxTerm.default;
    }

    return defaultSettings;
  }

  get active() {
    return this._userSettings.active;
  }

  set active(value) {
    this._userSettings.active = value;
  }

  get amount() {
    return this._userSettings.amount;
  }

  set amount(value) {
    this._userSettings.amount = NumberFormatter.unmaskNumber(value);
  }

  get maxAllocation() {
    return this._userSettings.maxAllocation;
  }

  set maxAllocation(value) {
    this._userSettings.maxAllocation = NumberFormatter.unmaskNumber(value);
  }

  get minRate() {
    return this._userSettings.minRate;
  }

  set minRate(value) {
    this._userSettings.minRate = value;
  }

  get maxLtv() {
    return this._userSettings.maxLtv;
  }

  set maxLtv(value) {
    this._userSettings.maxLtv = value;
  }

  get maxTerm() {
    return this._userSettings.maxTerm;
  }

  set maxTerm(value) {
    this._userSettings.maxTerm = value;
  }

  get showCustomOptions() {
    return this.autoInvestmentSettings.customSettings &&
      this._userSettings.showCustomOptions;
  }

  set showCustomOptions(value) {
    if (!this.autoInvestmentSettings.customSettings) { return; }

    this.active = true;
    this._userSettings.showCustomOptions = value;
  }

  resolveShowCustomOptions() {
    if (!this.autoInvestmentSettings.customSettings) { return; }

    this._userSettings.showCustomOptions = !(this._userSettings.minRate === this.autoInvestmentSettings.minRate.default &&
      this._userSettings.maxLtv === this.autoInvestmentSettings.maxLtv.default &&
      this._userSettings.maxTerm === this.autoInvestmentSettings.maxTerm.default);
  }

  resetCustomOptions() {
    if (!this.autoInvestmentSettings.customSettings) { return; }

    this._userSettings.minRate = this.autoInvestmentSettings.minRate.default;
    this._userSettings.maxLtv = this.autoInvestmentSettings.maxLtv.default;
    this._userSettings.maxTerm = this.autoInvestmentSettings.maxTerm.default;
  }

  clearChanges() {
    this.errors = Object.assign({}, ERRORS);
    Object.assign(this._userSettings, this._originalUserSettings);
    this.resolveShowCustomOptions();
  }

  update(data) {
    const newSettings = UserSettings.default(data, this.autoInvestmentSettings);

    Object.assign(this._originalUserSettings, newSettings);
    Object.assign(this._userSettings, newSettings);
  }

  validate() {
    this.validateAmount();
    this.validateMaxAllocation();
  }

  get valid() {
    return !Object.values(this.errors).some(message => !!message);
  }

  get changed() {
    return this.valuesChanged || this.customSettingsChanged;
  }

  get valuesChanged() {
    return this._userSettings.amount !== this._originalUserSettings.amount ||
      this._userSettings.maxAllocation !== this._originalUserSettings.maxAllocation ||
      this._userSettings.active !== this._originalUserSettings.active;
  }

  get customSettingsChanged() {
    if (!this.autoInvestmentSettings.customSettings) { return false; }

    return this._userSettings.minRate !== this._originalUserSettings.minRate ||
      this._userSettings.maxLtv !== this._originalUserSettings.maxLtv ||
      this._userSettings.maxTerm !== this._originalUserSettings.maxTerm;
  }

  toServer() {
    const settings = {
      investment_type: this.investmentType,
      amount: this._userSettings.amount,
      active: this._userSettings.active,
      max_allocation: this._userSettings.maxAllocation,
    };

    if (this.autoInvestmentSettings.customSettings) {
      settings.min_rate = this._userSettings.minRate;
      settings.max_ltv = this._userSettings.maxLtv;
      settings.max_term = this._userSettings.maxTerm;
    }

    return settings;
  }

  validateAmount() {
    this.errors.amount = undefined;

    if (this._userSettings.amount === undefined || this._userSettings.amount === null) {
      this.errors.amount = "This field is required.";

      return;
    }

    const minAmount = NumberFormatter.unmaskNumber(this.autoInvestmentSettings.minAmount);

    if (this._userSettings.amount < minAmount) {
      this.errors.amount = `Please enter an amount greater than or equal to ${
        NumberFormatter.formatMoney(minAmount, 0)
      }.`;

      return;
    }

    const maxAmount = NumberFormatter.unmaskNumber(this.autoInvestmentSettings.maxAmount);

    if (this._userSettings.amount > maxAmount) {
      this.errors.amount = `Please enter an amount less than or equal to ${
        NumberFormatter.formatMoney(maxAmount, 0)
      }.`;
    }
  }

  validateMaxAllocation() {
    this.errors.maxAllocation = undefined;

    if (this._userSettings.maxAllocation === undefined || this._userSettings.maxAllocation === null) {
      return;
    }

    if (this._userSettings.amount === undefined || this._userSettings.amount === null) {
      return;
    }

    if (this._userSettings.amount > this._userSettings.maxAllocation) {
      this.errors.maxAllocation =
        "Please leave blank or enter a value greater than or equal to Investment Per Loan.";
    }
  }
}
