Vue.component("LoanDetailsBorrowerTable", {
  props: {
    loan: { type: Object, required: true },
  },
  computed: {
    borrowersInfoExist: function(params) {
      return this.loan.borrowersInfo.length !== 0;
    },
  },
  methods: {
    borrowerTitle: function(index) {
      if (this.loan.borrowersInfo.length > 1) {
        return `(Borrower ${index + 1})`;
      }
    },
  },
  template: "#v-loan-details-borrower-table-template",
});
