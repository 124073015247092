<template>
  <component
    :is="bannerType"
    v-if="isVisible"
    ref="banner"
    :tokens-count="tokensCount" />
</template>

<script>
import PromoBannerAutoDeposits from "components/promo/banners/auto_deposits";
import PromoBannerEnableAi from "components/promo/banners/enable_ai";
import PromoBannerInvite from "components/promo/banners/invite";
import PromoBannerYieldBump from "components/promo/banners/yield_bump";

export default {
  name: "PromoBanner",
  props: {
    autoDepositsEnabled: Boolean,
    isInsitution: Boolean,
    isVisible: Boolean,
    tokensCount: { type: String, required: true },
  },
  data() {
    return {
      aiEnabled: PSData.autoInvestingEnabled,
      savingsRequireAgreement: PSData.savings.requireAgreement,
    };
  },
  computed: {
    bannerType() {
      if (this.hasTokens) {
        return PromoBannerYieldBump;
      } else if (this.showAutoDeposits) {
        return PromoBannerAutoDeposits;
      } else if (this.showAiBanner) {
        return PromoBannerEnableAi;
      } else if (this.showInvite) {
        return PromoBannerInvite;
      }
      else {
        return null;
      }
    },
    hasTokens() {
      return false;
      return parseInt(this.tokensCount, 10) > 0;
    },
    showAiBanner() {
      return false;
      return !this.aiEnabled;
    },
    showAutoDeposits() {
      return false;
      return !this.isInsitution && !this.autoDepositsEnabled;
    },
    showInvite() {
      return false;
      return !this.isInsitution;
    },
  },
  mounted() {
    PS.globalBus.$on("auto-investing-status-updated", enabled => {
      this.aiEnabled = enabled;
    });
  },
};
</script>
