import InvestModal from "components/invest/modal";

class InvestController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: { InvestModal: InvestModal },
    });
  }
}

window.PS.Controllers.InvestController = InvestController;
PS.Components.register(InvestController, { applyOnlyTo: ".v-invest-controller" });
