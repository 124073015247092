<template>
  <div v-cloak
       class="bid-confirmation-wrapper">
    <div v-show="showModal"
         class="bid-confirmation-modal">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ loanTitle }}
        </h5>
        <button id="bid-close-button"
                type="button"
                class="close"
                @click="closeModal">
          <i class="fa fa-times" />
        </button>
      </div>
      <div class="modal-body">
        <h5>Bid Percentage: {{ bidPercentage }}</h5>
        <h5>Max Allocation: {{ maxAllocation }}</h5>
        <h5>Expiration Date: {{ expirationDate | date }}</h5>
      </div>
      <div class="modal-footer">
        <button id="bid-confirm-button"
                type="button"
                class="btn btn-primary-cta"
                @click="confirmBid">
          Confirm
        </button>
        <button id="bid-cancel-button"
                type="button"
                class="btn cancel-button"
                @click="closeModal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BuyerBidsService from "services/secondary_market/buyer_bids_service";
import NumberFormatter from "formatters/number_formatter";

import { date } from "filters";

export const OPEN_BID_CONFIRMATION_MODAL_EVENT = "open-bid-confirmation-modal";

export const { formatMoney, formatPercent } = NumberFormatter;

function defaultData() {
  return {
    loanId: "",
    loanTitle: "",
    offerRate: "",
    allocationAmount: "",
    expirationDate: "",
    showModal: false,
  };
}

export default {
  name: "BidConfirmationModal",
  filters: {
    date,
  },
  props: {
    refreshLoans: {
      type: Function,
      required: true,
    },
    toggleForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return defaultData();
  },
  computed: {
    bidPercentage() {
      return formatPercent((parseFloat(this.offerRate) * 100), 0);
    },
    maxAllocation() {
      return formatMoney(this.allocationAmount, 0);
    },
  },
  mounted() {
    PS.globalBus.$on(OPEN_BID_CONFIRMATION_MODAL_EVENT, (params) => {
      this.setBidDetails(params);
      this.showModal = true;
    });
  },
  methods: {
    setBidDetails(params) {
      this.loanId = params.loanId;
      this.loanTitle = params.loanTitle;
      this.offerRate = params.offerRate;
      this.allocationAmount = params.allocationAmount;
      this.expirationDate = params.expirationDate;
    },
    closeModal() {
      this.showModal = false;
      this.toggleForm();
    },
    async confirmBid() {
      const requestBody = {
        loanId: this.loanId,
        expirationDate: this.expirationDate,
        offerRate: this.offerRate,
        allocationAmount: this.allocationAmount,
      };

      let data;

      try {
        data = await BuyerBidsService.createBid(requestBody);
      // Simply catching error for now. UX/PROD do not want to display message on UI.
      // eslint-disable-next-line no-empty
      } catch (error) {}

      if (data && data.success) {
        this.refreshLoans();
      }
    },
  },
};
</script>
