<template>
  <!-- eslint-disable vue/valid-v-bind-sync -->
  <div>
    <p>
      Select which investment types you would like to invest in, and customize
      your investment settings for each based on your specifications. For even
      more options, open
      <a @click="enableCustomOptions">custom settings</a>.
      Check out the
      <a href="https://info.peerstreet.com/faqs/"
         target="_blank">FAQs</a>
      for more information.
      (<a href="/invites"
          target="_blank">Yield bumps</a> are excluded from
      Automated Investing.)
    </p>

    <div class="auto-investments-card auto-investments-settings">
      <h3>Select investment types</h3>

      <auto-investments-item
        v-for="setting in settings"
        :key="setting.investmentType"
        :user-settings.sync="setting"
        :cash-sweep="cashSweep"
        @disable-custom-options="disableCustomOptions(setting)" />

      <div class="auto-investments-item auto-investments-setting"
           :class="reinvestSmallBalancesDisabledClass">
        <label class="control-label checkbox-toggle">
          <input v-model="reinvestSmallBalances"
                 type="checkbox"
                 :disabled="reinvestSmallBalancesDisabled">
          <span class="toggle-control" />
          Reinvest Small Balances in Loans
        </label>

        <p>
          When your investable cash balance reaches $100 or more, it will be automatically invested in loans that match your Bridge Loans Automated Investing settings if available.
          <a href="https://info.peerstreet.com/faqs/what-does-reinvest-small-balances-mean/"
             target="_blank">
            Learn more</a>.
        </p>
      </div>

      <div class="auto-investments-item auto-investments-setting"
           style="display: none;">
        <label class="control-label checkbox-toggle">
          <input v-model="cashSweep"
                 type="checkbox"
                 :disabled="cashSweepDisabled">
          <span class="toggle-control" />
          Sweep Small Balances to {{ pocketClassicOfferingName }}
        </label>

        <p>
          When your investable cash balance reaches $100 or more, it will be automatically invested in {{ pocketClassicOfferingName }} on Sundays.
          <a href="https://info.peerstreet.com/faqs/what-is-sweep-small-balances-to-pocket/"
             target="_blank">
            Learn more</a>.
        </p>
      </div>

      <div v-if="showDisclosure"
           class="auto-investments-item auto-investments-disclosure">
        <div class="checkbox-control">
          <input id="disclosure"
                 v-model="disclosureAccepted"
                 type="checkbox">
          <label for="disclosure">
            By activating Automated Investing, I acknowledge that I have read and understand the
            <a href="https://www.peerstreet.com/disclosure"
               target="_blank">Automated Investing Disclosures</a>,
            <a href="https://peerstreet-static.s3.amazonaws.com/docs/ppm-02.pdf"
               target="_blank">
              Mortgage Payment Dependent Note Private Placement Memorandum</a>, and
            <a href="https://peerstreet-static.s3.amazonaws.com/docs/ia-02.pdf"
               target="_blank">Investor Agreement</a>.
            <br>
            (Required)
          </label>
        </div>
      </div>

      <div v-if="showSavingsAgreement"
           class="auto-investments-item auto-investments-disclosure">
        <div class="checkbox-control">
          <input id="savings-agreement"
                 v-model="savingsAgreementAccepted"
                 type="checkbox">
          <label for="savings-agreement">
            By activating Sweep Small Balances to {{ pocketClassicOfferingName }}, I acknowledge that I have read, understand, and agree to be bound by the terms of the
            <a href="https://peerstreet-static.s3.amazonaws.com/docs/ppm-02.pdf"
               target="_blank">
              Private Placement Memorandum</a>, the
            <a href="https://peerstreet-static.s3.amazonaws.com/docs/ppm-02-rwn-supplement.pdf"
               target="_blank">Redeemable Warehouse Note PPM Supplement </a> and our updated
            <a href="https://peerstreet-static.s3.amazonaws.com/docs/ia-02.pdf"
               target="_blank">Investor Agreement</a>.
            <br>
            (Required)
          </label>
        </div>
      </div>

      <div class="auto-investments-item auto-investments-submit">
        <div v-if="autoInvestingEnabled"
             class="row">
          <div class="col-sm-6 d-none d-sm-flex">
            <a class="btn-info-cta"
               @click="turnOffAutoInvesting">Turn Off</a>
          </div>
          <div class="col-sm-6">
            <button class="btn-primary-cta"
                    :disabled="submitDisabled"
                    @click="updateAutoInvesting">
              Update Settings
            </button>
          </div>

          <div class="col-sm-6 d-flex d-sm-none">
            <a class="btn-info-cta"
               @click="turnOffAutoInvesting">Turn Off</a>
          </div>

          <a class="btn-link"
             @click="clearChanges">Clear Recent Changes</a>
        </div>

        <template v-else>
          <button class="btn-primary-cta"
                  :disabled="submitDisabled"
                  @click="updateAutoInvesting">
            Save &amp; Turn On
          </button>
          <a class="btn-link"
             @click="cancelSetup">Cancel</a>
        </template>
      </div>
    </div>
    <how-it-works-section />
  </div>
</template>

<script>
import Ajax from "services/ajax";
import AutoInvestmentsItem from "components/auto_investments/item";
import HowItWorksSection from "components/auto_investments/how_it_works_section";
import UserSettings from "models/auto_investments/user_settings";
import InvestmentOfferingsService from "services/investment_offerings_service";

export default {
  name: "AutoInvestmentsItems",
  components: {
    AutoInvestmentsItem,
    HowItWorksSection,
  },
  data() {
    const userSettings = this.createUserSettings(
      PSData.userSettings.autoInvestments,
      PSData.autoInvestmentSettings
    );

    return {
      blockUI: false,
      settings: userSettings,
      autoInvestingEnabled: PSData.autoInvestingEnabled,
      reinvestSmallBalances: PSData.userSettings.reinvestSmallBalances,
      cashSweep: PSData.userSettings.cashSweep,
      originalReinvestSmallBalances: PSData.userSettings.reinvestSmallBalances,
      originalCashSweep: PSData.userSettings.cashSweep,
      disclosureAccepted: false,
      savingsRequireAgreement: PSData.savings.requireAgreement,
      savingsAgreementAccepted: false,
    };
  },
  computed: {
    submitDisabled() {
      return this.blockUI ||
               !this.settingsEnabled ||
               !this.agreementsAccepted ||
               !this.settingsValid;
    },
    showDisclosure() {
      if (!this.autoInvestingEnabled && this.settingsEnabled) {
        return true;
      }

      return this.settingsEnabled && this.settingsChanged;
    },
    agreementsAccepted() {
      if (this.cashSweep && this.savingsRequireAgreement) {
        return this.savingsAgreementAccepted && this.disclosureAccepted;
      } else {
        return this.disclosureAccepted;
      }
    },
    pocketClassicOfferingName() {
      return InvestmentOfferingsService.pocketClassicOffering()?.name || "Pocket";
    },
    showSavingsAgreement() {
      // return this.cashSweepSettingsChanged && this.savingsRequireAgreement;
      return false;
    },
    reinvestSmallBalancesDisabledClass() {
      return this.reinvestSmallBalancesDisabled ? "auto-investments-reinvest-disabled" : "";
    },
    settingsValid() {
      return this.settings.every(setting => setting.valid);
    },
    reinvestSmallBalancesDisabled() {
      return !this.activeInvestmentSettings;
    },
    cashSweepDisabled() {
      return this.activeInvestmentSettings;
    },
    activeInvestmentSettings() {
      return this.settings.some(setting => setting.active);
    },
    settingsEnabled() {
      return this.activeInvestmentSettings ||
               this.cashSweepSettingsChanged;
    },
    cashSweepSettingsChanged() {
      return this.cashSweep != this.originalCashSweep;
    },
    settingsChanged() {
      // eslint-disable-next-line eqeqeq
      return this.reinvestSmallBalances != this.originalReinvestSmallBalances ||
               this.cashSweepSettingsChanged ||
               this.settings.some(setting => setting.changed);
    },
  },
  watch: {
    reinvestSmallBalancesDisabled(val) {
      if (val) {
        this.reinvestSmallBalances = false;
      }
    },
    showDisclosure(val) {
      if (!val) {
        this.disclosureAccepted = false;
      }
    },
    showSavingsAgreement(val) {
      if (!val) {
        this.savingsAgreementAccepted = false;
      }
    },
  },
  methods: {
    createUserSettings(autoInvestments, autoInvestmentSettingsList) {
      const settings = [];

      autoInvestments.forEach(autoInvestment => {
        const autoInvestmentSettings = autoInvestmentSettingsList.find(aiSettings => {
          return aiSettings.investmentType === autoInvestment.investmentType;
        });

        if (autoInvestmentSettings) {
          settings.push(new UserSettings(autoInvestment, autoInvestmentSettings));
        }
      });

      return settings;
    },
    enableCustomOptions() {
      this.settings.forEach((setting) => {
        setting.showCustomOptions = true;
      });
    },
    clearChanges() {
      this.settings.forEach(setting => setting.clearChanges());
      this.reinvestSmallBalances = this.originalReinvestSmallBalances;
      this.cashSweep = this.originalCashSweep;
    },
    cancelSetup() {
      this.clearChanges();
      this.$emit("setup-canceled");
    },
    updateAutoInvesting() {
      this.performRequest(() => {
        this.$emit("settings-updated");
      });
    },
    turnOffAutoInvesting() {
      this.clearChanges();
      this.settings.forEach(setting => {
        setting.active = false;
      });
      this.reinvestSmallBalances = false;
      this.cashSweep = false;
      this.savingsAgreementAccepted = false;

      this.performRequest(() => {
        this.$emit("turned-off");
      });
    },
    performRequest(callback) {
      this.blockUI = true;

      return Ajax.patch("user/auto_investment",
        { data: JSON.stringify(this.generateServerSettings()) },
        { "Content-Type": "application/json", "Accept": "application/json" })
        .then((data) => {
          this.syncSettings(data);
          callback();
        })
        .catch(({ data }) => {
          this.$emit("error", data.error);
        })
        .finally(() => {
          this.blockUI = false;
          PS.Services.WindowService.scrollTo({ top: 0 });
        });
    },
    generateServerSettings() {
      const investments = this.settings.map(setting => {
        return setting.toServer();
      });

      return {
        reinvest_small_balances: this.reinvestSmallBalances,
        // cash_sweep: this.cashSweep,
        // savings_agreement_accepted: this.savingsAgreementAccepted,
        auto_investments: investments,
      };
    },
    syncSettings(data) {
      const mapping = {};

      data.autoInvestments.forEach(setting => {
        mapping[setting.investmentType] = setting;
      });

      this.settings.forEach((setting, index) => {
        setting.update(mapping[setting.investmentType]);
      });

      this.autoInvestingEnabled = this.calculateEnabled(this.settings);
      this.reinvestSmallBalances = data.reinvestSmallBalances;
      this.originalReinvestSmallBalances = data.reinvestSmallBalances;
      this.cashSweep = data.cashSweep;
      this.originalCashSweep = data.cashSweep;
      this.savingsAgreementAccepted = data.savingsAgreementAccepted;
    },
    calculateEnabled(settings) {
      return settings.some((setting) => {
        return setting.active || this.cashSweep;
      });
    },
    disableCustomOptions(setting) {
      setting.showCustomOptions = false;
      setting.resetCustomOptions();
    },
  },
};
</script>
