Vue.component("LoanInvestmentInfo", {
  props: {
    amount: { type: [String, Number], default: 0 },
    seriesAmount: { type: [String, Number], default: 0 },
  },
  computed: {
    relatedAmount: function() {
      return this.seriesAmount - this.amount;
    },
    isDisplayed: function() {
      return this.relatedAmount > 0;
    },
  },
  template: "#v-loan-investment-info-template",
});
