import Vue from "vue";
import moment from "moment";

class Directive {
  static options(binding) {
    const isObject = typeof binding.value === "object";

    if (binding.value && !isObject) {
      console.error(
        `Expected Object for binding.value but was ${binding.value}. Found in v-${
          binding.name
        } directive.`
      );
    }

    return isObject ? binding.value : {};
  }

  static forceChangeEvent() {
    const event = document.createEvent("HTMLEvents");

    event.initEvent("input", true, true);
    this.dispatchEvent(event);
    $(this).trigger("change");
  }
}

export default class Mask extends Directive {
  // eslint-disable-next-line complexity
  static bind(el, binding) {
    const options = super.options(binding);

    switch (binding.arg) {
    case "number":
      // falls through

    case "currency":
      return Mask.number(el, options, { round: 2, minus: true });

    case "zip":
      return Mask.digits(el, options, { mask: "[99999]" });

    case "phone":
      return Mask.visible(el, options, { mask: "(999) 999-9999" });

    case "us_phone":
      return Mask.visible(el, options, {
        mask: "+1 ([999]) [999-9999]",
        onBeforePaste: function(pastedValue) {
          return pastedValue.replace(/[^0-9]/g, "");
        },
      });

    case "date":
      return Mask.date(el, options, { alias: "datetime" });

    default:
      console.error(`Invalid binding.arg ${binding.arg} in v-${binding.name} directive.`);
    }
  }

  static unbind(el) {
    $(el).inputmask("remove");
  }

  static number(el, options, defaults) {
    Mask._init(el, {
      alias: "numeric",
      autoGroup: true,
      groupSeparator: ",",
      digits: "round" in options ? options.round : defaults.round,
      radixPoint: ".",
      allowPlus: false,
      allowMinus: "minus" in options ? options.minus : defaults.minus,
      rightAlign: false,
      clearMaskOnLostFocus: false,
    });
  }

  static digits(el, options, defaults) {
    Mask._init(el, {
      mask: "mask" in options ? options.mask : defaults.mask,
      digits: 0,
      greedy: false,
      allowPlus: false,
      allowMinus: false,
      rightAlign: false,
      clearMaskOnLostFocus: false,
      placeholder: "",
    });
  }

  static date(el, options, defaults = {}) {
    Mask._init(el, {
      alias: "alias" in options ? options.alias : defaults.alias,
      showMaskOnHover: false,
      inputFormat: "mm/dd/yyyy",
      placeholder: "MM/DD/YYYY",
      yearrange: {
        minyear: 1000,
        maxyear: 9999,
      },
    });
  }

  static visible(el, options, defaults = {}) {
    Mask._init(el, {
      ...options,
      mask: "mask" in options ? options.mask : defaults.mask,
      showMaskOnHover: false,
    });
  }

  static _init(el, options) {
    $(el).inputmask(
      Object.assign(options, {
        oncomplete: super.forceChangeEvent,
        oncleared: super.forceChangeEvent,
      })
    );
  }
}

export class DatePickerDirective extends Directive {
  static bind(el, binding) {
    let options = super.options(binding);

    el.addEventListener("blur", DatePickerDirective.onBlurEventHandler);

    $(el).datepicker({
      dateFormat: "format" in options ? options.format : "mm/dd/yy",
      prevText: "<i class=\"fa fa-chevron-left\"></i>",
      nextText: "<i class=\"fa fa-chevron-right\"></i>",
      onSelect: super.forceChangeEvent,
      onClose: DatePickerDirective.onCloseEventHandler,
      maxDate: options.maxDate,
    });
  }

  static unbind(el) {
    $(el).datepicker("destroy");
  }

  static onCloseEventHandler(_value, datepicker) {
    DatePickerDirective.validateDate(datepicker.input.get(0));
  }

  static onBlurEventHandler(e) {
    const datepickerClosed = !$(e.target)
      .datepicker("widget")
      .is(":visible");

    if (datepickerClosed) {
      DatePickerDirective.validateDate(e.target);
    }
  }

  static validateDate(input) {
    const dateFormat = $(input).datepicker("option", "dateFormat");

    if (!moment(input.value, dateFormat).isValid()) {
      $(input).datepicker("setDate", "");
      super.forceChangeEvent.call(input);
    }
  }
}

Vue.directive("mask", {
  bind: Mask.bind,
  unbind: Mask.unbind,
});
