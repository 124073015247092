const COOKIE_NAME = "intercom_just_signed_out";

class IntercomService {
  static loadSignedUserSession() {
    window.Intercom("boot", {
      app_id:    PSData.global["apiIntercomId"],
      user_id:   PSData.user["id"],
      user_hash: PSData.user["intercomUserHash"],
    });
  }

  static loadGuestUserSession() {
    window.Intercom("boot", {
      app_id:    PSData.global["apiIntercomId"],
    });
  }

  static resetUserSession() {
    window.Intercom("shutdown");
  }

  static deleteSingedOutCookie() {
    document.cookie = `${COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  static startTour(tourId) {
    if (window.Intercom) {
      window.Intercom("startTour", tourId);
    }
  }

  static load() {
    if (PSData.user["signedIn"] === "yes") {
      IntercomService.loadSignedUserSession();
    } else {
      let userJustSignedOut = document.cookie.indexOf(COOKIE_NAME) > -1;

      IntercomService.loadGuestUserSession();

      if (userJustSignedOut) {
        IntercomService.resetUserSession();
        IntercomService.loadGuestUserSession();
      }
    }

    IntercomService.deleteSingedOutCookie();
  }
}

window.PS.loadIntercom = IntercomService.load;
window.PS.Services.IntercomService = IntercomService;
