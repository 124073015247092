import SavingsRwn from "components/savings/rwn";

$(() => {
  new Vue({
    el: document.querySelector(".v-rwn-controller"),
    components: {
      SavingsRwn,
    },
  });
});
