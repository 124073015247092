Vue.component("LoansListPage", {
  props: {
    loans: { type: Array, default: () => [] },
    page: { type: String, default: "" },
    title: { type: String, default: "" },
  },
  data: () => {
    return {
      order: PSData.sortOrder,
      sortColumn: PSData.sortColumn,
    };
  },
  methods: {
    sortBy: function(column) {
      let order;

      if (column !== this.sortColumn) {
        order = "desc";
      } else {
        order = this.order === "asc" ? "desc" : "asc";
      }

      const path = `/loans/${this.page}?sort=${column}&order=${order}`;

      window.location.replace(`${window.location.origin}${path}`);
    },
  },
  template: "#v-loans-list-page-template",
});
