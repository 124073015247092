#############################################
# BEGIN ONREADY/ONLOAD
#############################################

broadcastPopoverEvents = ->
  $(window).on 'shown.bs.popover', -> PS.globalBus.$emit("popoverActive", true)
  $(window).on 'hidden.bs.popover', -> PS.globalBus.$emit("popoverActive", false)

addExternalLinkClass = ->
  $('a').each ->
    host   = new RegExp(window.location.host)
    unless host.test(this.href)
      $(this).addClass 'external'
      $(this).attr 'target', '_blank'
        .attr 'rel', 'noopener noreferrer'

showHideOnHover = ->
  $(".on-parent-hover").parent().hover (->
    $(this).find(".on-parent-hover").fadeIn 400
  ), ->
    $(this).find(".on-parent-hover").fadeOut 400

resetRemoteModals = ->
  $('.modal.remote').on 'hidden.bs.modal', (e) ->
    $(this).removeData('bs.modal')

# needed for historical loan writeups only
startPopovers = ->
  $("[data-toggle=popover]").popover().click (e) => e.preventDefault()

smoothAnchorLinks = ->
  $page = $('html, body')

  $('.smooth-anchor a').on 'click', (e) ->
    ignoreOtherPages = this.href.indexOf(window.location.pathname) == -1
    return if ignoreOtherPages

    e.preventDefault()

    target = this.hash
    $location = $(target)

    if $(this).data('offset') != undefined
      extraOffset = $(this).data('offset')
    else
      extraOffset = 50

    $page.stop().animate {
      'scrollTop' : $location.offset().top - extraOffset + 'px'
    }, 777, (->
      $location.removeAttr('id')
      window.location.hash = target
      $location.attr('id', target.substring(1))
    )

toTheTop = ->
  $page = $('html, body')

  $('.footer .divider img.logo').on 'click', ->
    $page.stop().animate { 'scrollTop' : 0}, 777

# For IE9 since it can't handle placeholder text
placeholderMagic = ->
  $('input, textarea').placeholder()

maskInputs = ->
  $('input.us-ssn').inputmask("999-99-9999")
  $('input.us-ein').inputmask('99-9999999')
  $('input.us-phone').inputmask("(999) 999-9999")

characterCountLimit = ->
  $('.character-limit').on 'keyup', ->
    limit = $(this).attr 'maxlength'
    remaining = limit - $(this).val().length

    if remaining <= 10
      $(this).parents().find('.limit-counter').addClass 'warning'
    else if remaining > 10
      $(this).parents().find('.limit-counter').removeClass 'warning'

    $(this).parents().find('.limit-counter span').text remaining

geoComplete = ->
  $('.geo-complete input').geocomplete
    details: '.geocomplete-details',
    detailsAttribute: 'data-geo'

beginSlideDowns = ->
  $(".delay-slide").delay(1000).slideDown(500)

revealHiddenFields = ->
  $(".hidden-fields-control input[name='existing_debt']").change ->
    if $('#existing_debt_true').is(':checked')
      $('.loan-collateral').addClass('visible').find('input').val('')
    else
      $('.loan-collateral').removeClass('visible').find('input').val('')

populateInviteTokenForm = ->
  # Populates invite token form with user details if provided.
  $('.authform a[data-toggle="tab"]').on 'shown.bs.tab', (e) ->
    inviteForm = $('form#new_user')
    tokenForm  = $('#tokenTab form')

    $('#token', tokenForm).focus()

    first_name = $('input#user_first_name', inviteForm).val()
    last_name  = $('input#user_last_name', inviteForm).val()
    email      = $('input#user_email', inviteForm).val()

    $('input.user_first_name', tokenForm).val(first_name)
    $('input.user_last_name', tokenForm).val(last_name)
    $('input.user_email', tokenForm).val(email)

positionSidebar = ($target, topPos, topScrollPos, bottomPos, bottomScrollPos) ->
  if $(window).scrollTop() > bottomScrollPos
    $target.css { position: 'absolute', top: bottomPos }

  else if $(window).scrollTop() > (topScrollPos)
    $target.css { position: 'fixed', top: topScrollPos }

  else if $(window).scrollTop() < topScrollPos
    $target.css { position: 'absolute', top: topPos }

revealReadMore = ->
  $readMoreBox = $('.js-read-more-panel')

  $readMoreBox.each ->
    boxHeight = $(this).height()

    if boxHeight > 230 && !$(this).hasClass('js-panel-comp')
      $(this).addClass('read-more-present')

    $('.js-read-more', $(this)).on 'click', (e) ->
      e.preventDefault()

      $parent = $(this).parents('.js-read-more-panel')
      $parent.toggleClass('open-panel')

setCapitalStackChartHeight = ->
  tableHeight = $('.capital-stack-table').height()

  $('.capital-stack-chart').css("height", "#{tableHeight}px")

showDepositSections = ->
  $page = $('html, body')
  method = $("input:radio[name=method]:checked").val()
  $(".section.method[method=" + method + "]").fadeIn(100)

  $("input:radio[name=method]").change ->
    method = $(this).val()
    $.each $(".section.method"), ->
      $(this).fadeOut(100) unless $(this).attr("method") is method
    $(".section.method[method=" + method + "]").fadeIn 500, ->
      if isMobile()
        $page.animate {
          'scrollTop' : $(".funding-method").offset().top + 'px'
        }, 777
      $('.new-amount').focus()

    $(".card-transfer-inner").addClass('card-disabled')
    $(".panel-" + method).removeClass('card-disabled')

setupPageListeners = ->
  resetRemoteModals()
  startPopovers()
  showHideOnHover()
  toTheTop()
  placeholderMagic()
  characterCountLimit()
  geoComplete()
  beginSlideDowns()
  revealHiddenFields()
  showDepositSections()
  broadcastPopoverEvents()

onReady = ->
  setupPageListeners()
  populateInviteTokenForm()
  setCapitalStackChartHeight()

$(onReady)

onLoad = ->
  addExternalLinkClass()
  maskInputs()
  smoothAnchorLinks()
  window.validateForms()
  window.applyJQueryValidations()

onSuccess = ->
  maskInputs
  window.validateForms

$(window).on "load", onLoad

$(document).on 'ajaxSuccess', onSuccess

#############################################
# END ONREADY/ONLOAD
#############################################

#############################################
# BEGIN HELPERS
#############################################

window.isMobile = ->
  window.innerWidth <= 768

#############################################
# END HELPERS
#############################################
