<template>
  <div>
    <OfferingPicker />

    <div>
      <label class="control-label">From</label>
      <p id="account-number-notification">
        Funds will be transferred from account ***{{ modalStoreData.bankAccountLastFour }}.
      </p>
    </div>

    <div class="control-wrapper recurring-edit-form-row">
      <div id="frequency-selector"
           :class="{'has-error': settings.errors.frequency}">
        <label class="control-label">Frequency</label>

        <select v-model="settings.frequency"
                :class="{'wide-select': isFrequencyFirstAnd15th}"
                class="form-control custom-select">
          <option v-for="frequency in frequencies"
                  :key="frequency.key"
                  :value="frequency.value">
            {{ frequency.name }}
          </option>
        </select>

        <label v-show="settings.errors.frequency"
               class="error control-label">
          {{ settings.errors.frequency }}
        </label>
      </div>

      <div v-show="settings.showWeekly || settings.showEveryOtherWeek"
           id="day-of-week-selector"
           :class="{'has-error': settings.errors.dayOfWeek}">
        <label class="control-label"> Day of the Week *</label>

        <select v-model="settings.dayOfWeek"
                class="form-control custom-select">
          <option
            v-for="(name, number) in daysOfWeek"
            :key="name"
            :value="number + 1">
            {{ name }}
          </option>
        </select>

        <label v-show="settings.errors.dayOfWeek"
               class="error control-label">
          {{ settings.errors.dayOfWeek }}
        </label>
      </div>
    </div>

    <div v-show="settings.showMonthly">
      <div id="day-of-month-selector"
           class="control-wrapper"
           :class="{'has-error': settings.errors.dayOfMonth}">
        <label class="control-label">Day of the Month</label>

        <select v-model="settings.dayOfMonth"
                class="form-control custom-select">
          <option v-for="day in daysOfMonth"
                  :key="day"
                  :value="day">
            {{ day | ordinalize }}
          </option>
        </select>

        <label v-show="settings.errors.dayOfMonth"
               class="error control-label">
          {{ settings.errors.dayOfMonth }}
        </label>
      </div>
    </div>

    <div v-show="settings.showWeekly || settings.showEveryOtherWeek">
      <div id="start-date-selector"
           class="control-wrapper"
           :class="{'has-error': settings.errors.startDate}">
        <label class="control-label"> Start Date *</label>

        <select v-model="settings.startDate"
                class="form-control custom-select">
          <option v-for="date in weeklyStartDates"
                  :key="date.value"
                  :value="date.value">
            {{ date.text }}
          </option>
        </select>

        <label v-show="settings.errors.startDate"
               class="error control-label">
          {{ settings.errors.startDate }}
        </label>
      </div>
    </div>

    <label>Choose Amount</label>
    <div :class="{'has-error': settings.errors.amount}"
         class="control-wrapper">
      <div class="pocket-modal-invest-options">
        <div v-for="(option) in radioOptions"
             :key="`predefined-amount-${option.value}`"
             class="form-check">
          <input :id="`invest-option-${option.value}-bank`"
                 v-model="predefinedAmount"
                 class="form-check-input"
                 type="radio"
                 :value="option.value"
                 name="amount"
                 @change="setAmount">
          <label class="form-check-label"
                 :for="`invest-option-${option.value}-bank`">
            {{ option.label }}
          </label>
        </div>
      </div>

      <label v-show="!customAmountShown && settings.errors.amount"
             class="error control-label">
        {{ settings.errors.amount }}
      </label>

      <template v-if="customAmountShown">
        <div class="control-wrapper custom-amount">
          <div class="form-group currency required">
            <label class="control-label"
                   for="amount">Amount</label>
          </div>

          <div class="input-group-wrapper">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                id="amount"
                ref="customAmountInput"
                v-model="customAmount"
                v-mask:currency
                type="text"
                class="form-control currency"
                :class="{ 'error-control': settings.errors.amount }"
                autocomplete="off"
                @input="setCustomAmount">
            </div>
            <label v-show="settings.errors.amount"
                   class="error control-label">
              {{ settings.errors.amount }}
            </label>
          </div>
        </div>
      </template>
    </div>
    <div class="recurring-edit-buttons form-bottons">
      <button class="btn-primary-cta btn-block"
              :disabled="submitDisabled"
              @click="reviewDeposit">
        Review
      </button>
      <button v-if="inEditMode"
              class="btn btn-block"
              @click="leaveEditMode">
        Cancel
      </button>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-mutating-props */

import CalendarService, {
  DAYS_OF_MONTH,
  DAYS_OF_WEEK
} from "services/auto_deposits/calendar_service";

const PREDEFINED_AMOUNTS = [10000, 5000, 1000];

import OfferingPicker from "./fixed_offering_picker.vue";

import NumberFormatter from "formatters/number_formatter";
export const { formatMoney } = NumberFormatter;
import { money, ordinalize } from "filters";
import { FREQUENCY_MAPPING } from "models/auto_deposits/frequency";
import Mask from "directives/inputs";

import RecurringStore from "./store";
import ModalStore from "../store";

export default {
  name: "RecurringDepositEditor",

  components: {
    OfferingPicker,
  },

  filters: {
    ordinalize, money,
  },

  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
  },

  data() {
    return {
      daysOfMonth: DAYS_OF_MONTH,
      daysOfWeek: DAYS_OF_WEEK,
      predefinedAmount: "",
      customAmount: "",
      recurringStoreData: RecurringStore.data,
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    settings() {
      return this.recurringStoreData.settings;
    },

    frequencies() {
      return this.recurringStoreData.frequencies;
    },

    inEditMode() {
      return this.recurringStoreData.inEditMode;
    },

    submitDisabled() {
      return !this.settings.valid;
    },

    weeklyStartDates() {
      return CalendarService.weeklyStartDates(this.settings.dayOfWeek);
    },

    radioOptions() {
      const predefinedOptions = PREDEFINED_AMOUNTS.map((amount) => {
        return { value: amount, label: formatMoney(amount) };
      });

      return [ ...predefinedOptions, { value: "custom", label: "Custom Amount" } ];
    },

    customAmountShown() {
      return this.predefinedAmount === "custom";
    },

    isFrequencyFirstAnd15th() {
      return this.settings.frequency === FREQUENCY_MAPPING.monthly1st15th;
    },
  },

  watch: {
    "settings.dayOfWeek": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.settings.startDate = this.weeklyStartDates[0].value;
      }
    },
    customAmountShown(newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => this.$refs.customAmountInput.focus());
      }
    },
  },

  mounted() {
    this.initializeAmount();
    this.initializeStartDate();
  },

  methods: {
    initializeAmount() {
      if (!this.settings.amount) {
        this.predefinedAmount = this.settings.amount = PREDEFINED_AMOUNTS[0];
      } else if (PREDEFINED_AMOUNTS.includes(this.settings.amount)) {
        this.predefinedAmount = this.settings.amount;
      } else {
        this.predefinedAmount = "custom";
        this.customAmount = this.settings.amount;
      }
    },

    initializeStartDate() {
      const dateIrrelivant = !this.weeklyStartDates.some(startDate => {
        return startDate.value === this.settings.startDate;
      });

      if (dateIrrelivant) {
        this.settings.startDate = this.weeklyStartDates[0].value;
      }
    },

    leaveEditMode() {
      RecurringStore.leaveEditMode();
    },

    setAmount() {
      if (event.target.value === "custom") {
        this.customAmount = null;
        this.settings.resetAmount();
      } else {
        this.recurringStoreData.settings.amount = event.target.value;
      }
    },

    setCustomAmount() {
      this.recurringStoreData.settings.amount = event.target.value;
    },

    reviewDeposit() {
      ModalStore.showRecurringDepositsConfirmation();
    },
  },
};
</script>
