$(() => {
  const PRINCIPAL_TRANSFER_CATEGORY = "principal_transfer";

  new Vue({
    el: document.querySelector(".v-distribution-form-controller"),

    data: {
      isLoading: true,
      distribution: PSData.distribution,
      isLoanPaidOff: PSData.loanPaidOff,
      selectedPayOff: false,
      category: "",
    },

    computed: {
      isAmountDisabled: function() {
        return this.isCategoryPrincipalTransfer || this.selectedPayOff;
      },

      isParentIdDisabled: function() {
        return this.isLoading || this.selectedPayOff;
      },

      isPayOffDisabled: function() {
        return this.isCategoryPrincipalTransfer || this.isAmountNegative();
      },

      isCategoryPrincipalTransfer: function() {
        return this.category === PRINCIPAL_TRANSFER_CATEGORY;
      },

      negative: function() {
        return this.isAmountNegative();
      },
    },

    created: function() {
      PS.globalBus.$on("select2SelectedValue", this.updateDistributions.bind(this));
    },

    beforeDestroy: function() {
      PS.globalBus.$off("select2SelectedValue", this.updateDistributions.bind(this));
    },

    mounted: function() {
      if (!this.distribution.parent_id) {
        this.distribution.parent_id = "";
      }

      let loanId = $("#distribution_loan_id").val();

      if (loanId) {
        this.updateDistributions(loanId);
      }
    },

    methods: {
      updateDistributions: function(id) {
        this.isLoading = true;
        PS.Services.Ajax.get(this.resourceUrl(id)).then((data) => {
          this.populateOptions(data);
          this.isLoading = false;
          this.setDefaultParentIDforPaidOffLoan(data);
        });
      },

      isAmountNegative: function() {
        return this.distribution.amount.toString().charAt(0) === "-";
      },

      togglePayOff: function() {
        this.selectedPayOff = !this.selectedPayOff;

        if (this.selectedPayOff) {
          this.setDefaultAmount();
          this.setDefaultParentId();
        }
      },

      setDefaultAmount: function() {
        this.distribution.amount = 0;
      },

      setDefaultParentId: function() {
        this.distribution.parent_id = "";
      },

      resourceUrl: function(loanId) {
        return `/manage/loans/${loanId}/principals`;
      },

      populateOptions: function(data) {
        let select = $("#distribution_parent_id");

        select.children("option:not(:first)").remove();

        data.forEach((e) => {
          select.append($("<option>").text(e.label).attr("value", e.id));
        });
      },

      setDefaultParentIDforPaidOffLoan: function(data) {
        if (this.isLoanPaidOff) {
          this.distribution.parent_id = data[0].id;
        }
      },

      onFormSubmit: function(e) {
        if (
          this.isAmountNegative() &&
          // eslint-disable-next-line no-alert
          !confirm("Are you sure you want to create a negative distribution?")
        ) {
          e.preventDefault();
        }

        if (
          this.isLoanPaidOff &&
          // eslint-disable-next-line no-alert
          !confirm(
            "The loan has already been paid off. Are you sure you want to do a distribution?"
          )
        ) {
          e.preventDefault();
        }
      },
    },
  });
});
