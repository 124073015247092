$(() => {
  const listingsList = $("#job-openings");

  if (listingsList.length > 0) {
    return PS.Services.Greenhouse.listJobs((jobData) => {
      const jobsByDepartment = jobData.jobs.reduce((acc, job) => {
        const { name } = job.departments[0];

        acc[name] = (acc[name] || []).concat([job]);

        return acc;
      }, {});

      Object.keys(jobsByDepartment).forEach((name) => {
        const listings = jobsByDepartment[name].map(({ id, title, offices }) => {
          return `
            <li class="job-opening">
              <a href="https://boards.greenhouse.io/embed/job_app?for=peerstreet&token=${id}">
                <p class="job-title">${title}</p>
                <p class="job-location">${offices[0].name}</p>
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
          `;
        });

        const department = $(`
          <h3 class="job-category-name">${name}</h3>
          <ul class="job-category-openings">
            ${listings.join("\n")}
          </ul>
        `);

        listingsList.append(department);
      });
    });
  }
});
