<template>
  <div class="auto-deposits">
    <div class="col-sm-8 auto-deposits-settings">
      <div class="auto-deposits-confirm-page">
        <h1>Confirm Recurring Deposit</h1>

        <ul class="auto-deposits-confirm-list">
          <li>
            From
            <span>{{ account }}</span>
          </li>

          <li>
            Frequency
            <span>{{ frequency }}</span>
          </li>

          <li v-show="settings.showMonthly">
            Day of the Month
            <span>
              {{ settings.dayOfMonth | ordinalize }}
            </span>
          </li>

          <li v-show="settings.showWeekly || settings.showEveryOtherWeek">
            Day of the Week
            <span>{{ dayOfWeek }}</span>
          </li>

          <li v-show="settings.showWeekly || settings.showEveryOtherWeek">
            Start Date
            <span>{{ frequencyCopy.nextDate }}</span>
          </li>

          <li>
            Amount
            <span>{{ settings.amount | money }}</span>
          </li>
        </ul>

        <p class="auto-deposits-confirm-note">
          I authorize PeerStreet to initiate a bank transfer on my behalf in the amount of
          {{ settings.amount | money }}
          {{ frequencyCopy.details }} starting on {{ frequencyCopy.nextDate }}.

          I have read and understand the
          <a href="/transactions/ach_deposits/instant_funding_terms"
             target="_blank">Instant Funding Terms</a>.
        </p>

        <slot name="submit-deposit-button" />
        <slot name="edit-deposit-button" />
      </div>
    </div>

    <div class="col-sm-4 security-details-info">
      <security-details />
    </div>
  </div>
</template>
<script>
  import CalendarService, { DAYS_OF_WEEK } from "services/auto_deposits/calendar_service";
  import { money, ordinalize } from "filters";
  import { FREQUENCY_MAPPING } from "models/auto_deposits/frequency";
  import SecurityDetails from "components/auto_deposits/security_details";

  export default {
    components: {
      "security-details": SecurityDetails,
    },
    filters: {
      ordinalize, money,
    },
    props: {
      settings: { type: Object, default: null },
      account: { type: String, default: null },
      frequencies: { type: Object, default: null },
    },
    data() {
      return {
        daysOfWeek: DAYS_OF_WEEK,
      };
    },
    computed: {
      frequency() {
        return this.frequencies[this.settings.frequency].description;
      },
      dayOfWeek() {
        return this.daysOfWeek[this.settings.dayOfWeek - 1];
      },
      frequencyCopy() {
        switch (this.settings.frequency) {
          case FREQUENCY_MAPPING.monthly:
            return {
              details: `on the ${ordinalize(this.settings.dayOfMonth)} of every month`,
              nextDate: CalendarService.nextMonthlyStartDate(this.settings.dayOfMonth),
            };

          case FREQUENCY_MAPPING.monthly1st15th:
            return {
              details: "on the 1st & 15th of every month",
              nextDate: CalendarService.nextMonthly1st15thStartDate(),
            };

          case FREQUENCY_MAPPING.weekly:
            return {
              details: `every ${this.dayOfWeek}`,
              nextDate: CalendarService.convertToShortDate(this.settings.startDate),
            };

          case FREQUENCY_MAPPING.everyOtherWeek:
            return {
              details: `every other ${this.dayOfWeek}`,
              nextDate: CalendarService.convertToShortDate(this.settings.startDate),
            };
        }

        return "";
      },
    },
  };
</script>
