import submitWithRecaptcha from "components/submit_with_recaptcha";

class RecaptchaContainerController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        submitWithRecaptcha,
      },
    });
  }
}

window.PS.Controllers.RecaptchaContainerController = RecaptchaContainerController;
PS.Components.register(RecaptchaContainerController, { applyOnlyTo: ".v-recaptcha-container" });
