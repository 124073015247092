import AccountInformation from "components/account/information";
import BeneficiaryInformation from "components/account/beneficiary_information";
import SecurityInformation from "components/account/security_information";

$(() => {
  new Vue({
    el: document.querySelector(".v-account-controller"),
    components: {
      AccountInformation,
      SecurityInformation,
      BeneficiaryInformation,
    },
  });
});
