import orderBy from "lodash/orderBy";

const ORDERED_PAYMENT_STATUSES = [
  "pending",
  "performing",
  "deferred",
  "late_30",
  "late_60",
  "late_90",
  "default",
  "reo",
  "paid_off",
  "short_pay"
];

const SORT_PROPS_MAPPING = {
  earnings: "earnings",
  investment: "amount",
  lastnotice: p => p.loanServicingState.lastNotificationAtSec || Infinity,
  loan: "title",
  matures: "maturedAtSec",
  paidoff: "paidOffAtSec",
  notices: p => p.loanServicingState.notificationsCount,
  principal: "remainingPrincipal",
  rate: "bumpedInvestmentReturn",
  start: "startAtSec",
  status: p => ORDERED_PAYMENT_STATUSES.indexOf(p.loanServicingState.paymentStatus),
};

export default class PositionsSorter {
  static sort(positions, sortColumn, sortOrder) {
    return orderBy(positions, SORT_PROPS_MAPPING[sortColumn] || sortColumn, sortOrder);
  }
}
