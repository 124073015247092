<template>
  <div :id="name"
       class="earnings-chart" />
</template>

<script>
import NumberFormatter from "formatters/number_formatter";

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const MORRIS_OPTIONS = {
  xkey: "date",
  ykeys: ["total"],
  labels: ["Total", "Month"],
  preUnits: "$",
  hideHover: true,
  smooth: false,
  xLabels: "month",
  resize: true,
  xLabelFormat: x => MONTHS[x.getMonth()] + x.toString(" 'yy"),
  dateFormat: x => new Date(x).toString("MMMM 'yy"),
  hoverCallback: (index, options, content, row) => {
    let sign;

    switch (Math.sign(row["month"])) {
      case -1:
        sign = "-";
        break;

      case 1:
        sign = "+";
        break;

      case 0:
        sign = "";
        break;
    }

    const monthAmount = NumberFormatter.formatMoney(row["month"], 2, { plus: true });

    const monthLabelHtml =
      `<div class='morris-hover-point' style='color: #0b62a4'>
        Month: ${monthAmount}
      </div>`;

    const idx = content.indexOf("<div class='morris-hover-point'");
    const result = `${content.substr(0, idx)}${monthLabelHtml}${content.substr(idx)}`;

    return result;
  },
};

export default {
  name: "EarningsChart",

  props: {
    name: {
      type: String,
      required: true,
    },
    chartDataPoints: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  mounted() {
    if (this.chartDataPoints.length > 0) {
      this.chart = Morris.Line({
        element: this.name,
        data: this.chartDataPoints,
        ...MORRIS_OPTIONS,
      });
    }
  },

  methods: {
    redraw() {
      this.chart.redraw();
    },
  },
};
</script>
