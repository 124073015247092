import MfeInvestmentFundPromissoryNote from "components/investment_funds/mfe_promissory_note";

$(() => {
  new Vue({
    el: document.querySelector(".v-investment-fund-promissory-note-controller"),
    components: {
      MfeInvestmentFundPromissoryNote,
    },
  });
});
