import submitWithRecaptcha from "components/submit_with_recaptcha";

class AccountFormController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        submitWithRecaptcha,
      },
      data: {
        selectedAccountType: undefined,
        selectedGrantorType: undefined,
      },
      computed: {
        isAccountTypeSelected() { return !!this.selectedAccountType; },
        isTrustSelected() { return this.selectedAccountType === "trust"; },
        isCompanySelected() { return this.selectedAccountType === "company"; },
        isIndividualSelected() { return this.selectedAccountType === "individual"; },
        isIraSelected() { return this.selectedAccountType === "ira"; },
        isMultipleGrantorSelected() { return this.selectedGrantorType === "multiple_grantors"; },
        formattedSelectedAccountType() {
          return this.selectedAccountType === "ira" ? "IRA" : this.selectedAccountType;
        },
      },
      mounted() {
        Vue.nextTick(() => {
          var element = this.$el;

          $(".toggle-password", element).click(function(e) {
            e.preventDefault();

            $(".password-field", element).attr("type", function(index, attr) {
              return attr === "password" ? "text" : "password";
            });

            this.textContent = (this.textContent === "show") ? "hide" : "show";
          });
        });
      },
      methods: {
        resetAccountType() {
          this.selectedAccountType = undefined;
        },
      },
    });
  }
}

window.PS.Controllers.AccountFormController = AccountFormController;
PS.Components.register(AccountFormController, { applyOnlyTo: ".v-account" });
