<template>
  <div class="control-wrapper">
    <label class="control-label">Select an offering</label>
    <select v-model="selectedOfferingGlobalId"
            class="custom-select"
            disabled="true">
      <option v-for="offering in modalStoreData.offerings"
              :key="offering.globalId"
              :value="offering.globalId">
        {{ offering.name }}
      </option>
    </select>
  </div>
</template>

<script>
import InvestmentOfferingsService from "services/investment_offerings_service";
import ModalStore from "../store";

export default {
  data() {
    return {
      modalStoreData: ModalStore.data,
      selectedOfferingGlobalId: InvestmentOfferingsService.pocketClassicOffering().globalId,
    };
  },
};
</script>
