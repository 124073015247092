<template>
  <plaid-link v-bind="{onEvent, onExit, onSuccess}">
    <div slot-scope="{ openPlaid }">
      <slot :isLinking="isLinking"
            :open-plaid="openPlaid" />
    </div>
  </plaid-link>
</template>

<script>
import Segment, { SEGMENT_EVENTS } from "services/segment";
import PlaidLink from "components/plaid_link";

const INSTITUTION_NOT_FOUND = "institution_not_found";
const NEW_BANK_ACCOUNT_PATH = "/bank_accounts/new";
const BANK_ACCOUNTS_PLAID_PATH = "/plaid_accounts";

export default {
  components: {
    "plaid-link": PlaidLink,
  },
  props: {
    redirectLinkOnSuccess: { type: String, required: true },
    redirectLinkOnManualLinkAccount: { type: String, required: true },
  },
  data() {
    return {
      isLinking: false,
    };
  },
  methods: {
    onSuccess(public_token, metadata) {
      this.isLinking = true;
      PS.Services.Ajax.post(BANK_ACCOUNTS_PLAID_PATH, {
        public_token: public_token,
        plaid_account_id: metadata.account_id,
      }).then(() => {
        PS.Services.LocationService.assign(this.redirectLinkOnSuccess);
      }).catch(() => {
        this.isLinking = false;
      });
    },

    onEvent(plaidEventName, metadata) {
      // Full list of Plaid Events here: https://plaid.com/docs/link/web/#onevent
      const appEventName = `Plaid_${plaidEventName}`;

      if (SEGMENT_EVENTS.hasOwnProperty(appEventName)) {
        Segment.track(appEventName, metadata);
      }
    },

    onExit(_error, metadata) {
      if (metadata.status === INSTITUTION_NOT_FOUND) {
        PS.Services.LocationService.assign(this.redirectLinkOnManualLinkAccount || NEW_BANK_ACCOUNT_PATH);
      }
    },
  },
};
</script>
