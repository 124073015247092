import Chart from "chart.js";
import debounce from "lodash/debounce";

const ReturnCalculator = PS.Models.ReturnCalculator;
const DiscountedReturnCalculator = PS.Models.DiscountedReturnCalculator;
const ReturnCalculatorDatasource = PS.Models.ReturnCalculatorDatasource;

const STANDARD_INVESTMENT_DISCOUNT_TYPE = "standard_investment_discount";

Vue.component("ReturnCalculator", {
  data: function() {
    return {
      totalReturn: 0,
      investmentType: PSData.loan.investmentType,
      loanAmount: PSData.loan.loanAmount,
      investmentAmount: PSData.loan.investmentAmount,
      notePurchaseRate: PSData.loan.notePurchaseRate,
      remainingTerm: PSData.loan.remainingTerm,
      psTerm: PSData.loan.psTerm,
      investmentReturn: PSData.loan.investmentReturn,
      investableAmounts: PSData.loan.investableAmounts,
      selectedAmount: PSData.loan.selectedAmount,
      onResizeHandler: debounce(() => {
        this.recalculate(this.selectedAmount);
      }, 300),
    };
  },
  watch: {
    selectedAmount: {
      handler: function(amount) {
        this.recalculate(amount);
      },
      immediate: true,
    },
  },
  created: function() {
    $(window).on("resize", this.onResizeHandler);
  },
  beforeDestroy: function() {
    $(window).off("resize", this.onResizeHandler);
  },
  methods: {
    recalculate: function(investmentAmount) {
      const calc = this.buildReturnCalculator(investmentAmount);

      this.totalReturn = calc.calculateTotalReturn();

      Vue.nextTick(() => {
        let chartDataSource = new ReturnCalculatorDatasource(calc);
        let $chart = $(this.$el).find(".return-calculator-chart");
        let $canvas = $("<canvas />");

        // Remove current chart and add a new canvas.
        // Prevents bug where hovering loads original data before loading current data
        $chart.find("canvas").remove();
        $chart.append($canvas);

        let ctx = $canvas.get(0).getContext("2d");

        window.data = chartDataSource;
        window.chart = new Chart(ctx, {
          type: "line",
          data: chartDataSource.data(),
          options: chartDataSource.options(),
        });
      });
    },

    buildReturnCalculator(investmentAmount) {
      if (this.investmentType === STANDARD_INVESTMENT_DISCOUNT_TYPE) {
        return new DiscountedReturnCalculator({
          investmentAmount: investmentAmount,
          notePurchaseRate: this.notePurchaseRate,
          totalLoanAmount: this.loanAmount,
          totalInvestmentAmount: this.investmentAmount,
          term: this.remainingTerm,
        });
      } else {
        return new ReturnCalculator({
          investmentAmount: investmentAmount,
          annualizedRate: this.investmentReturn,
          term: this.remainingTerm,
        });
      }
    },
  },
  template: "#v-return-calculator-template",
});
