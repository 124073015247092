var DEFAULT_COLLAPSE_AFTER_HEIGHT = 230;

class ReadMorePanel {
  togglePanelOnClick($element) {
    $element.off("click", ".js-read-more");
    $element.on("click", ".js-read-more", (e) => {
      e.preventDefault();
      $element.toggleClass("open-panel");
    });
  }

  onDomReady($element) {
    this.togglePanelOnClick($element);
  }
}
window.PS.Components.ReadMorePanel = ReadMorePanel;

class ReadMoreText extends ReadMorePanel {
  onDomReady($element) {
    var collapseAfterHeight = $element.data("ps-collapse-after-height") || DEFAULT_COLLAPSE_AFTER_HEIGHT;

    if ($element.height() > collapseAfterHeight) {
      $element.addClass("read-more-present");
    }

    this.togglePanelOnClick($element);
  }
}
window.PS.Components.ReadMoreText = ReadMoreText;


PS.Components.register(ReadMoreText, { applyOnlyTo: ".js-read-more-text" });
PS.Components.register(ReadMorePanel, { applyOnlyTo: ".js-read-more-panel" });
