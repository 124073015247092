<template>
  <div>
    <a class="status-label-link" :class="status" v-popover="popoverProperties" role="button" tabindex=0 @click.prevent="">
      <template v-if="statusText">{{statusText}}</template>
      <span class="empty-status" v-if="!statusText">-</span>
    </a>
    <div class="payment-status-tooltip popover-content d-none">
      <h3 class="payment-status-tooltip-title" :class="status">{{statusText}}<span>{{statusSubtitle}}</span></h3>
      <div v-html="statusDescription"></div>
      <p class="payment-status-footnote">{{statusFootnote}}</p>
      <a v-if="showFaq" :href="statusFaqLink" target="_blank">Read more in our FAQ ></a>
      <a v-if="showLastNotificationAge" :href="loanPositionPath" target="_blank">Last notice {{loanServicingState.lastNotificationAge}}></a>
    </div>
  </div>
</template>

<script>
import Popover from "directives/popover";

export default {
  directives: {
    popover: {
      inserted: Popover.inserted,
      unbind: Popover.unbind,
    }
  },
  props: {
    loanServicingState: {
      type: Object,
      required: true,
    },
    showFaq: {
      type: Boolean,
      required: false,
      default: false,
    },
    loanPositionPath: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth <= 768;
    },
    status() {
      return this.loanServicingState.paymentStatus;
    },
    statusText() {
      return this.loanServicingState.paymentStatusText;
    },
    statusSubtitle() {
      return this.loanServicingState.paymentStatusSubtitle;
    },
    statusDescription() {
      return this.loanServicingState.paymentStatusDescription;
    },
    statusFootnote() {
      return this.loanServicingState.paymentStatusFootnote;
    },
    statusFaqLink() {
      return this.loanServicingState.paymentStatusFaqLink;
    },
    showLastNotificationAge() {
      if (this.showFaq) {
        return false;
      }

      return this.loanPositionPath && this.loanServicingState.lastNotificationAge && this.statusWithNotificationLink;
    },
    statusWithNotificationLink() {
      return !(this.loanServicingState.paymentStatus == "paid_off" || this.loanServicingState.paymentStatus == "short_pay");
    },
    popoverProperties() {
      return {
        placement: this.isMobileDevice ? "bottom" : "right",
        containerClass: "payment-status-tooltip-container",
      };
    }
  }
};
</script>
