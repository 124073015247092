<template>
  <div class="form-group">
    <label class="control-label">
      Phone Number
    </label>

    <p class="two-factor-info-item">
      {{ formattedPhoneNumber }}
    </p>
  </div>
</template>

<script>
import form from "../form.js";

export default {
  name: "SmsShow",

  data() {
    return {
      form: form,
    };
  },

  computed: {
    formattedPhoneNumber() {
      let areaCode = this.form.phoneNumber.slice(-10, -7);
      let firstPart = this.form.phoneNumber.slice(-7, -4);
      let lastPart = this.form.phoneNumber.slice(-4);

      return `+1 (${areaCode}) ${firstPart}-${lastPart}`;
    },
  },
};
</script>
