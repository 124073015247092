import Segment from "services/segment";

$(function() {
  if (window.__ps_test_mode) { return; }

  Segment.load();

  $("[data-track]").on("click", (e) => {
    let track = $(e.target).data().track;
    
    Segment.track(
      track.event,
      Object.assign({ source: window.location.href }, track.properties || {})
    );
  });
});
