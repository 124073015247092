<template>
  <banner-v3>
    <template #title>
      Turn on Automated Investing
    </template>
    <template #content>
      Automatically invest in loans to save time and diversify your account.
      <promo-link url="/auto_investment"
                  label="Automated Investing">
        Learn more
      </promo-link>
    </template>
  </banner-v3>
</template>
<script>
import PromoLink from "components/promo/link";
import BannerV3 from "../../banner_v3";

export default {
  name: "PromoBannerEnableAi",
  components: { BannerV3, PromoLink },
};
</script>

