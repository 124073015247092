import Ajax from "services/ajax";
import Settings from "models/auto_deposits/settings";
import Frequency from "models/auto_deposits/frequency";

export default class RecurringStore {
  static data = {
    inEditMode: false,
    settings: this.getSettings(),
    frequencies: this.getFrequencies(),
    accountNumber: this.getAccountNumber(),
  }

  static defaultData = {
    inEditMode: false,
    settings: new Settings({}),
    frequencies: this.getFrequencies(),
    accountNumber: this.getAccountNumber(),
  }

  static setData(data) {
    Object.assign(this.data, data);
  }

  static nullifyData() {
    Object.assign(this.data, this.defaultData);
  }

  static getSettings() {
    return new Settings(PSData.savings?.autoDeposits?.settings);
  }

  static getFrequencies() {
    return Frequency.localeMapping(PSData.savings?.autoDeposits?.frequencies);
  }

  static getAccountNumber() {
    return PSData.savings?.autoDeposits?.accountNumber;
  }

  static makeUpdateRecurringSettingsRequest(params, onSuccess) {
    let res = Ajax.patch("/pocket/auto_deposits/settings", params);

    res.then(onSuccess);
  }

  static updateRecurringSettingsFromServer(settingsFromServer) {
    this.data.settings = new Settings({
      frequency: settingsFromServer.frequency,
      amount: settingsFromServer.amount,
      dayOfMonth: settingsFromServer.day_of_month,
      dayOfWeek: settingsFromServer.day_of_week,
      startDate: settingsFromServer.start_date,
      enabled: settingsFromServer.enabled,
    });
  }

  static leaveEditMode() {
    this.data.inEditMode = false;
    this.data.settings.resetToOriginal();
  }
}
