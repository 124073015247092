<template>
  <div class="buyout-offer">
    <offer-modal v-if="showBuyoutOffer" />
    <div v-if="showBuyoutOffer"
         class="row buyout-offer-summary">
      <div class="col-md-6">
        <h5>
          Buyout Offer
          <span class="offer-tooltip">
            <a v-popover="popoverProperties"
               href="#">
              <i class="fa fa-question-circle" />
            </a>
            <span class="d-none">
              Buyout Offers are an opportunity for investors in select loan investments to sell out of their positions.
            </span>
          </span>
        </h5>
        <p>This offer is limited and may be discontinued at any time.</p>
      </div>
      <ul class="col-md-6">
        <li class="row">
          <p class="col label">
            Your Investment:
          </p>
          <p class="col investment-amount">
            {{ buyoutOffer.investmentAmount | formatMoney }}
          </p>
        </li>
        <li class="row">
          <p class="col label">
            Offer Amount:
          </p>
          <p class="col offer-amount">
            {{ buyoutOffer.offerAmount | formatMoney }}
          </p>
        </li>
        <li class="row review-offer-button-wrapper">
          <button class="col btn-primary-cta btn-default review-offer-button"
                  @click="onReview">
            Review offer
          </button>
        </li>
      </ul>
    </div>
    <div v-if="showOfferUnavailableBanner"
         class="row offer-status-banner offer-unavailable">
      <p>The buyout offer is no longer available.</p>
    </div>
    <div v-if="offerAccepted"
         class="row offer-status-banner offer-accepted">
      <p>Buyout confirmed. You can expect the funds to settle in your account within 3 business days.</p>
    </div>
  </div>
</template>

<script>
import NumberFormatter from "formatters/number_formatter";
import Popover from "directives/popover";
import Segment from "services/segment";

import OfferModal, { OPEN_BUYOUT_OFFER_MODAL_EVENT } from "components/secondary_market/offer_modal";

export const { formatMoney } = NumberFormatter;

const TRACK_BUYOUT_OFFER = "Buyout Offer";

export default {
  name: "BuyoutOffer",
  directives: {
    popover: {
      inserted: Popover.inserted,
      unbind: Popover.unbind,
    },
  },
  filters: {
    formatMoney,
  },
  components: {
    OfferModal,
  },
  props: {
    buyoutOffer: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      hasNavigatedFromEmail: false,
      showOfferUnavailableBanner: false,
    };
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth <= 768;
    },
    popoverProperties() {
      return { placement: this.isMobileDevice ? "bottom" : "right" };
    },
    offerAccepted() {
      return this.buyoutOffer && this.buyoutOffer.acceptedAt;
    },
    showBuyoutOffer() {
      return this.buyoutOffer && !this.offerAccepted;
    },
  },
  mounted() {
    this.setNavigatedFromEmail();
    this.setShowOfferUnavailableBanner();

    if (this.showBuyoutOffer) {
      this.trackShowBuyoutOffer();
    }

    if (this.hasNavigatedFromEmail && this.showBuyoutOffer) {
      this.openOfferModal();
      this.trackOpenOfferModalFromEmail();
    }

    if (this.showOfferUnavailableBanner) {
      this.trackShowOfferUnavailableBanner();
    }
  },
  methods: {
    openOfferModal() {
      PS.globalBus.$emit(OPEN_BUYOUT_OFFER_MODAL_EVENT, { buyoutOffer: this.buyoutOffer, showReject: false });
    },
    onReview() {
      this.openOfferModal();
      this.trackOnReviewClick();
    },
    setNavigatedFromEmail() {
      const searchParams = new URL(PS.Services.LocationService).searchParams;

      this.hasNavigatedFromEmail = searchParams.get("src") === "new_buyout_offer_notification";
    },
    setShowOfferUnavailableBanner() {
      this.showOfferUnavailableBanner = this.hasNavigatedFromEmail && !this.showBuyoutOffer && !this.offerAccepted;
    },
    trackShowBuyoutOffer() {
      Segment.track("pageView", { category: TRACK_BUYOUT_OFFER, label: "Offer Displayed" });
    },
    trackOpenOfferModalFromEmail() {
      Segment.track("pageView", { category: TRACK_BUYOUT_OFFER, label: "Offer Displayed From Email" });
    },
    trackShowOfferUnavailableBanner() {
      Segment.track("pageView", { category: TRACK_BUYOUT_OFFER, label: "Offer Unavailable" });
    },
    trackOnReviewClick() {
      Segment.track("click", { category: TRACK_BUYOUT_OFFER, label: "Review Offer Clicked" });
    },
  },
};
</script>
