<template>
  <div class="auto-deposits-setup">
    <edit-step v-show="showEditStep"
               :settings="settings"
               :frequencies="frequencies"
               :account="account">
      <button slot="create-deposit-button"
              class="btn-primary-cta btn-block"
              :disabled="!enableSubmit"
              @click="goToReviewStep">
        Review Deposit
      </button>

      <button slot="update-deposit-button"
              class="btn-primary-cta btn-block btn-update"
              :disabled="!enableSubmit"
              @click="goToReviewStep">
        Update Settings
      </button>

      <button slot="disable-deposit-button"
              class="btn-info-cta btn-block btn-turn-off"
              @click="turnOffDeposit"
              @disabled="blockUI">
        <span v-if="!blockUI">Turn Off</span>
        <span v-if="blockUI">Turning Off...</span>
      </button>

      <a slot="cancel-setup-button"
         :href="backPath"
         class="btn-link">
        Cancel
      </a>
    </edit-step>

    <confirm-step v-show="showConfirmStep"
                  :settings="settings"
                  :frequencies="frequencies"
                  :account="account">
      <button slot="submit-deposit-button"
              class="btn-primary-cta btn-block"
              @click="updateDeposit"
              @disabled="blockUI">
        <span v-if="!blockUI">Schedule Deposit</span>
        <span v-if="blockUI">Scheduling...</span>
      </button>

      <button slot="edit-deposit-button"
              class="btn-link"
              @click="goToEditStep">
        Edit Deposit
      </button>
    </confirm-step>

    <success-step v-show="showSuccessStep"
                  :settings="settings" />

    <security-note v-show="showEditStep || showConfirmStep" />
  </div>
</template>
<script>
  import ConfirmStep from "components/auto_deposits/steps/confirm";
  import EditStep from "components/auto_deposits/steps/edit";
  import SecurityNote from "components/auto_deposits/security_note";
  import SuccessStep from "components/auto_deposits/steps/success";

  import Ajax from "services/ajax";
  import Frequency from "models/auto_deposits/frequency";
  import Segment from "services/segment";
  import Settings from "models/auto_deposits/settings";

  const FUND_ACCOUNT_PATH = "/transactions/ach_deposits/new";
  const UPDATE_SETTINGS_PATH = "/transactions/auto_deposits/settings";
  const STEPS = {
    edit: "edit",
    confirm: "confirm",
    success: "success",
  };


  export default {
    name: "AutoDepositsSetup",
    components: {
      "security-note": SecurityNote,
      "edit-step": EditStep,
      "confirm-step": ConfirmStep,
      "success-step": SuccessStep,
    },
    data() {
      return {
        step: STEPS.edit,
        settings: new Settings(PSData.autoDeposits.settings),
        account: `***${PSData.autoDeposits.accountNumber}`,
        frequencies: Frequency.localeMapping(PSData.autoDeposits.frequencies),
        blockUI: false,
        backPath: this.findBackPath(),
      };
    },
    computed: {
      showEditStep() {
        return this.step === STEPS.edit;
      },

      showConfirmStep() {
        return this.step === STEPS.confirm;
      },

      showSuccessStep() {
        return this.step === STEPS.success;
      },

      enableSubmit() {
        return this.settings.valid && !this.blockUI;
      },
    },
    methods: {
      goToReviewStep() {
        this.step = STEPS.confirm;
        PS.Services.WindowService.scrollTo({ top: 0 });
      },

      goToEditStep() {
        this.step = STEPS.edit;
        PS.Services.WindowService.scrollTo({ top: 0 });
      },

      goToSuccessStep() {
        this.step = STEPS.success;
        PS.Services.WindowService.scrollTo({ top: 0 });
      },

      updateDeposit() {
        this.performRequest({ resource: this.settings.toServer() }, (settingsFromServer) => {
          if (this.settings.wasDisabled()) {
            Segment.track("enableRecurringDeposit", settingsFromServer);
          } else {
            Segment.track("updateRecurringDeposit", settingsFromServer);
          }

          this.goToSuccessStep();
        });
      },

      turnOffDeposit() {
        this.performRequest({ resource: { enabled: false } }, (settingsFromServer) => {
          Segment.track("disableRecurringDeposit", settingsFromServer);
          PS.Services.WindowService.location = this.backPath;
        });
      },

      performRequest(params, callback) {
        this.settings.errors = {};
        this.blockUI = true;

        return Ajax.patch(UPDATE_SETTINGS_PATH, params)
          .then(callback)
          .catch(({ data }) => {
            this.goToEditStep();

            Object.keys(data.errors).forEach((field) => {
              this.settings.errors[_.camelCase(field)] = data.errors[field].join(",");
            });
          })
          .finally(() => {
            this.blockUI = false;
          });
      },

      findBackPath() {
        const location = PS.Services.LocationService;
        const document = PS.Services.DocumentService;

        const referrer = document.referrer;
        const pattern = `^https?:\/\/${location.host}`;
        const isCurrentHost = referrer.match(pattern);

        if (referrer && isCurrentHost) {
          return referrer;
        } else {
          return FUND_ACCOUNT_PATH;
        }
      },
    },
  };
</script>
