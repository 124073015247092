import { DEFAULT_DAY_OF_MONTH, DEFAULT_DAY_OF_WEEK } from "services/auto_deposits/calendar_service";
import { FREQUENCY_MAPPING } from "models/auto_deposits/frequency";
import NumberFormatter from "formatters/number_formatter";
import _ from "lodash";

const MIN_DEPOSIT = 1;

export default class Settings {
  constructor(settings) {
    this.errors = {};

    this._settings = {};
    Object.assign(this._settings, Settings.default, settings);

    this._originalSettings = {};
    Object.assign(this._originalSettings, Settings.default, settings);
  }

  static get default() {
    return {
      enabled: false,
      frequency: FREQUENCY_MAPPING.monthly,
      dayOfMonth: DEFAULT_DAY_OF_MONTH,
      dayOfWeek: DEFAULT_DAY_OF_WEEK,
      startDate: undefined,
      amount: undefined,
    };
  }

  get enabled() {
    return this._settings.enabled;
  }

  get frequency() {
    return this._settings.frequency;
  }

  set frequency(value) {
    this.errors.frequency = undefined;
    this._settings.frequency = value;
  }

  get dayOfMonth() {
    return this._settings.dayOfMonth;
  }

  set dayOfMonth(value) {
    this.errors.dayOfMonth = undefined;
    this._settings.dayOfMonth = value;
  }

  get dayOfWeek() {
    return this._settings.dayOfWeek;
  }

  set dayOfWeek(value) {
    this.errors.dayOfWeek = undefined;
    this._settings.dayOfWeek = value;
  }

  get startDate() {
    return this._settings.startDate;
  }

  set startDate(value) {
    this.errors.startDate = undefined;
    this._settings.startDate = value;
  }

  get amount() {
    return this._settings.amount;
  }

  // eslint-disable-next-line complexity
  set amount(value) {
    const MAX_DEPOSIT = this._settings.depositMaxLimit;
    const formattedAmount = NumberFormatter.unmaskNumber(value);
    const amountMissing = !formattedAmount;
    const amountLessThanMin = formattedAmount && formattedAmount < MIN_DEPOSIT;
    const amountGreaterThanMax = formattedAmount && formattedAmount > MAX_DEPOSIT;

    if (this._settings.amount === formattedAmount) { return; }

    switch (true) {
    case amountMissing:
      this.errors.amount = "Please enter an amount.";
      break;

    case amountLessThanMin:
      this.errors.amount =
          `Please enter an amount greater than or equal to $${
            NumberFormatter.formatNumber(MIN_DEPOSIT, 0)
          }.`;
      break;

    case amountGreaterThanMax:
      this.errors.amount =
          `Please enter an amount up to $${NumberFormatter.formatNumber(MAX_DEPOSIT, 0)}.`;
      break;

    default:
      this.errors.amount = undefined;
    }

    this._settings.amount = value ? formattedAmount : value;
  }

  resetAmount() {
    this._settings.amount = null;
  }

  get valid() {
    if (!this._settings.amount) { return false; }

    return !Object.values(this.errors).some(message => !!message);
  }

  get disabled() {
    return !this.enabled;
  }

  get showMonthly() {
    return this.frequency === FREQUENCY_MAPPING.monthly;
  }

  get showWeekly() {
    return this.frequency === FREQUENCY_MAPPING.weekly;
  }

  get showEveryOtherWeek() {
    return this.frequency === FREQUENCY_MAPPING.everyOtherWeek;
  }

  wasDisabled() {
    return !this._originalSettings.enabled;
  }

  toServer() {
    const settings = {};

    Object.keys(Settings.default).forEach((key) => {
      settings[_.snakeCase(key)] = this[key];
    });

    settings.enabled = true;
    settings.amount = NumberFormatter.unmaskNumber(settings.amount);

    return settings;
  }

  resetToOriginal() {
    Object.assign(this._settings, this._originalSettings);
  }
}
