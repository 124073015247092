<template>
  <div>
    <template v-if="investmentsFromBankEnabled">
      <Tabs />

      <RecurringDepositForm v-if="isRecurringTabActive" />
      <OneTimeDepositForm v-else />
    </template>
    <template v-else>
      <OfferingPicker v-if="hasSomethingToInvest" />
      <AvailableToInvest />
      <CashInvestmentForm />
    </template>
  </div>
</template>

<script>
import OfferingPicker from "./offering_picker.vue";
import Tabs from "./tabs.vue";
import RecurringDepositForm from "./recurring_deposits/form.vue";
import OneTimeDepositForm from "./one_time_deposits/form.vue";
import AvailableToInvest from "./available_to_invest.vue";
import CashInvestmentForm from "./one_time_deposits/cash/form.vue";

import ModalStore, { TABS } from "./store";

export default {
  components: {
    Tabs,
    OfferingPicker,
    AvailableToInvest,
    OneTimeDepositForm,
    RecurringDepositForm,
    CashInvestmentForm,
  },

  data: function() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    investmentsFromBankEnabled() {
      return this.modalStoreData.bankAccountLastFour &&
        this.modalStoreData.investmentsFromBankEnabled;
    },

    isRecurringTabActive() {
      return this.modalStoreData.activeTab === TABS.RECURRING;
    },

    hasSomethingToInvest() {
      return this.modalStoreData.availableToInvest > 0;
    },
  },
};
</script>
