import { JSONEditor } from "@json-editor/json-editor";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-settings-controller"),
    data: {
      editor: undefined,
    },
    mounted() {
      JSONEditor.defaults.theme = "bootstrap4";
      JSONEditor.defaults.iconlib = "fontawesome4";

      this.editor = new JSONEditor(
        document.querySelector(".js-json-editor"),
        {
          schema: PSData.settingsSchema,
          startval: PSData.settings,
          disable_edit_json: true,
          disable_properties: true,
          no_additional_properties: true,
          required_by_default: true,
          show_errors: "always",
        }
      );
    },
    methods: {
      validateAndSubmit() {
        const errors = this.editor.validate();

        if (errors.length === 0) {
          $.ajax({
            type: "PATCH",
            url:  "/manage/settings",
            data: {
              settings: JSON.stringify(this.editor.getValue()),
            },
          })
            .then(
              () => {
                // eslint-disable-next-line no-alert
                alert("Done!");
              },
              (data) => {
                // eslint-disable-next-line no-alert
                alert(`Errors:\n\n${data.responseJSON.errors.join("\n\n")}`);
              }
            );
        }
      },
    },
  });
});
