<template>
  <Summary :fixed-offering-name="offeringName"
           :investment-source-name="investmentSourceName"
           :optional-items="settingItems"
           :frequency="frequencies[settings.frequency].name"
           :amount="settings.amount" />
</template>

<script>
import Summary from "../one_time_deposits/summary.vue";

import CalendarService from "services/auto_deposits/calendar_service";
import InvestmentOfferingsService from "services/investment_offerings_service";
import { DAYS_OF_WEEK } from "services/auto_deposits/calendar_service";
import { formatMoney } from "formatters/number_formatter";
import { ordinalize } from "filters";

import RecurringStore from "./store";
import ModalStore from "../store";

export default {
  components: {
    Summary,
  },

  data() {
    return {
      recurringStoreData: RecurringStore.data,
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    offeringName() {
      return InvestmentOfferingsService.pocketClassicOffering().name;
    },

    investmentSourceName() {
      return `Bank Account ***${this.modalStoreData.bankAccountLastFour}`;
    },

    settings() {
      return this.recurringStoreData.settings;
    },

    frequencies() {
      return this.recurringStoreData.frequencies;
    },

    settingItems() {
      const result = [];

      if (this.settings.showWeekly || this.settings.showEveryOtherWeek) {
        let startDate = CalendarService.convertToShortWithYear(this.settings.startDate);

        result.push({ label: "Day of Week", value: DAYS_OF_WEEK[this.settings.dayOfWeek - 1] });
        result.push({ label: "Start Date", value: startDate });
      }

      if (this.settings.showMonthly) {
        result.push({ label: "Day of Month", value: ordinalize(this.settings.dayOfMonth) });
      }

      return result;
    },
  },
};
</script>
