<template>
  <div class="investment-amount validate">
    <label class="control-label"
           for="invest_amount">{{ label }}</label>

    <div class="form-group currency required"
         :class="{ 'has-error': errors, 'disabled': disabled }">
      <div class="input-group-wrapper">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            id="invest_amount"
            ref="input"
            v-mask:currency
            :disabled="disabled"
            class="form-control currency required input-lg"
            :class="{ 'error-control': errors }"
            type="text"
            autocomplete="off"
            :value="value"
            @input="updateAmount">
        </div>
        <div class="investment-amount-notice">
          <label v-if="errors"
                 class="error control-label"
                 v-html="errors" />
          <div v-else
               class="investment-amount-notice-min">
            Min. $1,000
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mask from "directives/inputs";

export default {
  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
  },

  props: {
    value: { type: [Number, String], required: true },
    errors: { type: String, required: true },
    label: { type: String, required: true },
    disabled: Boolean,
  },

  mounted() {
    this.$refs.input.focus();
  },

  methods: {
    updateAmount(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
