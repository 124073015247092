<template>
  <div class="pocket-investment-confirmation">
    <Summary :investment-source-name="'PeerStreet Account'"
             :amount="cashStoreData.amount" />

    <AcknowledgementCheckbox v-model="investmentAcknowledged"
                             @acknowledgement-required="onAcknowledgementRequired" />

    <button class="btn-primary-cta btn-block"
            :disabled="investButtonDisabled"
            @click="investFunds">
      Invest Funds
    </button>
    <button class="btn btn-block"
            @click="leaveEditMode">
      Edit
    </button>
  </div>
</template>

<script>
import CashStore from "./store";
import OneTimeStore from "../store";
import ModalStore from "../../store";

import Summary from "../summary.vue";
import AcknowledgementCheckbox from "../acknowledgement_checkbox.vue";

export default {
  components: {
    Summary,
    AcknowledgementCheckbox,
  },

  data() {
    return {
      isLoading: false,
      cashStoreData: CashStore.data,
      modalStoreData: ModalStore.data,
      acknowledgementRequired: false,
      investmentAcknowledged: false,
    };
  },

  computed: {
    investButtonDisabled() {
      return this.isLoading || (this.acknowledgementRequired && !this.investmentAcknowledged);
    },
  },

  methods: {
    onAcknowledgementRequired(isRequired) {
      this.acknowledgementRequired = isRequired;
    },

    leaveEditMode() {
      ModalStore.showInvestForm();
    },

    investFunds() {
      this.isLoading = true;

      OneTimeStore.fundPocket({
        amount: this.cashStoreData.amount,
        investment_offering_global_id: this.modalStoreData.selectedOfferingGlobalId,
        source: "cash",
      }, () => {
        this.isLoading = false;

        ModalStore.showOneTimeCashSuccess();

        return true;
      }, ({ data }) => {
        let errorMessage = "";

        if (data && data.errors) {
          errorMessage = data.errors;
        } else {
          errorMessage = "There was an unknown error";
        }

        this.cashStoreData.serverErrors = errorMessage;
        this.isLoading = false;

        ModalStore.showInvestForm();

        return false;
      });
    },
  },
};
</script>
