import clone from "lodash/clone";
import snakeCase from "lodash/snakeCase";

export default class Account {
  constructor(account) {
    this.errors = {};

    this._account = {};
    Object.assign(this._account, Account.default, account);

    this._originalAccount = clone(account);
  }

  static get default() {
    return {
      email: undefined,
      phone: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
    };
  }

  get email() {
    return this._account.email;
  }

  set email(value) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    switch (true) {
      case !value:
        this.errors.email = "This field is required.";
        break;

      case !emailRegex.test(value):
        this.errors.email = "Please enter a valid email address.";
        break;

      default:
        this.errors.email = undefined;
    }

    this._account.email = value;
  }

  get phone() {
    return this._account.phone;
  }

  // eslint-disable-next-line complexity
  set phone(value) {
    let formattedPhone;

    if (value && value.toString) {
      formattedPhone = value.toString().replace(/[-()_ ]/g, "");
    }

    const phoneShorterThanMin = formattedPhone && formattedPhone.length < 10;

    switch (true) {
      case !formattedPhone:
        this.errors.phone = "This field is required.";
        break;

      case phoneShorterThanMin:
        this.errors.phone = "Invalid format.";
        break;

      default:
        this.errors.phone = undefined;
    }

    this._account.phone = value ? parseFloat(formattedPhone) : value;
  }

  get address1() {
    return this._account.address1;
  }

  set address1(value) {
    switch (true) {
      case !value:
        this.errors.address1 = "This field is required.";
        break;

      case value.length < 2:
        this.errors.address1 = "Please enter at least 2 characters.";
        break;

      default:
        this.errors.address1 = undefined;
    }

    this._account.address1 = value;
  }

  get address2() {
    return this._account.address2;
  }

  set address2(value) {
    this._account.address2 = value;
  }

  get city() {
    return this._account.city;
  }

  set city(value) {
    switch (true) {
      case !value:
        this.errors.city = "This field is required.";
        break;

      case value.length < 2:
        this.errors.city = "Please enter at least 2 characters.";
        break;

      default:
        this.errors.city = undefined;
    }

    this._account.city = value;
  }

  get state() {
    return this._account.state;
  }

  set state(value) {
    if (!value) {
      this.errors.state = "This field is required.";
    }
    else {
      this.errors.state = undefined;
    }

    this._account.state = value;
  }

  get zip() {
    return this._account.zip;
  }

  set zip(value) {
    switch (true) {
      case !value:
        this.errors.zip = "This field is required.";
        break;

      case value.length < 5:
        this.errors.zip = "Please enter at least 5 characters.";
        break;

      default:
        this.errors.zip = undefined;
    }

    this._account.zip = value;
  }

  get updateAccountInfoPath() {
    return this._account.updateAccountInfoPath;
  }

  get changePasswordPath() {
    return this._account.changePasswordPath;
  }

  get twoFactorAuthFeatureAllowed() {
    return this._account.twoFactorAuthFeatureAllowed;
  }

  get changeTwoFactorAuthPath() {
    return this._account.changeTwoFactorAuthPath;
  }

  get twoFactorAuthEnabled() {
    return this._account.twoFactorAuthEnabled;
  }

  get changeEinPath() {
    return this._account.changeEinPath;
  }

  get changeSsnPath() {
    return this._account.changeSsnPath;
  }

  get usStatesAndTerritoriesHash() {
    return this._account.usStatesAndTerritoriesHash;
  }

  get psId() {
    return this._account.psId;
  }

  get tinType() {
    return this._account.tinType;
  }

  get tinInvalid() {
    return this._account.tinInvalid;
  }

  get accountType() {
    return this._account.accountType;
  }

  get valid() {
    return !Object.values(this.errors).some(message => !!message);
  }

  toServer() {
    const account = {};

    Object.keys(Account.default).forEach((key) => {
      account[snakeCase(key)] = this[key];
    });

    return account;
  }

  revert() {
    this.errors = {};
    this._account = clone(this._originalAccount);
  }

  commit() {
    this.errors = {};
    this._originalAccount = clone(this._account);
  }
}
