class FocusModalsOnFirstInput {
  onDomReady($element) {
    $element.on("shown.bs.modal loaded.bs.modal", function() {
      $element.children().find("textarea, input[type!=hidden]").first().focus();
    });
  }
}

PS.Components.register(FocusModalsOnFirstInput, { applyOnlyTo: ".modal" });
window.PS.Components.FocusModalsOnFirstInput = FocusModalsOnFirstInput;
