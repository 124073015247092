<template>
  <div class="auto-deposits-status">
    <a v-if="displayLink"
       class="auto-deposits-status-link"
       :href="settingsUrl">{{ label }}</a>
    <span v-if="!displayLink">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "SavingsAutoDepositsStatus",
  data() {
    return {
      savingsAutoDeposits: PSData.savingsAutoDeposits,
      settingsUrl: PSData.savingsAutoDeposits.manageUrl,
    };
  },
  computed: {
    displayLink() {
      return !(this.enabled === null);
    },
    label() {
      switch (this.enabled) {
        case true: return "Enabled";
        case false: return "Disabled";
        default: return "Not Configured";
      }
    },
    enabled() {      
      if(this.savingsAutoDeposits.hasOwnProperty("settings")) {
        return this.savingsAutoDeposits.settings.enabled === true;
      };
    },
  },
};
</script>
