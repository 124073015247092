<template>
  <iframe id="investment-catalog-iframe"
          v-resize
          :src="iframeSrc"
          :title="title" />
</template>

<script>

import { iframeResizer } from "iframe-resizer";

export default {
  name: "InvestmentCatalogItemFrame",
  directives: {
    resize: {
      bind: function(el, { value = {} }) {
        el.addEventListener("load", () =>{
          try {
            const iframe = $("#investment-catalog-iframe");
            const head = iframe.contents().find("head");
            let script = iframe.get(0).contentWindow.document.createElement("script");

            script.type = "text/javascript";
            script.src = "iframe-resizer.js";
            head.append(script);
          } catch (err) {
            console.log("Failed to add iframe-resizer script to iframe", err);
          }

          iframeResizer(value, el);
          el.style.display = "block";
        });
      },
      unbind: function(el) {
        el.iFrameResizer.removeListeners();
      },
    },
  },
  props: {
    title: { type: String, required: true },
    iframeSrc: { type: String, required: true },
  },
};
</script>
