<template>
  <span class="badge"
        :class="badgeClass">{{ badgeTitle }}</span>
</template>
<script>
export default {
  name: "TwoFactorBadge",

  props: {
    enabled: { type: Boolean },
  },

  computed: {
    badgeClass() {
      return this.enabled ? "badge-success" : "badge-neutral";
    },

    badgeTitle() {
      return this.enabled ? "On" : "Off";
    },
  },
};
</script>
