import TwoFactorAuthSettings from "components/two_factor_auth/settings";

$(() => {
  new Vue({
    el: document.querySelector(".v-two-factor-auth"),
    components: {
      TwoFactorAuthSettings,
    },
  });
});
