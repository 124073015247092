<template>
  <div class="loan-is-fully-funded">
    <h4 class="loan-invest-content-title">
      Sorry, this loan has been fully funded
    </h4>

    <a href="/loans"
       class="btn btn-primary-cta btn-block"
       role="button">
      See more investments
    </a>
  </div>
</template>

<script>
export default {
};
</script>
