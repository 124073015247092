<template>
  <div v-if="showSection"
       class="account-positions-section">
    <div class="positions-header d-sm-flex">
      <h4>{{ sectionTitle }}</h4>
      <a v-if="showAllInvestmentsLink"
         :href="viewAllInvestmentsLink">{{ viewAllLinkText }}</a>
    </div>

    <account-positions-dashboard-list
      :positions="positionsWithBumpedRates"
      :paid-off="isPaidOff"
      :pending="isPending"
      :enable-sorting="enableSorting" />
  </div>
</template>

<script>
import AccountPositionsDashboardList from "components/account_positions/dashboard/list";
import RateCalculator from "models/account_positions/rate_calculator";
import StringFormatter from "formatters/string_formatter";
import TourService from "services/tour_service";
import some from "lodash/some";

export const { pluralize } = StringFormatter;

export default {
  name: "AccountPositionsDashboardSection",
  components: { AccountPositionsDashboardList },
  props: {
    enableSorting: Boolean,
    hideEmpty: Boolean,
    investmentsType: { type: String, default: null },
    positions: { type: Array, default: null },
    positionsLimit: { type: Number, default: null },
    viewAllInvestmentsLink: { type: String, default: null },
  },
  data() {
    return {
      accountPositionsCount: PSData.accountPositionsCount || {},
      tokens: PSData.tokens,
    };
  },
  computed: {
    sectionTitle() {
      const { totalPositionsCount: count, investmentsType: type } = this;

      return `${type} Investments (${count})`;
    },
    showAllInvestmentsLink() {
      return this.viewAllInvestmentsLink && this.totalPositionsCount > this.positionsLimit;
    },
    viewAllLinkText() {
      return `View All ${this.investmentsType} Investments`;
    },
    totalPositionsCount() {
      const { current, late, pending, paidOff, shortPay, deferred, earlyLiquidation } = this.accountPositionsCount;

      return {
        Active: current + late + deferred,
        Pending: pending,
        Past: paidOff + shortPay + earlyLiquidation,
      }[this.investmentsType];
    },
    positionsWithBumpedRates() {
      return new RateCalculator(this.tokens).applyBumpedRates(this.positions);
    },
    isPaidOff() {
      return this.investmentsType === "Past";
    },
    isPending() {
      return this.investmentsType === "Pending";
    },
    showSection() {
      return !this.hideEmpty || this.positions.length > 0;
    },
    hasDefaultedPositions() {
      return some(this.positions, position => position.paymentStatus === "default");
    },
  },
  mounted() {
    // start product tour after list is rendered
    setTimeout(() => {
      this.startFirstDefaultTour();
    }, 1000);
  },
  methods: {
    startFirstDefaultTour() {
      if (this.hasDefaultedPositions) {
        TourService.start("investorFirstDefault");
      }
    },
  },
};
</script>
