export default class InvestmentOfferingsService {
  static savingsOfferings() {
    return PSData.user?.properties?.savingsInvestment?.offerings;
  }

  static pocketClassicOffering() {
    return this.savingsOfferings()?.find(o => o.isPocketClassic);
  }

  static isPocketClassic(offeringGlobalId) {
    return this.pocketClassicOffering()?.globalId === offeringGlobalId;
  }
}
