<template>
  <div class="account-bar">
    <div class="row">
      <div class="container">
        <account-stats-slider v-bind="selectProps" />
      </div>
    </div>
  </div>
</template>

<script>
import AccountStatsSlider from "components/account_stats_slider";

export default {
  name: "AccountBar",
  components: { AccountStatsSlider },
  props: {
    cash: { type: String, required: true },
    investmentFundPrincipal: { type: String, required: true }, 
    loansPrincipal: { type: String, required: true },
    savingsPrincipal: { type: String, required: true },
    totalEarned: { type: String, required: true },
    value: { type: String, required: true },
  },
  data() {
    return {
      savingsRequireAgreement: PSData.savings.requireAgreement,
    };
  },
  computed: {
    selectProps() {
      return { ...this.$props, savingsRequireAgreement: this.savingsRequireAgreement };
    },
  },
};
</script>
