<template>
  <mfe-connect :service-url="serviceUrl"
               :application-name="applicationName"
               @ready="mountApp">
    <div ref="entryPoint" />
  </mfe-connect>
</template>

<script>
import MfeConnect from "./mfe_connect";

export default {
  name: "Savings",
  components: {
    MfeConnect,
  },

  props: {},

  data() {
    return {
      serviceUrl: PSData.savings.uiUrl,
      applicationName: "ui",
    };
  },

  methods: {
    mountApp() {
      SavingsApp.mount({
        element: this.$refs.entryPoint,
      });
    },
  },
};
</script>
