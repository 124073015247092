import Setup from "components/auto_deposits/setup";
import Summary from "components/auto_deposits/summary";

$(() => {
  new Vue({
    el: document.querySelector(".v-auto-deposits-controller"),
    components: {
      "auto-deposits-setup": Setup,
      "auto-deposits-summary": Summary,
    },
  });
});
