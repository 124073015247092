<template>
  <div class="account-information">
    <div class="card">
      <div class="card-heading">
        <h4>Security</h4>
      </div>
      <div class="card-body">
        <table class="table security-information-table">
          <tbody>
            <tr>
              <td class="control-label">
                <label class="control-label">Password</label>
              </td>
              <td>
                <a
                  id="change-password-btn"
                  :href="editPasswordPath"
                  class="btn btn-primary-cta btn-primary-small btn-edit">
                  Edit
                </a>
              </td>
            </tr>

            <tr v-if="displayTwoFactorAuth">
              <td class="control-label">
                <label class="control-label">Two-Factor Authentication</label>
                <TwoFactorBadge :enabled="twoFactorEnabled" />
              </td>
              <td>
                <a
                  id="edit-two-factor-btn"
                  :href="editTwoFactorAuthPath"
                  class="btn btn-primary-cta btn-primary-small btn-edit">
                  Edit
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Account from "models/account";
import TwoFactorBadge from "components/two_factor_auth/badge";

export default {
  name: "SecurityInformation",

  components: {
    TwoFactorBadge,
  },

  data() {
    return {
      account: new Account(PSData.account),
    };
  },

  computed: {
    editPasswordPath() {
      return this.account.changePasswordPath;
    },
    displayTwoFactorAuth() {
      return this.account.twoFactorAuthFeatureAllowed;
    },
    editTwoFactorAuthPath() {
      return this.account.changeTwoFactorAuthPath;
    },
    twoFactorEnabled() {
      return this.account.twoFactorAuthEnabled;
    },
  },
};
</script>
