export default class Ajax {
  static get(url, headers) {
    return this.ajax(url, "GET", {}, headers);
  }

  static patch(url, data, headers) {
    return this.ajax(url, "PATCH", data, headers);
  }

  static post(url, data, headers) {
    return this.ajax(url, "POST", data, headers);
  }

  static delete(url, data, headers) {
    return this.ajax(url, "DELETE", data, headers);
  }

  static ajax(url, method = "GET", data, headers = {}) {
    const headersContainer = new Headers(headers);
    const AUTH_TOKEN = (document.head.querySelector("[name=csrf-token]") || {}).content;

    headersContainer.set("X-CSRF-Token", AUTH_TOKEN);
    headersContainer.set("X-Requested-With", "XMLHttpRequest");

    return new Promise((resolve, reject) => {
      let body = Ajax.encode(method, data, headersContainer);

      if (body instanceof URLSearchParams) {
        // Fix for IE11 + fetch.js
        body = body.toString();

        const hasContentType = !!headersContainer.get("Content-Type");

        if (!hasContentType) {
          // Fix for IE Edge https://github.com/jerrybendy/url-search-params-polyfill/issues/18
          headersContainer.set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        }
      }

      const options = {
        credentials: "same-origin",
        method: method,
        body: body,
        headers: headersContainer,
      };

      fetch(url, options)
        .then((response) => {
          Ajax.decode(response).then((body) => {
            if (response.status >= 200 && response.status < 300) {
              resolve(body);
            } else {
              reject({ response: response, data: body });
            }
          });
        })
        .catch((error) => reject({ response: Response.error(), error: error }));
    });
  }

  static encode(method, data, headers) {
    if (["GET", "HEAD"].includes(method) || !data) {
      return;
    }

    if (Ajax.type(headers) === "json") {
      return JSON.stringify(data);
    } else {
      if (typeof data === "string") {
        return data;
      } else {
        return this.toParams(data);
      }
    }
  }

  static decode(response) {
    if (Ajax.type(response.headers) === "json") {
      return response.json();
    } else {
      return response.text();
    }
  }

  static type(headers) {
    const header = headers.get("Content-Type");
    const isString = typeof header === "string";

    if (isString && header.includes("application/json")) {
      return "json";
    } else {
      return "text";
    }
  }

  static toParams(obj) {
    const properties = this.convertObject(obj, []);
    const hasFile = properties.some(([key, value]) => value instanceof File);

    return properties.reduce(
      (acc, [key, value]) => {
        acc.append(key, value);

        return acc;
      },
      hasFile ? new FormData() : new URLSearchParams()
    );
  }

  // eslint-disable-next-line complexity
  static convertObject(obj, params, namespace) {
    let key;

    // eslint-disable-next-line guard-for-in
    for (let property in obj) {
      if (namespace) {
        key = namespace + "[" + property + "]";
      } else {
        key = property;
      }

      const value = obj[property];
      const isFile = value instanceof File;
      const isNull = value === null;
      const coercedValue = isNull ? "" : value;

      // if the property is an object, but not a File, use recursivity.
      if (value instanceof Date) {
        params.push([ key, value.toISOString() ]);
      } else if (typeof value === "object" && !isNull && !isFile) {
        this.convertObject(value, params, key);
      } else if (value !== undefined) {
        params.push([ key, coercedValue ]);
      }
    }

    return params;
  }
}

PS.Services.Ajax = Ajax;
