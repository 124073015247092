export default class RateCalculator {
  constructor(tokens) {
    this.tokens = tokens;
  }

  applyBumpedRates(positions) {
    let rate;
    let token;

    return positions.map(position => {
      position.bumpedInvestmentReturn = position.investmentReturn + this.bumpedRate(position);

      return position;
    });
  }

  bumpedRate(position) {
    const token = this.findToken(position);

    return token && token.rate > 0 ? token.rate : 0;
  }

  findToken(position) {
    // eslint-disable-next-line eqeqeq
    return this.tokens.find(token => token.accountPosition.psId == position.psId);
  }
}
