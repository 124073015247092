<template>
  <div class="account-information">
    <div class="details">
      <h1>My Account</h1>
      <p>{{ account.accountType }} Account: {{ account.psId }}</p>
    </div>
    <div v-if="hasMessages"
         class="messages alert"
         :class="alertState"
         role="alert">
      <p v-for="message in messages"
         :key="message"
         class="message">
        {{ message }}
      </p>
    </div>
    <div class="card">
      <div class="card-heading">
        <h4>Personal Information</h4>
      </div>
      <div v-if="isForm"
           class="card-body account-information-edit">
        <form>
          <div class="control-wrapper"
               :class="{'has-error': account.errors.email}">
            <label class="control-label">Email</label>

            <input v-model="account.email"
                   class="form-control"
                   type="email"
                   name="email"
                   placeholder="name@example.com">

            <label v-show="account.errors.email"
                   class="error control-label">
              {{ account.errors.email }}
            </label>
          </div>
          <div class="control-wrapper"
               :class="{'has-error': account.errors.phone}">
            <label class="control-label">Phone</label>
            <input v-model="account.phone"
                   v-mask:phone="{clearMaskOnLostFocus: false}"
                   class="form-control"
                   name="phone"
                   placeholder="Phone">

            <label v-show="account.errors.phone"
                   class="error control-label">
              {{ account.errors.phone }}
            </label>
          </div>
          <div class="control-wrapper"
               :class="{'has-error': account.errors.address1}">
            <label class="control-label">Address</label>

            <input v-model="account.address1"
                   name="address"
                   class="form-control"
                   placeholder="Address">
            <label v-show="account.errors.address1"
                   class="error control-label">
              {{ account.errors.address1 }}
            </label>
          </div>
          <div class="control-wrapper">
            <label class="control-label">Address (Optional)</label>
            <input v-model="account.address2"
                   name="address2"
                   class="form-control"
                   placeholder="Address (Optional)">
          </div>
          <div class="control-wrapper"
               :class="{'has-error': account.errors.city}">
            <label class="control-label">City</label>
            <input v-model="account.city"
                   name="city"
                   class="form-control"
                   placeholder="City">
            <label v-show="account.errors.city"
                   class="error control-label">
              {{ account.errors.city }}
            </label>
          </div>
          <div class="control-wrapper"
               :class="{'has-error': account.errors.state}">
            <label class="control-label">State / Territory</label>

            <select v-model="account.state"
                    class="form-control"
                    name="state">
              <option v-for="usState in account.usStatesAndTerritoriesHash"
                      :key="usState.code"
                      :value="usState.code">
                {{ usState.name }}
              </option>
            </select>
            <label v-show="account.errors.state"
                   class="error control-label">
              {{ account.errors.state }}
            </label>
          </div>
          <div class="control-wrapper"
               :class="{'has-error': account.errors.zip}">
            <label class="control-label">ZIP Code</label>

            <input v-model="account.zip"
                   v-mask:zip
                   name="zip"
                   class="form-control"
                   placeholder="ZIP Code">
            <label v-show="account.errors.zip"
                   class="error control-label">
              {{ account.errors.zip }}
            </label>
          </div>
        </form>
        <div class="account-information-controls">
          <div class="links">
            <a v-if="showTinLink"
               class="update-tin-link hidden-xs"
               target="_self"
               :href="tinLinkPath">{{ tinLinkCopy }}</a>
            <a v-if="showTinLink"
               class="update-tin-link visible-xs"
               target="_self"
               :href="tinLinkPath">{{ shortTinLinkCopy }}</a>
          </div>
          <div class="buttons">
            <button class="btn btn-link-sm"
                    @click="setShowMode">
              Cancel
            </button>
            <button :disabled="disableSubmit()"
                    class="btn btn-primary-cta btn-primary-small btn-save"
                    @click="save">
              Save
            </button>
          </div>
        </div>
      </div>
      <div v-if="isShow"
           class="card-body account-information-show">
        <table class="table">
          <tbody>
            <tr>
              <td class="control-label">
                Email
              </td>
              <td>{{ account.email }}</td>
            </tr>
            <tr>
              <td class="control-label">
                Phone
              </td>
              <td v-mask:phone>
                {{ account.phone }}
              </td>
            </tr>
            <tr>
              <td class="control-label">
                Address
              </td>
              <td>{{ account.address1 }}</td>
            </tr>
            <tr>
              <td class="control-label">
                Address (Optional)
              </td>
              <td>{{ account.address2 }}</td>
            </tr>
            <tr>
              <td class="control-label">
                City
              </td>
              <td>{{ account.city }}</td>
            </tr>
            <tr>
              <td class="control-label">
                State / Territory
              </td>
              <td>{{ account.state }}</td>
            </tr>
            <tr>
              <td class="control-label">
                ZIP Code
              </td>
              <td>{{ account.zip }}</td>
            </tr>
          </tbody>
        </table>
        <div class="account-information-controls">
          <div class="links">
            <a v-if="showTinLink"
               class="update-tin-link hidden-xs"
               target="_self"
               :href="tinLinkPath">{{ tinLinkCopy }}</a>
            <a v-if="showTinLink"
               class="update-tin-link visible-xs"
               target="_self"
               :href="tinLinkPath">{{ shortTinLinkCopy }}</a>
          </div>
          <div class="buttons">
            <button class="btn btn-primary-cta btn-primary-small btn-edit"
                    @click="setEditMode">
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Account from "models/account";
import Ajax from "services/ajax";
import Mask from "directives/inputs";
import camelCase from "lodash/camelCase";

const MODE = {
  form: "form",
  show: "show",
};

const LOADING = {
  failure: "failure",
  success: "success",
  inProgress: "inProgress",
};

export default {
  name: "AccountInformation",
  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
  },
  data() {
    return {
      state: MODE.show,
      loadingState: LOADING.success,
      account: new Account(PSData.account),
      messages: [],
    };
  },
  computed: {
    hasMessages() {
      return this.messages.length && this.messages.length > 0;
    },
    showTinLink() {
      return this.account.tinInvalid;
    },
    shortTinLinkCopy() {
      // eslint-disable-next-line eqeqeq
      if (this.account.tinType == 1) {
        return "Update TIN";
      } else {
        return "Update SSN";
      }
    },
    tinLinkCopy() {
      // eslint-disable-next-line eqeqeq
      if (this.account.tinType == 1) {
        return "Update Tax Identification Number";
      } else {
        return "Update Social Security Number";
      }
    },
    tinLinkPath() {
      // eslint-disable-next-line eqeqeq
      if (this.account.tinType == 1) {
        return this.account.changeEinPath;
      } else {
        return this.account.changeSsnPath;
      }
    },
    isForm() {
      return this.state === MODE.form;
    },
    isShow() {
      return this.state === MODE.show;
    },
    isLoading() {
      return this.loadingState === LOADING.inProgress;
    },
    alertState() {
      if (this.loadingState === LOADING.failure) {
        return "alert-danger";
      } else {
        return "alert-success";
      }
    },
  },
  methods: {
    disableSubmit() {
      return this.isLoading || !this.account.valid;
    },
    setEditMode() {
      this.state = MODE.form;
    },
    setShowMode() {
      this.account.revert();
      this.state = MODE.show;
    },
    save() {
      this.loadingState = LOADING.inProgress;
      const account = this.account.toServer();

      Ajax
        .patch(this.account.updateAccountInfoPath, { account })
        .then(data => {
          if (data.errors) {

            Object.keys(data.errors).forEach((key) => {
              this.account.errors[camelCase(key)] = data.errors[key].join(", ");
            });

          } else {
            this.account.errors = {};
            this.messages = data;
            this.account.commit();
            this.state = MODE.show;
          }

          this.loadingState = LOADING.success;
        })
        .catch(() => {
          this.loadingState = LOADING.failure;
          this.messages = [
            "Something went wrong..."
          ];
        });
    },
  },
};
</script>
