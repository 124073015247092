<template>
  <div class="col-md-6 statement-outer">
    <div class="statement-block"
         :class="className">
      <div class="clearfix">
        <h3 class="statement-header">
          {{ header }}
        </h3>
        <select v-if="hasStatements"
                v-model="currentStatementsYear"
                class="statement-years-select">
          <option v-for="year in statementYears"
                  :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      <ul v-if="hasStatements"
          class="statement-items">
        <div v-if="groupByKey">
          <li v-for="(group, i) in groupedStatements"
              :key="i"
              class="statement-item">
            <div v-for="(statement) in group[1]"
                 :key="statement.id">
              <a target="_blank"
                 :href="statement.url">
                {{ statement.name }}
              </a>
            </div>
          </li>
        </div>
        <div v-else>
          <li v-for="statement in selectedStatements"
              :key="statement.id"
              class="statement-item">
            <a target="_blank"
               :href="statement.url">{{ statement.name }}</a>
          </li>
        </div>
      </ul>
      <span v-else
            class="statement-no-items">No {{ entity }} available</span>
    </div>
  </div>
</template>

<script>
import { first, uniq, groupBy } from "lodash";

export default {
  name: "Statements",
  props: {
    statements: {
      type: Array,
      required: true,
      default: () => [],
    },
    header: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      default: "statements",
    },
    orderBy: {
      type: String,
      default: null,
    },
    groupByKey: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: "statement-inner-left",
    },
  },
  data() {
    let currentStatementsYear;
    const statementYears = uniq(this.statements.map(record => record.year)).sort().reverse();

    if (this.statements.length > 0) {
      currentStatementsYear = first(statementYears);
    }

    return { currentStatementsYear, statementYears };
  },
  computed: {
    hasStatements() {
      return this.statements.length > 0;
    },
    selectedStatements() {
      // eslint-disable-next-line eqeqeq
      const statements = this.statements.filter(record => record.year == this.currentStatementsYear);

      if (this.orderBy) {
        return statements.sort((a, b) => {
          const value1 = a[this.orderBy];
          const value2 = b[this.orderBy];

          if (value1 < value2) { return -1; }

          if (value1 > value2) { return 1; }

          return 0;
        });
      } else {
        return statements;
      }
    },
    groupedStatements() {
      const group = Object.entries(groupBy(this.selectedStatements, this.groupByKey));

      // eslint-disable-next-line radix
      return group.sort((a, b) => b[0] - a[0]);
    },
  },
};
</script>
