import Setup from "components/auto_deposits/setup";
import Summary from "components/auto_deposits/summary";
import TotalEarnedCard from "components/dashboard/total_earned_card";

$(() => {
  new Vue({
    el: document.querySelector(".v-dashboard-overview-controller"),
    components: { TotalEarnedCard, Setup, Summary },
  });
});
