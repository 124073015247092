<template>
  <div
    v-cloak
    class="foreclosure-modal modal fade">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <div v-if="currentMilestone">
            <h3>Foreclosure Status</h3>
          </div>
          <div v-else>
            <h3>Foreclosure Process</h3>
          </div>
          <a class="close"
             aria-hidden="true"
             href="#"
             data-dismiss="modal">
            <i class="fa fa-times" />
          </a>
        </div>

        <div class="modal-body">
          <div v-if="currentMilestone">
            <section>
              <foreclosure-current-milestone
                :title="position.currentMilestone.title"
                :date="position.currentMilestoneDate"
                :message="position.currentMilestone.message" />
            </section>
            <h3>Foreclosure Process</h3>
          </div>

          <section>
            <foreclosure-milestone
              v-for="milestone in milestones"
              :key="milestone.name"
              :title="milestone.title"
              :description="milestone.description"
              :highlighted="milestone.highlighted" />
          </section>

          <h3 class="milestone-warnings-subheader">
            Can happen at any time during (or before/after) the foreclosure:
          </h3>

          <section>
            <foreclosure-milestone
              v-for="milestone in milestoneWarnings"
              :key="milestone.name"
              :title="milestone.title"
              :description="milestone.description"
              :highlighted="milestone.highlighted"
              :milestone-class="'milestone-warning'" />
          </section>

          <div class="action-buttons">
            <button class="btn btn-success"
                    data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForeclosureCurrentMilestone from "components/account_positions/foreclosure/current_milestone";
import ForeclosureMilestone from "components/account_positions/foreclosure/milestone";

const OPEN_MODAL_EVENT = "openForeclosureModal";
const accountPositionData = PSData.accountPosition;

export default {
  name: "ForeclosureModal",
  components: { ForeclosureMilestone, ForeclosureCurrentMilestone },
  data() {
    return {
      milestones: PSData.foreclosure.milestones || [],
      milestoneWarnings: PSData.foreclosure.warnings || [],
      position: {},
    };
  },
  computed: {
    currentMilestone() {
      return this.position.currentMilestone;
    },
  },
  mounted() {
    PS.globalBus.$on(OPEN_MODAL_EVENT, this.openModal);
  },
  methods: {
    openModal(position) {
      this.position = position || accountPositionData;
      $(this.$el).modal("show");
    },
  },
};
</script>
