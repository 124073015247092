const IDLE_SESSION_CHECK = 10 * 1000; // 10 seconds
const IDLE_SESSION_KEY = "peerstreet.session.expires_at";

class IdleSession {
  constructor() {
    this.storage = PS.Services.LocalStorage;
  }

  ttl() {
    var minutes = (PSData.user && PSData.user.idleSessionTtl) || 30;

    return minutes * 60 * 1000;
  }

  userSignedIn() {
    return PSData.user && PSData.user.signedIn === "yes";
  }

  setExpiresAt() {
    var expiresAt = new Date(Date.now() + this.ttl());

    this.storage.setItem(IDLE_SESSION_KEY, expiresAt);
  }

  scheduleIdleCheck() {
    setInterval(this.ping.bind(this), IDLE_SESSION_CHECK);
  }

  reloadPage() {
    document.location.reload(true);
  }

  ping() {
    var expiresAt = this.storage.getItem(IDLE_SESSION_KEY);

    if (expiresAt && (new Date(expiresAt) <= new Date())) {
      this.reloadPage();
    }
  }

  run() {
    if (this.userSignedIn()) {
      this.setExpiresAt();
      this.scheduleIdleCheck();
    }
  }

  onDomReady() {
    this.run();
  }

  onAjaxSuccess() {
    if (this.userSignedIn()) {
      this.setExpiresAt();
    }
  }
}

window.PS.Components.IdleSession = IdleSession;
PS.Components.register(IdleSession);
