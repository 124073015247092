Vue.directive("select", {
  inserted: function(el, binding) {
    let options = binding.value ? binding.value : {};
    let element = $(el);

    element
      .select2(Object.assign(options, { placeholder: "Type to select an item" }))
      .on("change", function() {
        PS.globalBus.$emit("select2SelectedValue", element.val());
      });
  },

  unbind: function(el) {
    $(el).select2("destroy");
  },
});
