<template>
  <div class="form-buttons">
    <button
      type="submit"
      class="btn btn-primary-cta btn-block"
      :disabled="isSubmiting || disabled"
      @click="onClick()">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isSubmiting: Boolean,
    disabled: Boolean,
  },

  computed: {
    buttonText() {
      return this.isSubmiting ? "Submitting..." : "Submit Order";
    },
  },

  methods: {
    onClick() {
      this.$emit("submit");
    },
  },
};
</script>
