import Statements from "components/statements";

$(() => {
  new Vue({
    el: document.querySelector(".v-statements-controller"),
    components: { Statements },
    methods: {
      statements() {
        return PSData.statements || [];
      },
      taxDocuments() {
        return PSData.taxDocuments || [];
      },
    },
  });
});
