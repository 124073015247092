<template>
  <div>
    <form class="simple_form form-horizontal"
          :action="url"
          method="POST">
      <input type="hidden"
             name="transaction[user_id]"
             :value="userId">
      <input type="hidden"
             name="transaction[method_type]"
             value="wire">
      <input type="hidden"
             name="transaction[amount]"
             :value="wireAmount">
      <input ref="token"
             type="hidden"
             name="authenticity_token">

      <div class="row">
        <div class="col-md-12">
          <p>Cash Balance: {{ cashBalance | money }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p v-cloak
             class="wire-amount">
            Wire Amount: {{ wireAmount | money }}
          </p>
        </div>
        <div class="col-md-6">
          <div class="form-group currency adjustment-amount-container">
            <label class="control-label currency"
                   for="adjustment_amount">
              Adjustment Amount
            </label>
            <div class="input-group-wrapper">
              <div class="input-group">
                <span class="input-group-addon">$</span>
                <input
                  v-model="adjustmentAmount"
                  v-mask:currency
                  class="form-control currency"
                  type="text">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <input
            class="btn btn-primary"
            data-disable-with="Create Withdrawal"
            name="commit"
            value="Create Withdrawal"
            type="submit">
        </div>
      </div>

      <div v-if="errors"
           class="alert alert-danger">
        {{ errors }}
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="jarviswidget">
            <header>
              <h2>Unlinked Payments</h2>
            </header>
            <div role="content">
              <div class="widget-body no-padding">
                <unlinked-payment-list :user-id="userId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Events from "components/manage/transactions/unlinked_payment/events";
import Mask from "directives/inputs";
import NumberFormatter from "formatters/number_formatter";
import UnlinkedPaymentList from "components/manage/transactions/unlinked_payment/list";
import { money } from "filters";

export const { unmaskNumber } = NumberFormatter;

export default {
  name: "UnlinkedPaymentForm",
  components: { UnlinkedPaymentList },
  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
  },
  filters: {
    money,
  },
  props: {
    cashBalance: { type: String, default: null },
    errors: { type: String, default: null },
    url: { type: String, default: null },
    userId: { type: String, default: null },
  },
  data() {
    return {
      adjustmentAmount: null,
      paymentsAmount: 0,
    };
  },
  computed: {
    wireAmount() {
      return this.paymentsAmount + unmaskNumber(this.adjustmentAmount);
    },
  },
  mounted() {
    this.setAuthToken();
    this.watchForPaymentsAmount();
  },
  methods: {
    setAuthToken() {
      const token = document.querySelector("meta[name=\"csrf-token\"]").content;

      this.$refs.token.value = token;
    },
    watchForPaymentsAmount() {
      PS.globalBus.$on(Events.amountUpdated, amount => {
        this.paymentsAmount = amount;
      });
    },
  },
};
</script>
