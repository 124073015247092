<template>
  <div v-if="showLoans"
       id="loansTable">
    <outstanding-bids-table :available-loans="availableLoans" />
    <buyer-loans-table :available-loans="availableLoans"
                       :refresh-loans="refreshLoans" />
  </div>
</template>

<script>
import BuyerBidsService from "services/secondary_market/buyer_bids_service";
import BuyerLoansTable from "components/secondary_market/buyer_loans_table";
import OutstandingBidsTable from "components/secondary_market/outstanding_bids_table";

export default {
  name: "BuyerBids",
  components: {
    BuyerLoansTable,
    OutstandingBidsTable,
  },
  data() {
    return {
      loans: [],
      showLoans: false,
    };
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth <= 768;
    },
    popoverProperties() {
      return { placement: this.isMobileDevice ? "bottom" : "right" };
    },
    availableLoans() {
      return this.loans;
    },
  },
  created() {
    this.getAvailableLoans();
  },
  methods: {
    async getAvailableLoans() {
      try {
        let data = await BuyerBidsService.getLoans();

        if (data.success) {
          this.loans = data.loans;
          this.showLoans = true;
        }
      } catch (error) {
        if (window.bugsnag) {
          window.bugsnag.notify(error);
        } else {
          console.log(error);
        }
      }
    },
    refreshLoans() {
      this.showLoans = false;
      this.getAvailableLoans();
    },
  },
};
</script>
