<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="auto-investments-extended-settings">
    <div class="row">
      <div class="col-md-6 d-none d-md-flex">
        <div class="auto-investments-disable-settings"
             :class="{'control-disabled': disabled}">
          <a @click="disableCustomOptions">Disable Custom Settings</a>
        </div>
      </div>
      <div class="col-md-6 offset-md-0 flex">
        <div class="auto-investments-option mb-5" :class="{'control-disabled': disabled}">
          <label class="control-label">Maximum Term (Months)</label>
          <vue-slider
            v-model="settings.maxTerm"
            :min="12"
            :max="36"
            :interval="12"
            :marks="true"
            :hide-label="true"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :tooltip-formatter="maxTermLabel"
            :disabled="disabled"
          ></vue-slider>
        </div>
        <div class="auto-investments-option mb-5" :class="{'control-disabled': disabled}">
          <label class="control-label">Maximum LTV</label>
          <vue-slider
            v-model="settings.maxLtv"
            :min="60"
            :max="85"
            :interval="5"
            :marks="true"
            :hide-label="true"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :tooltip-formatter="maxLtvLabel"
            :disabled="disabled"
          ></vue-slider>
        </div>
        <div class="auto-investments-option mb-5" :class="{'control-disabled': disabled}">
          <label class="control-label">Minimum Yield</label>
          <vue-slider
            v-model="settings.minRate"
            :min="4.0"
            :max="8.0"
            :interval="0.25"
            :marks="true"
            :hide-label="true"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :tooltip-formatter="minRateLabel"
            :disabled="disabled"
          ></vue-slider>
        </div>
      </div>
      <div class="d-flex d-md-none col-10 offset-md-0">
        <div class="auto-investments-disable-settings">
          <a @click="disableCustomOptions">Disable Custom Settings</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AutoInvestmentsExtendedSettings",
    props: {
      settings: { type: Object, required: true },
    },
    data() {
      return {
        maxTermLabel: "{value}",
        maxLtvLabel: "{value}%",
        minRateLabel: this.formatMinRateLabel(),
      }
    },
    computed: {
      disabled() {
        return !this.settings.active;
      },
    },
    methods: {
      disableCustomOptions() {
        if (this.disabled) { return; }

        this.$emit("disable-custom-options");
      },
      formatMinRateLabel() {
        return value => `${("" + value).replace(/^[0-9]\d*$/g, value + ".0")}%+`;
      },
    },
  };
</script>
