import Vue from "vue";
import DateFormatter from "./formatters/date_formatter";
import NumberFormatter from "formatters/number_formatter";
import StringFormatter from "formatters/string_formatter";
import ValueFormatter from "formatters/value_formatter";

export const {
  formatMoney: money,
  formatPercent: percent,
  formatNumber: number,
  formatDecimal: round,
  unmaskNumber: unmask,
} = NumberFormatter;

export const { ceil, floor } = Math;
export const { ordinalize, pluralize } = StringFormatter;
export const { formatInvalidValue } = ValueFormatter;
export const { formatDate: date } = DateFormatter;

Vue.filter("ceil", ceil);
Vue.filter("floor", floor);
Vue.filter("money", money);
Vue.filter("unmask", unmask);
Vue.filter("number", number);
Vue.filter("ordinalize", ordinalize);
Vue.filter("percent", percent);
Vue.filter("pluralize", pluralize);
Vue.filter("round", round);
Vue.filter("missingValue", value => formatInvalidValue(value, "—"));
Vue.filter("date", date);
