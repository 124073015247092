<template>
  <p class="pocket-investment-confirmation-note">
    Funds deposited from your bank typically take 3-4 business days to clear.
    Pending transactions can be viewed on your Dashboard as well as in Transaction History.
  </p>
</template>

<script>
export default {
};
</script>
