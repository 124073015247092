class ReturnCalculatorDatasource {
  constructor(returnCalculator, scaleSteps = 3) {
    this.returnCalculator = returnCalculator;
    this.scaleSteps = scaleSteps;
  }

  data() {
    return {
      labels: this.labels(),
      datasets: [{
        backgroundColor: "rgba(102, 204, 51, 0.1)",
        borderColor: "rgba(102, 204, 51, 1)",
        data: this.returnCalculator.calculatePayouts(),
      }],
    };
  }

  options() {
    return {
      elements: {
        point: {
          pointStyle: "line",
          hitRadius: 7,
        },
      },
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        displayColors: false,
        backgroundColor: "rgba(102, 204, 51, 1)",
        callbacks: {
          title: () => null,
          labelTextColor: function(context) {
            return "#ffffff";
          },
          label: function(context) {
            return "$" + context.yLabel;
          },
        },
      },
      tension: 0,
      scales: {
        xAxes: [{
          min: 0,
          max: this.scaleSteps,
          scaleLabel: {
            display: true,
            labelString: "Months",
          },
        }],
        yAxes: [{
          min: 0,
          max: this.scaleSteps,
          ticks: {
            stepSize: this._calculateStepWidth(this.scaleSteps),
            callback: function(value) {
              return "$" + value.toLocaleString();
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Dollars",
          },
        }],
      },
    };
  }

  labels() {
    var labels = [];

    for (let i = 0; i <= this.returnCalculator.term; i++) {
      labels.push(i);
    }

    return labels;
  }

  _calculateStepWidth(stepCount) {
    const total = this.returnCalculator.calculateTotalReturn() / stepCount;

    return Math.ceil(total / 10) * 10;
  }
}

window.PS.Models.ReturnCalculatorDatasource = ReturnCalculatorDatasource;
