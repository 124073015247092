export default class NumberFormatter {
  static formatMoney(value, precision = 2, { plus = false } = {}) {
    const maskedNumber = NumberFormatter.formatNumber(value, precision).replace("-", "");
    const sign = NumberFormatter._displaySign(value, plus);

    return `${sign}$${maskedNumber}`;
  }

  static formatPercent(value, precision = 2, { plus = false } = {}) {
    const maskedNumber = NumberFormatter.formatNumber(value, precision).replace("-", "");
    const sign = NumberFormatter._displaySign(value, plus);

    return `${sign}${maskedNumber}%`;
  }

  static formatNumber(value, precision = 2) {
    return parseFloat(value)
      .toFixed(precision)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  static unmaskNumber(value) {
    if (value || value === 0) {
      return parseFloat(value.toString().replace(/,/g, ""));
    } else {
      return null;
    }
  }

  static formatDecimal(value, precision = 2, { truncateZero = false } = {}) {
    const fixed = parseFloat(value).toFixed(precision);
    const endsWithZero = fixed[fixed.length - 1] === "0";

    return endsWithZero && truncateZero ? fixed.slice(0, -1) : fixed;
  }

  static _displaySign(value, plus = false) {
    const parsedValue = parseFloat(value);

    let sign = "";

    if (parsedValue > 0 && plus) {
      sign = "+";
    } else if (parsedValue < 0) {
      sign = "-";
    }

    return sign;
  }
}

window.PS.Models.NumberFormatter = NumberFormatter;

export let formatMoney = NumberFormatter.formatMoney;
export let unmaskNumber = NumberFormatter.unmaskNumber;
