import "namespaces";

// require_tree ./services
import "services/globalBus";
import "services/greenhouse";
import "services/intercom_service";
import "services/local_storage";
import "services/ajax";
import "services/location_service";
import "services/document_service";
import "services/window_service";

// require_tree ./directives
import "directives/select2";
import "directives/inputs";
import "directives/popover";

// require_tree ./models
import "models/datasources/return_calculator_datasource";
import "models/return_calculator";
import "models/discounted_return_calculator";

import "filters";

// require_tree ./components
import "components/components"; //should go first
import "components/auto_investment_scrolling"; //needs components/components
import "components/focus_modals_on_first_input";
import "components/header_overlay";
import "components/idle_session_timeout";
import "components/loans/loan_details_table";
import "components/loans/loan_details_borrower_table";
import "components/loans/loan_invested_amount";
import "components/loans/loan_investment_info";
import "components/loans/loan_investment_status";
import "components/loans/loan_sidebar";
import "components/loans/loans_list";
import "components/loans/loans_list_item";
import "components/loans/loans_list_page";
import "components/loans/skin_in_the_game";

import "components/read_more";
import "components/return_calculator";
import "components/signing/document_height";
import "components/signing/document_status";
import "components/social_sharing";
import "components/tracking/anonymous_user_info";
import "components/video_modal";
import "components/sticky_element";
import "components/learning_resources_slider";

// require_tree ./controllers
import "controllers/two_factor_sign_in_auth_controller";
import "controllers/about_us_controller";
import "controllers/account_bar_controller";
import "controllers/account_controller";
import "controllers/account_form_controller";
import "controllers/account_positions_controller";
import "controllers/auto_investment_controller";
import "controllers/dashboard_controller";
import "controllers/recaptcha_container_controller";
import "controllers/loan_details_controller";
import "controllers/loan_overview_controller";
import "controllers/loan_overview_cta_box_controller";
import "controllers/loan_details_cta_box_controller";
import "controllers/loan_details_mobile_controller";
import "controllers/loans_list_controller";
import "controllers/manual_verification_controller";
import "controllers/organization_loans_controller";
import "controllers/auto_deposits_controller";
import "controllers/bank_accounts_settings_controller";
import "controllers/withdraw_funds_page_controller";
import "controllers/promo_controller";
import "controllers/statements_controller";
import "controllers/dashboard_overview_controller";
import "controllers/deposits_controller";
import "controllers/invest_controller";
import "controllers/investment_funds_modal_controller";
import "controllers/privacy_section_controller";
import "controllers/investment_fund_promissory_note_controller";
import "controllers/two_factor_auth_controller";
import "controllers/secondary_market/buyout_offer_controller";
import "controllers/secondary_market/buyer_bids_controller";
import "controllers/savings/savings_invest_modal_controller";
import "controllers/savings/rwn_controller";
import "controllers/investments_controller";

// Manage controllers
import "controllers/manage/borrower_controller";
import "controllers/manage/settings_controller";
import "controllers/manage/user_account_controller";
import "controllers/manage/distribution_form_controller";
import "controllers/manage/verification_requests_controller";
import "controllers/manage/auto_deposits/auto_deposits_show_controller";
import "controllers/manage/savings/auto_deposits/auto_deposits_show_controller";
import "controllers/manage/transactions/linked_withdrawal_controller";
import "controllers/manage/loans_controller";
import "controllers/manage/marketing_banners_controller";

$(() => window.PS.Components.start());
