<template>
  <button class="btn-primary-cta btn-block"
          @click.prevent="showModal">
    Invest
  </button>
</template>
<script>
const EVENT = "openInvestModal";

export default {
  props: {
    url: { type: String, required: true },
  },
  methods: {
    showModal() {
      PS.globalBus.$emit(EVENT, this.url);
    },
  },
};
</script>
