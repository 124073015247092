$(() => {
  new Vue({
    el: document.querySelector(".v-manual-verification-controller"),
    data: {
      disabled: false,
    },
    computed: {
      // eslint-disable-next-line eqeqeq
      disabledForm: function() { return this.disabled == true; },
    },
    methods: {
      submitForm: function(e) {
        var form = this.$el.querySelector("form");
        var files = e.target.files;

        if (files.length > 0) {
          this.disabled = true;
          form.submit();
        }
      },
    },
  });
});
