<template>
  <div class="investment-item">
    <div class="image"
         :style="{ backgroundImage: 'url(' + image + ')' }" />

    <div class="content">
      <span class="title">
        {{ title }}
        <a v-if="tooltip"
           v-popover="{placement: 'right'}"
           class="tooltip-toggle"
           href="#">
          <i class="fa fa-question-circle" />
        </a>
        <span v-if="tooltip"
              class="d-none">
          {{ tooltip }}
        </span>
      </span>

      <div class="subtitle">
        {{ subtitle }}
      </div>

      <div v-for="field in fields"
           :key="field.title"
           class="field-row">
        <span class="field-title">
          {{ field.title }}
        </span>

        <span class="field-value">
          {{ field.value }}
        </span>
      </div>

      <div class="ctas-section">
        <a class="btn-primary-cta btn-block"
           :href="ctaUrl">
          {{ ctaTitle }}
        </a>

        <a v-if="secondaryCtaUrl"
           class="btn-secondary-outlined btn-block"
           :href="secondaryCtaUrl">
          {{ secondaryCtaTitle }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestmentCatalogItem",
  props: {
    image: { type: String, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    tooltip: { type: String, default: null },
    fields: { type: Array, required: true },
    ctaTitle: { type: String, default: null },
    ctaUrl: { type: String, default: null },
    secondaryCtaTitle: { type: String, default: null },
    secondaryCtaUrl: { type: String, default: null },
  },
  data() {
    return {
    };
  },
};
</script>
