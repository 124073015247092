<template>
  <banner-v3>
    <template #title>
      Invite your friends to PeerStreet
    </template>
    <template #image>
      <div class="banner-v3-image banner-v3-image-border incentive-invitation-image" />
    </template>
    <template #content>
      When they fund their account you both get a <span>1% YIELD BUMP</span>!
      <promo-link url="/invites"
                  label="Invite Friends">
        Learn more
      </promo-link>
    </template>
  </banner-v3>
</template>
<script>
import PromoLink from "components/promo/link";
import BannerV3 from "../../banner_v3";

export default {
  name: "PromoBannerInvite",
  components: { BannerV3, PromoLink },
};
</script>
