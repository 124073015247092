// To declare custom component you can
// 1) create a simple class for it:
//   class MyComponent {}
//
// 2) choose which events you want to subscribe:
//   class MyComponent {
//     onDomReady($element, event) { ... }
//     onWindowLoad($element, event) { ... }
//   }
//
// 3) and register this component:
//   PS.Components.register(MyComponent);
//
const ID_PREFIX = "__ps_domid_";

function classHash(className) {
  return /^[\w ]+\(\)/.exec(className.toString());
}

class Components {
  constructor() {
    this.DOMIDCounter = 0;

    this.registredClasses = {
      onDomReady:         [],
      onWindowLoad:       [],
      onAjaxSuccess:      [],
    };

    this.globalListeners  = {};
    this.elementListeners = {};
  }

  // Subscribes each listener for events, if such methods are present in @componentClass:
  //  * #onDomReady — called after the "DOM content loaded" event for each assigned element
  //  * #onWindowLoad - called after the "window load" event for each assigned element
  //  * #onAjaxSuccess - called after the "ajaxSuccess" event for each assigned element
  // Signature of all these methods is *function(jQueryElement, event)*

  // Creates only one instance for all events. Constructor method of @componentClass
  // will be called only once after the "DOM content loaded" event.

  // @options:
  //  * applyOnlyTo: 'css selector' - instantiate and attach component only for pages with this selector
  // If you have more that one element which matches this selector,
  // these four methods (#onDomReady, #onWindowLoad or #onAjaxSuccess)
  // will be called for each element, one after one.
  //
  // For example, if you have class with onDomReady method and this class was registered with applyOnlyTo: '.js-read-more-text' option,
  // and your page contains three elements with .js-read-more-text class, method #onDomReady will be called three times for each element.

  register(componentClass, options = {}) {
    var events = Object.keys(this.registredClasses);
    var classUID = classHash(componentClass);

    events.forEach((event) => {
      var eventHandlerDefined = Object.prototype.toString.call(componentClass.prototype[event]) === "[object Function]";

      if (eventHandlerDefined) { // ignore classes without subscribers
        this.registredClasses[event].push({
          componentClass: componentClass,
          classUID:       classUID,
          options:        options,
        });
      }
    });
  }

  handleEvent(eventName, eventObject) {
    try {
      this.registredClasses[eventName].forEach((listener) => {
        if (listener.options.applyOnlyTo) {
          let $elements = $(listener.options.applyOnlyTo);

          $elements.each((__, element) => {
            if (!element.id) {
              // big thanks to silly design of js, two similar DOM elements will be counted as the same elements
              // to prevent that we will update DOM element's id to make each DOM element uniq
              element.id = `${ID_PREFIX}${++this.DOMIDCounter}`;
            }

            if (!this.elementListeners[element.id]) {
              this.elementListeners[element.id] = {};
            }

            if (!this.elementListeners[element.id][listener.classUID]) {
              this.elementListeners[element.id][listener.classUID] = new listener.componentClass();
            }

            this.elementListeners[element.id][listener.classUID][eventName]($(element), eventObject);
          });
        } else {
          if (!this.globalListeners[listener.classUID]) {
            this.globalListeners[listener.classUID] = new listener.componentClass();
          }

          this.globalListeners[listener.classUID][eventName](undefined, eventObject);
        }
      });
    } catch (e) {
      if (console && console.error) { console.error("PS ERROR:", e); }
    }
  }

  start() {
    if (window.__ps_test_mode) { return; } // do not run in test mode

    this.handleEvent("onDomReady", {}); //DOMContentLoaded is already happened
    $(document).on("ajaxSuccess", (e) => { this.handleEvent("onAjaxSuccess", e); });
    $(window).on("load", (e) => { this.handleEvent("onWindowLoad", e); });
  }
}

window.PS.Components = new Components();
