<template>
  <div>
    <ul ref="slider" 
        :class="resolvedContainerClass">
      <slot />
    </ul>
  </div>
</template>
<script>
export default {
  name: "BannerV3Slider",
  props: {
    numberOfSlides: {
      type: [Number , String],
      default: 1,
    },
    containerClass: {
      type: String,
      default: null,
    },
    pager: {
      type: Boolean,
      default: true,
    },
    sliderClass: {
      type: String,
      default: "bx-wrapper",
    },
    slideWidth: {
      type: [Number , String],
      default: 0,
    },
  },
  computed: {
    resolvedContainerClass() {
      return this.$props.containerClass || "d-block";
    },
    sliderConfig() {
      return {
        infiniteLoop: false,
        speed: 400,
        useCSS: false,
        controls: true,
        touchEnabled: false,
        minSlides: this.numberOrZero(this.numberOfSlides),
        maxSlides: this.numberOrZero(this.numberOfSlides),
        slideWidth: this.numberOrZero(this.slideWidth),
        wrapperClass: this.sliderClass,
        pager: this.pager,
      };
    },
  },
  mounted() {
    this.setupSlider();
    window.addEventListener("resize", this.setupSlider);
  },
  methods: {
    /*
     * When provided via HAML, attributes are converted to 
     * strings. The bzSlider library documentation specifies 
     * the slideWidth input as an integer.
     */
    numberOrZero(val) {
      return val ? parseInt(val, 10) : 0;
    },
    setupSlider() {
      if (this.$slider) {
        this.$slider.reloadSlider(this.sliderConfig);
      } else {
        this.$slider = $(this.$refs.slider).bxSlider(this.sliderConfig);
      }
    },
  },
};
</script>
