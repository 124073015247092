export default class BuyoutOffer {
  constructor(buyoutOffer) {
    this._buyoutOffer = buyoutOffer;
  }

  static get offerFAQPath() {
    return "https://info.peerstreet.com/faqs/what-is-the-secondary-marketplace/";
  }

  get investmentAmount() {
    return this._buyoutOffer.investmentAmount;
  }

  get outstandingPrincipal() {
    return this._buyoutOffer.outstandingPrincipal;
  }

  get offerAmount() {
    return this._buyoutOffer.offerAmount;
  }

  get discountToPar() {
    const discountRaw = 1.0 - this._buyoutOffer.offerRate;
    const discountRounded = Math.round((discountRaw + Number.EPSILON) * 1000) / 1000;

    return discountRounded;
  }

  get annualReturnRate() {
    return this._buyoutOffer.annualReturnRate;
  }

  get interestEarned() {
    return this._buyoutOffer.interestEarned;
  }

  get totalReturn() {
    return this._buyoutOffer.totalReturn;
  }

  get bidId() {
    return this._buyoutOffer.bidId;
  }

  get acceptedAt() {
    return this._buyoutOffer.acceptedAt;
  }
}
