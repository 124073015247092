<template>
  <tr class="bank-account-balance">
    <td class="balance-title">
      Bank Account Balance
    </td>
    <td v-if="isSyncing"
        class="balance-message text-right">
      Syncing...
    </td>
    <td v-else-if="hasBalanceAmount"
        class="text-right">
      <span class="balance-message">{{ account.balance | money }} as of {{ account.balanceUpdatedAt }}</span>
      <i class="fa fa-refresh"
         @click="refresh" />
    </td>
    <td v-else
        class="text-right">
      <span class="balance-message">{{ message }}</span>
      <i v-if="balanceIsAvailable"
         class="fa fa-refresh"
         @click="refresh" />
    </td>
  </tr>
</template>

<script>
import Ajax from "services/ajax";

const POLL_INTERVAL = 5000;

export default {
  name: "ManageBankAccountBalance",
  data() {
    const account = PSData.plaidAccount;
    const state = (typeof account === "object" && account.balanceSyncing) ? "syncing" : "synced";

    if (state === "syncing") {
      this.subscribeToUpdates();
    }

    return {
      state: state,
      account: account,
      timer: undefined,
    };
  },
  computed: {
    isSyncing() {
      return this.state === "syncing";
    },
    message() {
      if (this.state === "failed" && !this.hasBalanceAmount) {
        return "Balance call unsuccessful";
      } else {
        return "Not available";
      }
    },
    hasBalanceAmount() {
      return this.balanceIsAvailable && this.account.balance !== null;
    },
    balanceIsAvailable() {
      return this.account !== undefined;
    },
  },
  methods: {
    subscribeToUpdates() {
      this.timer = setInterval(() => {
        Ajax
          .get(this.account.refreshUrl)
          .then(account => {
            if (account.balanceSyncing === false) {
              this.account = account;
              this.state = "synced";
              clearInterval(this.timer);
            }
          })
          .catch(() => {
            this.state = "failed";
            clearInterval(this.timer);
          });
      }, POLL_INTERVAL);
    },
    refresh() {
      this.state = "syncing";

      Ajax
        .post(this.account.refreshUrl)
        .then(() => this.subscribeToUpdates())
        .catch(() => { this.state = "failed"; });
    },
  },
};
</script>
