function suppressRailsValidationMessages() {
  return $(".alert-danger, .help-block").addClass("hidden");
}

const validPrefixes = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32"];

let validationOptions = {
  errorClass: "error control-label",
  success: function(label, element) {
    suppressRailsValidationMessages();
    $(element).removeClass("error-control");
    if (label.closest(".form-group").find(".error-control").length === 0) {
      label.closest(".form-group").removeClass("has-error").addClass("has-success");
      label.prev(".form-group").removeClass("has-error").addClass("has-success");
    }

    $(element).next(".error.control-label").remove();
    $(".error-note").removeClass("text-error");
  },
  highlight: function(element) {
    suppressRailsValidationMessages();
    $(element)
      .addClass("error-control")
      .closest(".form-group")
      .removeClass("has-success")
      .addClass("has-error");
    $(".error-note").addClass("text-error");
  },
};

export const validateForms = function validateForms() {
  $("input[type='checkbox'][data-checkbox-group]").on("change", (event) => {
    $(`#${event.target.dataset["checkboxGroup"]}`).blur();
  });

  $("form.validate").each(function() {
    $(this).validate(validationOptions);
  });
};

export const validateFormWithoutSubmitting = function validateFormWithoutSubmitting() {
  validationOptions.submitHandler = (_) => { return false; };

  $("#validate-with-captcha").validate(validationOptions);
};

function validRoutingNumber(number) {
  let i, sum;

  i = 0;
  sum = 0;
  while (i < number.length) {
    sum += parseInt(number.charAt(i), 10) * 3 +
      parseInt(number.charAt(i + 1), 10) * 7 +
      parseInt(number.charAt(i + 2), 10);
    i += 3;
  }

  return ((sum !== 0 && sum % 10 === 0) && validRoutingNumberPrefix(number));
}

function validRoutingNumberPrefix(number) {
  return validPrefixes.includes(number.substring(0, 2));
}

export function applyJQueryValidations() {
  jQuery.extend(jQuery.validator.messages, {
    equalTo: "Passwords do not match.",
  });

  jQuery.validator.addMethod("routing_number", (function(value, element, params) {
    return this.optional(element) || (validRoutingNumber(value));
  }), jQuery.validator.format("Invalid routing number."));

  jQuery.validator.addMethod("regexp", function(value, element, regexp_string) {
    return this.optional(element) || new RegExp(regexp_string).test(value);
  }, jQuery.validator.format("Invalid format."));

  jQuery.validator.addMethod("checkbox-group", function(value, element, group_name) {
    return $("input[type='checkbox'][data-checkbox-group='" + group_name + "']:checked").length > 0;
  }, jQuery.validator.format("Select at least one option."));

  jQuery.validator.addMethod("tin", (function(value, element, params) {
    return this.optional(element) || PSData["invalidTins"].indexOf(value.replace(/\D+/g, "")) === -1;
  }), jQuery.validator.format("Invalid number."));
}

window.validateForms = validateForms;
window.applyJQueryValidations = applyJQueryValidations;
