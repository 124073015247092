<template>
  <div v-cloak
       class="investment-funds-modal modal fade">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Invest into PeerStreet Portfolio</h2>
          <a class="close"
             aria-hidden="true"
             href="#"
             @click="closeModal">
            <i class="fa fa-times" />
          </a>
        </div>

        <div class="modal-body">
          <p class="mb-3">
            Invest funds from your PeerStreet account into the Portfolio.
          </p>
          <div class="modal-available mb-4 rounded">
            <label>Available to Invest</label>
            <a v-if="cashAvailable"
               :href="addFundsUrl"
               class="add-funds-link">Add funds</a>
            <span class="available-amount">{{ formattedAvailableCash }}</span>
          </div>
          <form
            ref="form"
            class="investment-funds-form"
            :class="{ 'has-error': hasErrors }"
            @submit.prevent.capture="addFunds()">
            <template v-if="cashAvailable">
              <label>Choose amount</label>
              <div class="invest-options">
                <div v-for="(predefined, idx) in filteredPredefinedAmounts"
                     :key="`predefined-amount-${predefined}`"
                     class="form-check">
                  <input :id="`invest-option-${predefined}`"
                         v-model="predefinedAmount"
                         class="form-check-input"
                         type="radio"
                         :value="predefined"
                         name="amount">
                  <label class="form-check-label"
                         :for="`invest-option-${predefined}`">
                    {{ predefined | formatMoney }}
                    <template v-if="idx === 0">
                      (Available to Invest)
                    </template>
                  </label>
                </div>
                <div class="form-check">
                  <input id="invest-option-custom"
                         v-model="predefinedAmount"
                         class="form-check-input"
                         type="radio"
                         name="amount"
                         value="custom">
                  <label class="form-check-label"
                         for="invest-option-custom">
                    Custom Amount
                  </label>
                </div>
              </div>

              <label v-if="serverErrors && !customAmountShown"
                     class="error control-label"
                     v-html="serverErrors">{{ serverErrors }}</label>

              <template v-if="customAmountShown">
                <div class="control-wrapper custom-amount">
                  <div class="form-group currency required"
                       :class="{ 'has-error': errors }">
                    <label class="control-label"
                           for="amount">Amount</label>
                  </div>

                  <div class="input-group-wrapper">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        id="amount"
                        v-model="customAmount"
                        v-mask:currency
                        type="text"
                        class="form-control currency"
                        autocomplete="off"
                        :class="{ 'error-control': errors }">
                    </div>
                    <span class="form-helper-label">{{ minimumAmountMessage }}</span>
                    <label v-if="errors"
                           class="error control-label"
                           v-html="errors">{{ errors }}</label>

                    <label v-if="serverErrors"
                           class="error control-label"
                           v-html="serverErrors">{{ serverErrors }}</label>
                  </div>
                </div>
              </template>
            </template>
            <div class="form-buttons">
              <button
                v-if="cashAvailable"
                type="submit"
                class="btn btn-block btn-primary-cta"
                :disabled="isLoadingOrClosedToNewInvestments">
                Invest
                <a v-if="closedToNewInvestments"
                   v-popover="{ placement: 'top' }"
                   href="#"
                   class="tooltip-toggle">
                  <i class="fa fa-question-circle" />
                </a>
                <span class="d-none">
                  The investing period for the Portfolio Beta has closed.
                  To join the next waitlist please visit our
                  <a :href="waitlistUrl">landing page</a>.
                </span>
              </button>
              <a v-else
                 :href="addFundsUrl"
                 class="btn btn-block btn-primary-cta">
                Add funds
              </a>
              <button class="btn btn-block"
                      type="button"
                      @click="closeModal">
                Cancel
              </button>
            </div>
          </form>

          <div class="upcoming-date-list">
            <p>
              <span>
                Investing Period Ends:
                <strong>
                  {{ nextInvestmentCutoffDate }}
                </strong>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ajax from "services/ajax";
import NumberFormatter from "formatters/number_formatter";
export const { formatMoney, unmaskNumber } = NumberFormatter;

const OPEN_MODAL_EVENT = "openInvestmentFundsModal";
const PREDEFINED_AMOUNTS = [10000, 5000, 1000];

export default {
  name: "InvestmentFundsModal",
  filters: {
    formatMoney,
  },
  props: {
    minAmount: {
      type: [Number, String],
      default: PSData?.investmentFunds?.minimumFundingAmount,
    },
    availableToInvest: {
      type: [Number, String],
      default: PSData?.investmentFunds?.availableToInvest,
    },
    predefinedAmounts: {
      type: Array,
      default: () => PREDEFINED_AMOUNTS,
    },
  },

  data: function() {
    return {
      errors: "",
      serverErrors: "",
      predefinedAmount: "",
      customAmount: "",
      isLoading: false,
    };
  },
  computed: {
    hasErrors() {
      return this.errors.trim() !== "" || this.serverErrors.trim() !== "";
    },
    formattedAvailableCash() {
      return formatMoney(this.availableToInvest);
    },
    minimumAmountMessage() {
      return `Minimum ${formatMoney(this.minAmount)}`;
    },
    addFundsUrl() {
      return "/transactions/ach_deposits/new";
    },
    customAmountShown() {
      return this.predefinedAmount === "custom";
    },
    amount() {
      return this.customAmountShown ? this.customAmount : this.predefinedAmount;
    },
    filteredPredefinedAmounts() {
      let result = this.predefinedAmounts.filter(pa => pa < +this.availableToInvest);

      return [...new Set([this.availableToInvest, ...result])];
    },
    cashAvailable() {
      return +this.availableToInvest > 0;
    },
    nextClosingDate() {
      return PSData?.investmentFunds?.nextClosingDate;
    },
    nextInvestmentCutoffDate() {
      return PSData?.investmentFunds?.nextInvestmentCutoffDate;
    },
    closedToNewInvestments() {
      return PSData?.investmentFunds?.closedToNewInvestments;
    },
    isLoadingOrClosedToNewInvestments() {
      return this.closedToNewInvestments || this.isLoading;
    },
    waitlistUrl() {
      return PSData?.investmentFunds?.waitlistUrl;
    },
  },
  watch: {
    amount(_new, _old) {
      this.serverErrors = "";
    },
  },
  mounted() {
    PS.globalBus.$on(OPEN_MODAL_EVENT, this.openModal);

    if (this.instantOpen()) {
      this.openModal();
    }
  },
  methods: {
    addFunds() {
      if (this.customAmountShown) {
        const amount = unmaskNumber(this.amount);

        this.validateAmount();
        if (this.errors) {
          return false;
        }
      }

      this.submitInvestment();
    },
    validateAmount() {
      const amount = unmaskNumber(this.amount);
      const minAmount = unmaskNumber(this.minAmount);
      const availableCash = Number(unmaskNumber(this.availableToInvest));

      if (amount === null) {
        this.errors = "This field is required.";
      } else if (amount > availableCash) {
        this.errors = "You do not have enough investable cash.";
      } else if (amount < minAmount && availableCash > minAmount) {
        this.errors = `Please enter a value between ${formatMoney(
          minAmount
        )} and ${this.formattedAvailableCash}.`;
      } else if (amount < minAmount) {
        this.errors = `A minimum investment of ${formatMoney(
          minAmount
        )} is required at this time.`;
      } else {
        this.errors = "";
      }
    },
    closeModal() {
      $(this.$el).modal("hide");
    },
    openModal() {
      this.predefinedAmount = this.availableToInvest;
      this.errors = "";
      this.isLoading = false;
      $(this.$el).modal("show");
    },
    instantOpen() {
      return window.location.pathname.endsWith("investment_funds")
          && window.location.hash === "#invest";
    },
    async submitInvestment() {
      try {
        this.isLoading = true;

        const payload = {
          amount: unmaskNumber(this.amount),
        };

        await Ajax.post("/investment_funds/funding.json", payload);

        this.closeModal();
        window.location.reload();
      } catch ({ response, data }) {
        let errorMessage = "";

        if (data && data.errors) {
          errorMessage = data.errors;
        } else {
          errorMessage = "There was an unknown error";
        }

        this.serverErrors = errorMessage;
        this.isLoading = false;
      }
    },
  },
};
</script>
