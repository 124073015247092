<template>
  <banner-v3-slider
    class="account-stats-slider"
    :number-of-slides="numberOfSlides"
    :pager="pager"
    :slide-width="slideWidth"
    container-class="d-block">
    <li>
      <p>
        Total Account Value
        <span class="account-stats-slider-amount">{{ value | money(2) }}</span>
      </p>
    </li>
    <li>
      <p>
        Total Earned
        <a v-popover="{ placement: 'top' }"
           href="#"
           class="tooltip-toggle">
          <i class="fa fa-question-circle" />
        </a>
        <span class="d-none">
          {{ totalEarnedTooltip }}
        </span>
        <span class="account-stats-slider-amount">
          {{ totalEarned | money(2, { plus: true }) }}
        </span>
      </p>
    </li>
    <li class="divider">
      <p>
        In Loans
        <span class="account-stats-slider-amount">
          {{ loansPrincipal | money(2) }}
        </span>
      </p>
    </li>
    <li class="divider">
      <p>
        In Pocket
        <a v-if="savingsRequireAgreement"
           :href="savingsPath"
           class="account-info-link">Learn</a>
        <a v-else
           :href="savingsPath"
           class="account-info-link">
          Manage
        </a>
        <span class="account-stats-slider-amount">
          {{ savingsPrincipal | money(2) }}
        </span>
      </p>
    </li>
    <li class="divider">
      <p>
        In Portfolio
        <a v-if="!investmentFundsEnabled"
           :href="investmentFundsWaitlistUrl"
           class="account-info-link">Learn More</a>
        <a v-else-if="investmentFundsRequireAgreement"
           class="account-info-link"
           :href="investmentFundsPath">Learn</a>
        <template v-else>
          <a
            :href="investmentFundsPath"
            class="account-info-link"
            @click="showInvestFundModal($event)">
            Invest
          </a>
          <span class="account-stats-slider-amount">
            {{ investmentFundPrincipal | money(2) }}
          </span>
        </template>
      </p>
    </li>
    <li>
      <p>
        Investable Cash
        <span class="account-stats-slider-amount">{{ cash | money(2) }}</span>
      </p>
    </li>
  </banner-v3-slider>
</template>
<script>
import BannerV3Slider from "components/banner_v3_slider";

export default {
  name: "AccountStatsSlider",
  components: { BannerV3Slider },
  props: {
    cash: { type: [Number, String], required: true },
    investmentFundPrincipal: { type: [Number, String], default: null },
    loansPrincipal: { type: [Number, String], default: null },
    savingsPrincipal: { type: [Number, String], default: null },
    savingsRequireAgreement: Boolean,
    totalEarned: { type: [Number, String], required: true },
    value: { type: [Number, String], required: true },
  },
  data() {
    return {
      investmentFundsEnabled: PSData.investmentFunds.enabled,
      investmentFundsPath: PSData.investmentFunds.path,
      investmentFundsRequireAgreement: PSData.investmentFunds.requireAgreement,
      investmentFundsWaitlistUrl: PSData.investmentFunds.waitlistUrl,
      savingsPath: PSData.savings.path,
      windowWidth: this.getWindowWidth(),
    };
  },
  computed: {
    sliderConfig() {
      let numberOfSlides;
      let slideWidth = 1000;
      let pager = true;

      // coordinated with account-stats-slider bx-wrapper padding ($screen-lg-min)
      if (this.windowWidth > 1200) {
        numberOfSlides = 6;
        pager = false;
      } else if (this.windowWidth > 768) {
        numberOfSlides = 4;
      } else if (this.windowWidth > 576) {
        numberOfSlides = 3;
      } else {
        numberOfSlides = 1;
        slideWidth = 0;
      }

      return { numberOfSlides, pager, slideWidth };
    },
    numberOfSlides() {
      return this.sliderConfig.numberOfSlides;
    },
    pager() {
      return this.sliderConfig.pager;
    },
    slideWidth() {
      return this.sliderConfig.slideWidth;
    },
    totalEarnedTooltip() {
      return (
        "Total Earned is the sum of all interest payments, fee payments, charge-offs, " +
          "and gains on sales received for all your investments."
      );
    },
    newDepositPath() {
      return "/transactions/ach_deposits/new";
    },
  },
  /*
   * Make window width reactive, so slider can be
   * reconfigured.
   */
  mounted() {
    window.onresize = () => {
      this.windowWidth = this.getWindowWidth();
    };
  },
  methods: {
    getWindowWidth() {
      return $(window).width();
    },
    showInvestFundModal(e) {
      this.showModal("openInvestmentFundsModal", e);
    },
    showModal(globalEventName, event) {
      PS.globalBus.$emit(globalEventName);
      event.preventDefault();
    },
  },
};
</script>
