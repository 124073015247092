import AutoInvestmentsIndicator from "components/auto_investments/indicator";
import AutoInvestmentsSetup from "components/auto_investments/setup";
import LearningResourcesSlider from "components/learning_resources_slider";
import MarketingBanner from "components/marketing_banner";
import PromoBanner from "components/promo/banner";
import BankruptcyClaimBanner from "components/bankruptcy_claim_banner";
import BannerV3Slider from "components/banner_v3_slider";

class AutoInvestmentController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        AutoInvestmentsSetup,
        AutoInvestmentsIndicator,
        LearningResourcesSlider,
        MarketingBanner,
        PromoBanner,
        BankruptcyClaimBanner,
        BannerV3Slider,
      },
      data: function() {
        return {};
      },
    });
  }
}

window.PS.Controllers.AutoInvestmentController = AutoInvestmentController;
PS.Components.register(AutoInvestmentController, { applyOnlyTo: ".v-auto-investment-controller" });
