import InvestmentFundsModal from "components/investment_funds/modal";

class InvestmentFundsModalController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: { InvestmentFundsModal },
    });
  }
}

window.PS.Controllers.InvestmentFundsModalController = InvestmentFundsModalController;
PS.Components.register(InvestmentFundsModalController, { applyOnlyTo: ".v-investment-funds-modal" });
