class HeaderOverlay {
  onDomReady($element) {
    $element.on("show.bs.collapse", function() {
      $(".navbar-overlay").removeClass("hidden");
    });
    $element.on("hidden.bs.collapse", function() {
      $(".navbar-overlay").addClass("hidden");
    });
  }
}

PS.Components.register(HeaderOverlay, { applyOnlyTo: ".main-header-inner" });
window.PS.Components.HeaderOverlay = HeaderOverlay;
