import BuyerBids from "components/secondary_market/buyer_bids";

class BuyerBidsController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        BuyerBids,
      },
      data: {},
    });
  }
}

window.PS.BuyerBidsController = BuyerBidsController;
PS.Components.register(BuyerBidsController, { applyOnlyTo: ".v-buyer-bids" });
