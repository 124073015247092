<template>
  <mfe-connect :service-url="serviceUrl"
               :application-name="applicationName"
               @ready="mountApp">
    <div ref="entryPoint" />
  </mfe-connect>
</template>

<script>
import MfeConnect from "./../mfe_connect";

export default {
  name: "InvestmentFundsPromissoryNote",
  components: {
    MfeConnect,
  },
  data() {
    return {
      serviceUrl: PSData.investmentFunds && PSData.investmentFunds.uiUrl,
      applicationName: "ui",
    };
  },
  methods: {
    mountApp() {
      PromissoryNoteApp.mount({
        element: this.$refs.entryPoint,
      });
    },
  },
};
</script>
