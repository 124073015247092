$(() => {
  const toArray = (nodeList) => {
    const els = [];

    for (var i = 0; i < nodeList.length; i++) {
      els.push(nodeList[i]);
    }

    return els;
  };

  const querySelectorAll = (selector) => toArray(document.querySelectorAll(selector));

  // eslint-disable-next-line no-return-assign
  const secureLink = (node) => node.rel = "noopener noreferrer";
  const needsSecuring = (node) => node.nodeName === "A" && node.target === "_blank";

  const safely = (fn) => {
    try { fn(); } catch (err) { console.log("Error: ", err); }
  };

  // First pass to secure existing links
  querySelectorAll("a").filter(needsSecuring).forEach(secureLink);

  // Observer to secure dynamically-added links
  safely(() => {
    if (typeof MutationObserver !== "undefined") {
      new MutationObserver((mutations) => {
        mutations.forEach(({ addedNodes }) => {
          toArray(addedNodes).filter(needsSecuring).forEach(secureLink);
        });
      })
      .observe(document.body, { childList: true, subtree: true });
    }
  });
});
