<template>
  <div class="control-wrapper">
    <label class="control-label">Select an offering</label>
    <select v-model="modalStoreData.selectedOfferingGlobalId"
            class="custom-select offering-select">
      <option v-for="offering in modalStoreData.offerings"
              :key="offering.globalId"
              :value="offering.globalId">
        {{ offering.name }}
      </option>
    </select>
  </div>
</template>

<script>
import ModalStore from "./store";

export default {
  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },
};
</script>
