import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

document.addEventListener("DOMContentLoaded", () => {
  if (window.__ps_test_mode) {
    window.bugsnag = {
      notify() {},
    };
  } else {
    window.bugsnag = Bugsnag.start({
      apiKey: PSData.global.bugsnagApiKey,
      releaseStage: PSData.global.bugsnagReleaseStage,
      notifyReleaseStages: ["production", "staging"],
      plugins: [new BugsnagPluginVue()],
      user: getBugsnagUser(),
      beforeSend: function(report) {
        report.updateMetaData("instance", {
          app_name: PSData.global.appName,
        });
      },
      onError: filterError,
    });
  }
});

function getBugsnagUser() {
  if (!PSData.user.id) {
    return null;
  }

  return {
    id: PSData.user.id,
    name: `${PSData.user.properties.firstName} ${PSData.user.properties.lastName}` ,
    email: PSData.user.properties.email,
  };
}

// Discarding errors:
// https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#discarding-events
//
const PASS_ERROR = true;
const DISCARD_ERROR = false;

const STACKTRACE_SAMPLES_TO_DISCARD = [
  "safari-web-extension",
  "user-script",
  "maps.googleapis.com",
  "connect.facebook.net"
];

function filterError(event) {
  if (event.errors.length > 1) {
    return PASS_ERROR;
  }

  return filterErrorByStacktrace(event.errors[0]);
}

function filterErrorByStacktrace(error) {
  const topStacktraceLine = error.stacktrace[0].file;

  for (const sample of STACKTRACE_SAMPLES_TO_DISCARD) {
    if (topStacktraceLine.match(sample)) {
      return DISCARD_ERROR;
    }
  }

  return PASS_ERROR;
}
