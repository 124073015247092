<template>
  <div
    v-cloak
    class="invest-modal modal fade"
    aria-modal="true">
    <div class="modal-dialog">
      <loading-icon v-if="isLoading" />

      <div v-else
           class="modal-content">
        <close-icon />

        <div class="modal-body">
          <div class="loan-invest">
            <invest-loan-card :loan="loan" />

            <div class="loan-invest-content">
              <div class="loan-invest-content-loan-title">
                {{ loan.loanTitle }}
              </div>

              <incomplete-profile v-if="user.hasIncompleteProfile" />

              <filled-loan v-else-if="loan.isFilled" />

              <order-details v-else-if="isOrderCreated"
                             :amount="amount" />

              <template v-else>
                <investment-options-with-pocket v-if="isInvestFromPocketEnabled"
                                                :available-investment="loan.availableInvestment"
                                                :available-cash="user.availableCash"
                                                :pocket-principal="user.pocketClassicPrincipal"
                                                @funds-source-changed="onFundsSourceChange($event)"
                                                @funds-source-amount-changed="
                                                  onFundsSourceAmountChange($event)" />
                <investment-options v-else
                                    :available-investment="loan.availableInvestment"
                                    :available-cash="user.availableCash" />

                <yield-bump v-if="user.hasYieldBump"
                            :yield-bump-rate="user.yieldBumpRate" />

                <template v-if="areInsufficientFunds">
                  <insufficient-funds />
                </template>

                <template v-else>
                  <investment-amount
                    ref="investmentAmount"
                    v-model="amount"
                    :disabled="loan.isFixedAmount"
                    :errors="errors"
                    :label="'Investment Amount'" />

                  <submit-button :is-submiting="isSubmiting"
                                 :disabled="isSubmitDisabled"
                                 @submit="submitInvestment()" />
                </template>
              </template>
            </div>
          </div>

          <invest-disclosure />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ajax from "services/ajax";
import pick from "lodash/pick";

import CloseIcon from "components/invest/close_icon";
import FilledLoan from "./filled_loan";
import IncompleteProfile from "./incomplete_profile";
import InsufficientFunds from "./insufficient_funds";
import InvestDisclosure from "components/invest/disclosure";
import InvestLoanCard from "components/invest/loan_card";
import InvestmentAmount from "./investment_amount";
import InvestmentOptions from "./investment_options";
import InvestmentOptionsWithPocket from "./investment_options_with_pocket";
import LoadingIcon from "components/invest/loading_icon";
import OrderDetails from "./order_details";
import SubmitButton from "./submit_button";
import YieldBump from "./yield_bump";

import NumberFormatter from "formatters/number_formatter";
import Segment from "services/segment";

export const { formatMoney, formatPercent, formatNumber, unmaskNumber } = NumberFormatter;

const DEFAULT_DATA = {
  loan: {
    ...LOAN_DATA,
  },
  user: {
    ...USER_DATA,
  },
};

const LOAN_DATA = {
  loanId: undefined,
  loanTitle: "",
  imageUrl: "",
  remainingTerm: 0,
  ltv: 0,
  percentageFunded: 0,
  amountLeft: 0,
  isFilled: false,
  bumpedRate: 0,
  availableInvestment: 0,
  isFixedAmount: false,
};

const USER_DATA = {
  availableCash: 0,
  pocketClassicPrincipal: 0,
  hasYieldBump: false,
  yieldBumpRate: 0,
  hasIncompleteProfile: false,
};

const EVENT = "openInvestModal";
const TRACK_INVEST_MODAL_CATEGORY = "Invest Modal";
const MINIMUM_INVESTMENT_AMOUNT = 1000;

export default {
  name: "InvestModal",

  components: {
    CloseIcon,
    FilledLoan,
    IncompleteProfile,
    InsufficientFunds,
    InvestDisclosure,
    InvestLoanCard,
    InvestmentAmount,
    InvestmentOptions,
    InvestmentOptionsWithPocket,
    LoadingIcon,
    OrderDetails,
    SubmitButton,
    YieldBump,
  },

  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      isSubmitDisabled: false,
      amount: "",
      errors: "",
      orderNumber: undefined,
      fundsSource: null,
      fundsSourceAmount: null,
      isInvestFromPocketEnabled: false,
      loan: {
        ...LOAN_DATA,
      },
      user: {
        ...USER_DATA,
      },
    };
  },

  computed: {
    areInsufficientFunds() {
      return this.isInsufficientAmount(this.user.availableCash) &&
        this.isInsufficientAmount(this.user.pocketClassicPrincipal);
    },

    isOrderCreated() {
      return !!this.orderNumber;
    },
  },

  watch: {
    amount() {
      this.validateAmount();
    },

    fundsSource() {
      this.validateAmount();
    },
  },

  mounted() {
    PS.globalBus.$on(EVENT, url => {
      const modal = $(this.$el);

      modal.modal("show");
      modal.on("hide.bs.modal", this.onHideModal);
      this.loadLoanData(url);
    });
  },

  methods: {
    isInsufficientAmount(amount) {
      const minAmount = this.loan.isFixedAmount ?
        this.loan.availableInvestment : MINIMUM_INVESTMENT_AMOUNT;

      return amount < minAmount;
    },

    onHideModal(event) {
      if (this.isOrderCreated) {
        event.preventDefault();

        if (!this.isLoading) {
          this.closeAndReload();
        }
      }

      this.isLoading = true;
      Object.assign(this, DEFAULT_DATA);
    },

    closeAndReload() {
      location.reload();
    },

    loadLoanData(url) {
      Ajax.get(url).then(data => {
        Object.assign(this.loan, pick(data, Object.keys(LOAN_DATA)));
        Object.assign(this.user, pick(data, Object.keys(USER_DATA)));

        this.isInvestFromPocketEnabled = data.isInvestFromPocketEnabled;

        if (!this.isInvestFromPocketEnabled) {
          this.fundsSourceAmount = this.user.availableCash;
        }

        if (data.isFixedAmount) {
          this.amount = formatNumber(data.availableInvestment, 2);
        } else {
          this.amount = "";
        }

        this.isLoading = false;
        this.trackOpen();
      });
    },

    submitInvestment() {
      this.validateOnSubmit();

      if (this.errors) {
        this.focusInvestmentAmount();

        return false;
      }

      if (this.isSubmiting) { return false; }

      this.isSubmiting = true;
      this.trackSubmit();

      const amount = NumberFormatter.unmaskNumber(this.amount);
      const url = "/orders";

      Ajax.post(url, {
        loan_id: this.loan.loanId,
        amount: amount,
        ...(this.isInvestFromPocketEnabled && { funds_source: this.fundsSource }),
      })
        .then(data => {
          if (data.errors) {
            this.errors = data.errors;
          } else {
            this.orderNumber = data.orderNumber;
          }
        })
        .finally(() => {
          this.isSubmiting = false;
        });
    },

    validateOnSubmit() {
      const amount = unmaskNumber(this.amount);

      if (this.loan.isFixedAmount) { return; }

      if (amount === null) {
        this.errors = "This field is required";
      } else if (amount < MINIMUM_INVESTMENT_AMOUNT) {
        this.errors = `Minimum investment amount is ${formatMoney(MINIMUM_INVESTMENT_AMOUNT, 2)}`;
      } else {
        this.errors = "";
      }
    },

    focusInvestmentAmount() {
      this.$refs.investmentAmount.$refs.input.focus();
    },

    validateAmount() {
      if (this.isLoading) { return; }

      const amount = unmaskNumber(this.amount);

      if (amount > this.fundsSourceAmount) {
        this.errors = "Amount exceeds investable funds";
      } else if (amount > this.loan.availableInvestment) {
        this.errors = "Amount exceeds available investment";
      } else {
        this.errors = "";
      }

      if (this.errors) {
        this.isSubmitDisabled = true;
      } else {
        this.isSubmitDisabled = false;
      }
    },

    onFundsSourceChange(fundsSource) {
      this.fundsSource = fundsSource;
    },

    onFundsSourceAmountChange(amount) {
      this.fundsSourceAmount = amount;
    },

    trackOpen() {
      Segment.track("pageView", { category: TRACK_INVEST_MODAL_CATEGORY, label: "Open Modal" });
    },

    trackSubmit() {
      Segment.track("click", { category: TRACK_INVEST_MODAL_CATEGORY, label: "Click Submit" });
    },
  },
};
</script>
