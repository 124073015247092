import AutoDepositsStatus from "components/manage/auto_deposits/status.vue";
import BankAccountBalance from "components/manage/bank_account_balance.vue";
import EarningsChart from "components/charts/earnings_chart";
import SavingsAutoDepositsStatus from "components/manage/savings/auto_deposits/status.vue";

$(() => {
  new Vue({
    el: document.querySelector(".v-manage-user-account-controller"),
    components: {
      BankAccountBalance,
      AutoDepositsStatus,
      SavingsAutoDepositsStatus,
      EarningsChart,
    },
    computed: {
      thisYearEarnings() {
        return PSData.thisYearEarnings;
      },
    },
  });
});
