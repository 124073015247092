<template>
  <form class="pocket-form"
        :class="{ 'has-error': hasErrors }"
        @submit.prevent.capture="onFormSubmit()">
    <label>Choose Amount</label>
    <div class="pocket-modal-invest-options">
      <div v-for="(option) in radioOptions"
           :key="`predefined-amount-${option.value}`"
           class="form-check">
        <input :id="`invest-option-${option.value}-bank`"
               v-model="bankStoreData.predefinedAmount"
               class="form-check-input"
               type="radio"
               :value="option.value"
               name="amount">
        <label class="form-check-label"
               :for="`invest-option-${option.value}-bank`">
          {{ option.label }}
        </label>
      </div>
    </div>
    <label v-if="serverErrors && !customAmountShown"
           class="error control-label"
           v-html="serverErrors">{{ serverErrors }}</label>

    <template v-if="customAmountShown">
      <div class="control-wrapper custom-amount">
        <div class="form-group currency required"
             :class="{ 'has-error': errors }">
          <label class="control-label"
                 for="amount">Amount</label>
        </div>

        <div class="input-group-wrapper">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              id="amount"
              v-model="customAmount"
              v-mask:currency
              type="text"
              class="form-control currency"
              :class="{ 'error-control': errors }"
              autocomplete="off">
          </div>
          <span class="form-helper-label">{{ minimumAmountMessage }}</span>
          <label v-if="errors"
                 class="error control-label"
                 v-html="errors">{{ errors }}</label>

          <label v-if="serverErrors"
                 class="error control-label"
                 v-html="serverErrors">{{ serverErrors }}</label>
        </div>
      </div>
    </template>

    <div class="form-buttons">
      <button
        type="submit"
        class="btn btn-block btn-primary-cta">
        Review
      </button>

      <button class="btn btn-block"
              data-dismiss="modal">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { formatMoney, unmaskNumber } from "formatters/number_formatter";

import BankStore from "./store";
import ModalStore from "../../store";

const PREDEFINED_AMOUNTS = [10000, 5000, 1000];

export default {
  name: "BankInvestmentForm",

  props: {
    minAmount: {
      type: [Number, String],
      default: PSData.user?.properties?.savingsInvestment?.minimumInvestmentAmount,
    },
  },

  data: function() {
    return {
      errors: "",
      customAmount: "",
      isLoading: false,
      bankStoreData: BankStore.data,
    };
  },

  computed: {
    serverErrors() {
      return this.bankStoreData.serverErrors;
    },

    predefinedAmount() {
      return this.bankStoreData.predefinedAmount;
    },

    hasErrors() {
      return this.errors.trim() !== "" || this.serverErrors.trim() !== "";
    },

    customAmountShown() {
      return this.predefinedAmount === "custom";
    },

    amount() {
      return this.customAmountShown ? this.customAmount : this.predefinedAmount;
    },

    radioOptions() {
      const predefinedOptions = PREDEFINED_AMOUNTS.map((amount) => {
        return { value: amount, label: formatMoney(amount) };
      });

      return [ ...predefinedOptions, { value: "custom", label: "Custom Amount" } ];
    },

    minimumAmountMessage() {
      return `Minimum ${formatMoney(this.minAmount)}`;
    },
  },

  watch: {
    amount(_new, _old) {
      BankStore.cleanServerErrors();
    },
  },

  mounted() {
    if (this.customAmountShown) {
      this.customAmount = this.bankStoreData.amount;
    }

    this.bankStoreData.predefinedAmount ||= this.radioOptions[0].value;
  },

  methods: {
    onFormSubmit() {
      if (this.customAmountShown) {
        this.validate();

        if (this.errors) {
          return false;
        }
      }

      this.bankStoreData.amount = unmaskNumber(this.amount);
      BankStore.cleanServerErrors();
      ModalStore.showOneTimeBankConfirmation();
    },

    validate() {
      const amount = unmaskNumber(this.amount);
      let errorMessage = "";

      if (amount === null) {
        errorMessage =  "This field is required.";
      } else if (amount < this.minAmount) {
        errorMessage = `A minimum investment of ${formatMoney(
          this.minAmount
        )} is required at this time.`;
      }

      this.errors = errorMessage;
    },
  },
};
</script>
