<template>
  <div class="table-responsive">
    <table class="table table-hover positions-table sorted-list"
           :class="tableClass">
      <account-positions-dashboard-list-header
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        :paid-off="paidOff"
        :pending="pending"
        @sorting-changed="changeSorting" />

      <tbody>
        <template v-if="hasPositions">
          <account-positions-dashboard-list-item
            v-for="position in sortedPositions"
            :key="position.psId"
            :position="position" />
        </template>

        <tr v-else
            class="no-positions">
          <td colspan="10">
            None
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AccountPositionsDashboardListHeader from "components/account_positions/dashboard/list_header";
import AccountPositionsDashboardListItem from "components/account_positions/dashboard/list_item";
import PositionsSorter from "models/account_positions/positions_sorter";

export default {
  name: "AccountPositionsDashboardList",
  components: {
    AccountPositionsDashboardListHeader,
    AccountPositionsDashboardListItem,
  },
  props: {
    enableSorting: Boolean,
    paidOff: Boolean,
    pending: Boolean,
    positions: { type: Array, default: null } },
  data() {
    return {
      sortColumn: PSData.sortColumn,
      sortOrder: PSData.sortOrder,
    };
  },
  computed: {
    hasPositions() {
      return this.positions.length > 0;
    },
    sortedPositions() {
      return this.enableSorting ? this.positionsSortedByProperty() : this.positions;
    },
    tableClass() {
      if (this.paidOff) {
        return "past-positions-table";
      } else if (this.pending)
        return "pending-positions-table";
      else {
        return "active-positions-table";
      }
    },
  },
  methods: {
    changeSorting(column, order) {
      if (this.enableSorting) {
        this.sortColumn = column;
        this.sortOrder = order;
      } else {
        this.reloadPageWithSeachParams(column, order);
      }
    },
    positionsSortedByProperty() {
      return PositionsSorter.sort(this.positions, this.sortColumn, this.sortOrder);
    },
    reloadPageWithSeachParams(sort, order) {
      const searchParams = new URLSearchParams({ sort, order }).toString();
      const url = `${window.location.origin}${window.location.pathname}?${searchParams}`;

      PS.Services.LocationService.replace(url);
    },
  },
};
</script>
