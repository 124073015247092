<template>
  <div>
    <div class="positions-header d-none d-md-flex">
      <a
        :href="allInvestmentsPath"
        class="investments-link">
        <i class="fa fa-angle-left fa-lg" />
        &thinsp;Back to All Investments
      </a>
      <slot name="pagination" />
    </div>

    <a
      :href="allInvestmentsPath"
      class="investments-link d-md-none">
      &lt; Back to All Investments
    </a>

    <div class="mobile-pagination row d-flex d-md-none">
      <div class="col-3 text-left">
        <slot name="pagination-prev" />
      </div>
      <div class="col-6 text-center">
        <slot name="pagination-status" />
      </div>
      <div class="col-3 text-right">
        <slot name="pagination-next" />
      </div>
    </div>

    <account-positions-dashboard-section
      :positions="positions"
      :investments-type="investmentsType" />

    <div class="clearfix pagination-footer d-none d-md-block">
      <slot name="pagination-status" />
      <slot name="pagination" />
    </div>

    <div class="mobile-pagination row d-flex d-md-none">
      <div class="col-3 text-left">
        <slot name="pagination-prev" />
      </div>
      <div class="col-6 text-left">
        <slot name="pagination-status" />
      </div>
      <div class="col-3 text-right">
        <slot name="pagination-next" />
      </div>
    </div>
  </div>
</template>

<script>
import AccountPositionsDashboardSection from "components/account_positions/dashboard/section";

export default {
  name: "AccountPositionsDashboardPage",
  components: { AccountPositionsDashboardSection },
  props: {
    investmentsType: { type: String, default: null },
    positions: { type: Array, default: null },
  },
  computed: {
    allInvestmentsPath() {
      return "/positions";
    },
  },
};
</script>
