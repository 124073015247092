<template>
  <div :class="classString">
    <h4>{{ title }}</h4>
    <div class="milestone-wrapper">
      <p v-if="showTruncateLink"
         ref="description"
         :class="{ 'clamp-lines': isTruncated }">
        {{ description }}
      </p>
      <a v-if="showTruncateLink"
         href="#"
         @click.prevent="toggleTruncated">Show More</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ForeclosureMilestone",
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    highlighted: Boolean,
    milestoneClass: { type: String, default: "milestone" },
  },
  data() {
    return { isTruncated: true };
  },
  computed: {
    classString() {
      return this.highlighted ? `${this.milestoneClass} milestone-highlighted` :
        this.milestoneClass;
    },
    showTruncateLink() {
      return !this.highlighted;
    },
  },
  methods: {
    toggleTruncated(event) {
      this.isTruncated = !this.isTruncated;
      event.target.text = this.isTruncated ? "Show More" : "Show Less";
    },
  },
};
</script>
