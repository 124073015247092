$(() => {
  new Vue({
    el: document.querySelector(".v-borrower-controller"),
    data: {
      selectedBorrowerType: undefined,
      hasGuarantor: undefined,
    },
    computed: {
      isIndividual: function() {
        return this.selectedBorrowerType === "individual";
      },
    },
    created: function() {
      this.selectedBorrowerType = $("#borrower_kind").val();
      this.hasGuarantor = $("#borrower_has_guarantor").prop("checked");
    },
    methods: {
      setHasGuarantor: function() {
        this.hasGuarantor = !this.isIndividual;
      },
    },
  });
});
