import LinkPlaidInstantly from "components/bank_accounts/link_plaid_instantly";

$(() => {
  new Vue({
    el: document.querySelector(".v-withdraw-funds-page-controller"),

    components: {
      "link-plaid-instantly": LinkPlaidInstantly,
    },
  });
});
