const SIDEBAR_BOTTOM_CONTROL_CLASS = "sidebar-bottom-control";
const MD_WIDTH = 768;
const SIDEBAR_TOP_OFFSET_WHILE_FIXED = 16;

document.addEventListener("DOMContentLoaded", () => {
  function sidebarPositionControl() {
    if (window.innerWidth >= MD_WIDTH) {
      let disclaimerBottomY = disclaimer.getBoundingClientRect().bottom +
        document.documentElement.scrollTop;
      let sidebarStopPosition = disclaimerBottomY - sidebar.offsetHeight -
        SIDEBAR_TOP_OFFSET_WHILE_FIXED;

      if (window.pageYOffset > sidebarStopPosition) {
        document.body.classList.add(SIDEBAR_BOTTOM_CONTROL_CLASS);
        sidebar.style.top = `-${window.pageYOffset - sidebarStopPosition}px`;
      } else {
        document.body.classList.remove(SIDEBAR_BOTTOM_CONTROL_CLASS);
        sidebar.style.top = "auto";
      }
    } else {
      document.body.classList.remove(SIDEBAR_BOTTOM_CONTROL_CLASS);
      sidebar.style.top = "auto";
    }
  }

  const sidebar = document.querySelector(".sidebar");

  if (!sidebar) { return; }

  const disclaimer = document.querySelector(".loan-disclaimer");

  if (!disclaimer) { return; }

  window.addEventListener("scroll", () => {
    window.requestAnimationFrame(sidebarPositionControl);
  });
});
