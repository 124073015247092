<template>
  <div v-if="acknowledgementRequired"
       class="form-check investment-acknowledgement">
    <input id="investment-acknowledgement-check"
           class="form-check-input"
           type="checkbox"
           @change="$emit('input', $event.target.checked)">
    <label class="form-check-label"
           for="investment-acknowledgement-check">
      I acknowledge by investing that I have read, understand,
      and agree to be bound by the terms of the
      <a target="_blank"
         :href="offeringAgreementUrl">{{ offeringAgreementName }}</a>.
    </label>
  </div>
</template>

<script>
import ModalStore from "../store";

export default {
  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    acknowledgementRequired() {
      return !!this.offeringAgreementName;
    },

    offeringAgreementUrl() {
      return this.selectedOffering.agreementDocumentUrl;
    },

    offeringAgreementName() {
      return this.selectedOffering.agreementDocumentName;
    },

    selectedOffering() {
      return this.modalStoreData.offerings.find(
        o => o.globalId === this.modalStoreData.selectedOfferingGlobalId
      );
    },
  },

  mounted() {
    this.$emit("acknowledgement-required", this.acknowledgementRequired);
  },
};
</script>
