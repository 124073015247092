<template>
  <a :href="url"
     @click="onClick"><slot /></a>
</template>
<script>
  import Segment from "services/segment";

  export default {
    props: {
      url: { type: String, required: true },
      label: { type: String, required: true },
    },
    mounted: function() {
      Segment.track("pageView", { category: "marketingWidget", label: this.label, page: window.document.title });
    },
    methods: {
      onClick: function(event) {
        Segment.track("click", { category: "marketingWidget", label: this.label, page: window.document.title });

        if (window.__ps_test_mode) {
          event.preventDefault();
        }
      },
    },
  };
</script>
