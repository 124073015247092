<template>
  <div class="auto-investments">
    <div class="auto-investments-section">
      <div v-if="message"
           class="alert alert-success"
           role="alert">
        <p>{{ message }}</p>
      </div>

      <div v-if="error"
           class="alert alert-danger"
           role="alert">
        <p>Error! {{ error }}</p>
      </div>

      <auto-investments-indicator />

      <auto-investments-items v-show="settingsVisible"
                              @setup-canceled="hideSettings"
                              @turned-off="turnOff"
                              @settings-updated="showUpdateMessage"
                              @error="showError" />

      <auto-investments-overview v-show="!settingsVisible"
                                 @show-settings="showSettings" />
    </div>
  </div>
</template>

<script>
import AutoInvestmentsIndicator from "components/auto_investments/indicator";
import AutoInvestmentsItems from "components/auto_investments/items";
import AutoInvestmentsOverview from "components/auto_investments/overview";

export default {
  name: "AutoInvestmentsSetup",
  components: {
    AutoInvestmentsItems,
    AutoInvestmentsIndicator,
    AutoInvestmentsOverview,
  },
  data() {
    return {
      settingsVisible: PSData.autoInvestingEnabled,
      message: "",
      error: "",
    };
  },
  methods: {
    showSettings() {
      this.settingsVisible = true;
    },
    hideSettings() {
      this.settingsVisible = false;
    },
    clearErrorMessage() {
      this.error = undefined;
    },
    turnOff() {
      this.clearErrorMessage();
      this.hideSettings();
      this.message = "Automated Investing has been turned off.";
      PS.globalBus.$emit("auto-investing-status-updated", false);
    },
    showUpdateMessage() {
      this.clearErrorMessage();
      this.message = "Automated Investing has been updated.";
      PS.globalBus.$emit("auto-investing-status-updated", true);
    },
    showError(error) {
      this.message = undefined;
      this.error = error;
    },
  },
};
</script>
