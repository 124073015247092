<template>
  <banner-v3>
    <template #title>
      Congrats, you have
      <span>{{ pluralizedYieldBumps }}</span>
    </template>
    <template #image>
      <div class="banner-v3-image banner-v3-image-border incentive-invitation-image" />
    </template>
    <template #content>
      <span aria-label="promo banner content message">
        {{ applyTokensMessage }}
      </span>
      <promo-link url="/invites"
                  label="Yield Bump">
        Learn more
      </promo-link>
    </template>
  </banner-v3>
</template>

<script>
import BannerV3 from "../../banner_v3";
import PromoLink from "components/promo/link";
import StringFormatter from "formatters/string_formatter";

export const { pluralize } = StringFormatter;

export default {
  name: "PromoBannerYieldBump",
  components: { BannerV3, PromoLink },
  props: {
    tokensCount: { type: String, default: null },
  },
  data() {
    return {};
  },
  computed: {
    pluralizedYieldBumps() {
      return `${this.tokensCount} Yield ${this.multipleTokens ? "Bumps" : "Bump"}!`;
    },
    applyTokensMessage() {
      const pronoun = this.multipleTokens ? "them" : "it";

      return `Apply ${pronoun} to a loan investment to earn an extra 1% yield.`;
    },
    multipleTokens() {
      return parseInt(this.tokensCount, 10) > 1;
    },
  },
};
</script>
