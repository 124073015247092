<template>
  <table class="auto-deposits-show table table-striped">
    <tbody>
      <tr>
        <th>Deposit Amount</th>
        <td class="deposit-amount">
          {{ formattedAmount}}
        </td>
      </tr>
      <tr>
        <th>Frequency</th>
        <td class="deposit-frequency">
          {{ formattedFrequency }}
        </td>
      </tr>
      <tr>
        <th>Day of the Month</th>
        <td v-if="displayDayOfMonth"
            class="deposit-day-of-month">
          {{ autoDepositsSettings.dayOfMonth }}
        </td>
        <td v-else
            class="deposit-day-of-month">
          N/A
        </td>
      </tr>
      <tr>
        <th>Day of the Week</th>
        <td v-if="displayDayOfWeek"
            class="deposit-day-of-week">
          {{ autoDepositsSettings.dayOfWeek }}
        </td>
        <td v-else
            class="deposit-day-of-week">
          N/A
        </td>
      </tr>
      <tr>
        <th>Next Deposit Date</th>
        <td class="deposit-next-date">
          {{ autoDepositsSettings.depositDate }}
        </td>
      </tr>
      <tr>
        <th>Active?</th>
        <td v-if="active"
            class="deposit-active deposit-enabled">
          <span class="badge badge-success">Yes</span>
        </td>
        <td v-else>
          <span class="badge badge-warning deposit-enabled">No</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatMoney } from "formatters/number_formatter";

export default {
  name: "SavingsAutoDepositsShow",
  data() {
    return {
      autoDepositsFrequencies: PSData.savingsAutoDeposits.frequencies,
      autoDepositsSettings: PSData.savingsAutoDeposits.settings,
    };
  },
  computed: {
    active() {
      return this.autoDepositsSettings.enabled === true;
    },
    displayDayOfMonth() {
      return this.autoDepositsSettings.frequency === "monthly";
    },
    displayDayOfWeek() {
      return (this.autoDepositsSettings.frequency === "weekly" || this.autoDepositsSettings.frequency === "every_other_week");
    },
    formattedAmount() {
      return formatMoney(this.autoDepositsSettings.amount);
    },
    formattedFrequency() {
      switch (this.autoDepositsSettings.frequency) {
        case "weekly": return this.autoDepositsFrequencies.weekly.name;
        case "monthly": return this.autoDepositsFrequencies.monthly.name;
        case "every_other_week": return this.autoDepositsFrequencies.everyOtherWeek.name;
        case "monthly_1st_15th": return this.autoDepositsFrequencies.monthly1st15th.name;
      }
    },
  },
};
</script>
