import RememberDeviceTooltip from "components/two_factor_auth/remember_device_tooltip";
import SendVerificationCodeButton from "components/two_factor_auth/send_verification_code_button";

$(() => {
  new Vue({
    el: document.querySelector(".v-two-factor-auth-controller"),
    components: {
      RememberDeviceTooltip,
      SendVerificationCodeButton,
    },
  });

  $("[autofocus]").focus();
});
