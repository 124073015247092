<template>
  <banner-v3 v-if="shouldRender"
             slider-class="bx-wrapper banner-v3-slider-wrapper">
    <template #title>
      <span aria-label="banner title">
        {{ title }}
      </span>
    </template>
    <template #image>
      <img class="banner-v3-image"
           :src="require(`../../assets/images/icons/icon-banner.svg`)">
    </template>
    <template #content>
      <div v-html="content" />
    </template>
  </banner-v3>
</template>

<script>
import Segment from "services/segment";
import BannerV3 from "./banner_v3";

const DEFAULT_LINK_TRACKING_LABEL = "banner clicked";
const DEFAULT_PAGE_TRACKING_LABEL = "banner displayed";

export default {
  components: { BannerV3 },
  props: {
    content: { type: String, default: null },
    isVisible: { type: Boolean, default: false },
    title: { type: String, default: null },
    tracking: { type: Boolean, default: true, required: false },
  },
  computed: {
    shouldRender() {
      return this.isVisible && this.title && this.content;
    },
  },
  mounted: function() {
    if (this.tracking && this.shouldRender) {
      this.trackPageView();
      this.trackLinks();
    }
  },
  methods: {
    trackPageView() {
      Segment.track("pageView", { category: "Marketing Banner", label: DEFAULT_PAGE_TRACKING_LABEL });
    },
    trackLinks() {
      if (!this.isVisible) return;

      this.$el.querySelectorAll("a").forEach(link => {
        link.addEventListener("click", this.trackPromotionalBannerClick);
      });
    },
    trackPromotionalBannerClick(event) {
      Segment.track("click", { category: "Marketing Banner", label: DEFAULT_LINK_TRACKING_LABEL });

      if (window.__ps_test_mode) {
        event.preventDefault();
      }
    },
  },
};
</script>
