$(() => {
  let parentNode;

  $(document).on("show.bs.modal",".cancel-pledge-modal", function() {
    parentNode = $(this).parent();
    $(this).detach().prependTo("body");
  });

  $(document).on("hidden.bs.modal",".cancel-pledge-modal", function() {
    $(this).detach().prependTo(parentNode);
    parentNode = null;
  });
});
