export default class BuyoutOfferService {
  static accept(buyoutOffer) {
    if (!buyoutOffer) { return Promise.resolve({ errors: ["No offer"] }); }

    const requestBody = { "bid_id": buyoutOffer.bidId };

    return PS.Services.Ajax.post("/buyout_offers/accept", requestBody);
  }

  static reject(buyoutOffer, minBuyoutAmount = null) {
    if (!buyoutOffer) { return Promise.resolve({ errors: ["No offer"] }); }

    const requestBody = {
      "bid_id": buyoutOffer.bidId,
      "position_amount": buyoutOffer.outstandingPrincipal,
      "min_buyout_amount": minBuyoutAmount,
    };

    return PS.Services.Ajax.post("/buyout_offers/reject", requestBody);
  }
}
