// Error reporting
import "bugsnag_config";

// Polyfills
import "console-polyfill";

import USP from "url-search-params";
window.URLSearchParams = window.URLSearchParams || USP;

import "whatwg-fetch";

// Libs
import "vue/dist/vue";
import "vue_config";
import "datejs";
import "raphael/raphael";
import "morris.js/morris.js";

// jQuery and jQuery plugins
import "jquery";
import "jquery-ui/ui/widgets/datepicker";
import "inputmask/dist/jquery.inputmask.bundle";
import "jquery-validation";
import "jquery-validate.patches";
import "bootstrap/dist/js/bootstrap";
// For IE9 since it can't handle placeholder text
import "jquery-placeholder";
import "popper.js/dist/esm/popper";
import "peerstreet-bxslider/dist/jquery.bxslider";
import "geocomplete";
import "s3_direct_upload.js.coffee";

// Old browser notifications
import "browser_update";

// Rails
import "rails_ujs";

// Analytics
import "analytics";
import "modals";

// Vue slider component
import VueSlider from "vue-slider-component";
Vue.component("VueSlider", VueSlider);

////////////////////////////////////////////////////////////////////////////////
// Application code
////////////////////////////////////////////////////////////////////////////////
import "application.new";

import "datetime-moment";
import "peerstreet-masks.js.coffee";
import "peerstreet-money.js";
import "datatables";
import "peerstreet-table";
import "peerstreet-validate-forms.js";
import "cart.js.coffee";
import "global.js.coffee";
import "google.maps.js.coffee";
import "invite.coffee";
import "secure_external_windows";
import "careers";

// Rails
import "tooltips.js.coffee";
