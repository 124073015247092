import every from "lodash/every";

$(() => {
  new Vue({
    el: document.querySelector(".privacy-section-controller"),
    data() {
      return {
        isSubmitDisabled: true,
      };
    },
    mounted() {
      this.allCheckboxes().forEach(checkbox => {
        $(checkbox).change(this.updateSubmitDisabledState);
      });
    },
    methods: {
      allCheckboxes() {
        return Array.from(this.$el.querySelectorAll("input[type=checkbox]"));
      },
      updateSubmitDisabledState() {
        this.isSubmitDisabled = !every(this.allCheckboxes(), ({ checked }) => checked);
      },
    },
  });
});
