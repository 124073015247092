<template>
  <div class="recurring-deposit-summary">
    <Total />

    <div class="form-buttons recurring-view-buttons">
      <button
        class="btn-info-cta"
        @click="turnOffDeposit"
        @disabled="blockUI">
        <span v-if="!blockUI">Turn Off</span>
        <span v-if="blockUI">Turning Off...</span>
      </button>

      <button
        class="btn-primary-cta"
        @click="enterEditMode">
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import { DAYS_OF_WEEK } from "services/auto_deposits/calendar_service";
import { formatMoney } from "formatters/number_formatter";
import { ordinalize } from "filters";

import CalendarService from "services/auto_deposits/calendar_service";
import Total from "./summary.vue";

import RecurringStore from "./store";

export default {
  name: "RecurringDepositSummary",

  components: {
    Total,
  },

  data() {
    return {
      blockUI: false,
      recurringStoreData: RecurringStore.data,
    };
  },

  computed: {
    settings() {
      return this.recurringStoreData.settings;
    },

    frequencies() {
      return this.recurringStoreData.frequencies;
    },

    settingLineItems() {
      const optionalLineItems = [];

      if (this.settings.showWeekly || this.settings.showEveryOtherWeek) {
        let startDate = CalendarService.convertToShortWithYear(this.settings.startDate);

        optionalLineItems.push({ label: "Day of Week", value: DAYS_OF_WEEK[this.settings.dayOfWeek - 1] });
        optionalLineItems.push({ label: "Start Date", value: startDate });
      }

      if (this.settings.showMonthly) {
        optionalLineItems.push({ label: "Day of Month", value: ordinalize(this.settings.dayOfMonth) });
      }

      return [
        { label: "From", value: `Bank Account ***${this.store.accountNumber}` },
        { label: "Frequency", value: this.frequencies[this.settings.frequency].name },
        ...optionalLineItems,
        { label: "Amount", value: formatMoney(this.settings.amount) }
      ];
    },
  },

  methods: {
    turnOffDeposit() {
      this.blockUI = true;

      RecurringStore.makeUpdateRecurringSettingsRequest({ resource: { enabled: false } }, (settingsFromServer) => {
        RecurringStore.updateRecurringSettingsFromServer(settingsFromServer);

        this.blockUI = false;
      });
    },

    enterEditMode() {
      this.recurringStoreData.inEditMode = true;
    },
  },
};
</script>
