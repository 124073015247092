$(() => {
  new Vue({
    el: document.querySelector(".v-loan-details-mobile-controller"),
    mounted: function() {
      let loanSlider = $(this.$el).find(".loan-slider");

      loanSlider.bxSlider({
        speed: 400,
        useCSS: false,
      });
    },
  });
});
