<template>
  <div class="incomplete-profile-message">
    <h4>Complete your profile to continue</h4>
    <p>
      You need to complete your profile before you can invest on PeerStreet.
    </p>
    <a href="/profile/personal_info"
       class="btn-primary-cta btn-block">
      Complete Profile
    </a>
  </div>
</template>

<script>
export default {
};
</script>
