<template>
  <div v-if="showTable"
       id="activeBidsTable">
    <h3>Active Bids</h3>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">
            Title
          </th>
          <th scope="col">
            Bid %
          </th>
          <th scope="col">
            Max<br>Allocation
          </th>
          <th scope="col">
            Expiration<br>Date
          </th>
          <th scope="col">
            Offers<br>Accepted
          </th>
          <th scope="col">
            Principal<br>Amount
          </th>
          <th scope="col">
            Purchase<br>Amount
          </th>
          <th scope="col">
            Pending<br>Settlement
          </th>
          <th scope="col">
            Bid<br>Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="loan in validBidLoans"
            :key="loan.loan_ps_id">
          <td>
            <a :href="loan.loan_path"
               class="link-primary date-input"
               target="_blank">
              {{ loan.loan_title }}
            </a>
          </td>
          <td>{{ loan.secondary_market_bid.offer_percentage | percent }}</td>
          <td>{{ loan.secondary_market_bid.max_allocation | money }}</td>
          <td>{{ loan.secondary_market_bid.expires_at | date }}</td>
          <td>{{ loan.secondary_market_bid.offers_accepted }}</td>
          <td>{{ loan.secondary_market_bid.principal_amount | money }}</td>
          <td>{{ loan.secondary_market_bid.purchase_amount | money }}</td>
          <td>{{ loan.secondary_market_bid.pending_settlement | money }}</td>
          <td>{{ loan.secondary_market_bid.bid_status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { date, money, percent } from "filters";

export default {
  name: "BuyerLoansTable",
  filters: {
    date,
    money,
    percent,
  },
  props: {
    availableLoans: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    showTable() {
      return this.validBidLoans.length > 0;
    },
    loansWithBids() {
      return this.availableLoans.filter( loan => { return loan.secondary_market_bid !== null; } );
    },
    validBidLoans() {
      return this.loansWithBids.filter( loan => {
        const bid = loan.secondary_market_bid;

        return bid.bid_status === "Active" || parseFloat(bid.pending_settlement) > 0.00;
      });
    },
  },
};
</script>
