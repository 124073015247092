<template>
  <form class="two-factor-form"
        @submit="onFormSubmit($event)">
    <div v-if="responseError"
         class="alert alert-danger"
         role="alert">
      {{ responseError }}
    </div>

    <div class="form-group">
      <label for="two-factor-auth-strategy-sms">Authentication Method</label>
      <br>

      <div class="form-check form-check-inline">
        <input id="two-factor-auth-strategy-sms"
               v-model="form.strategy"
               name="two-factor-auth-strategy"
               class="form-check-input"
               type="radio"
               value="sms"
               checked
               required>
        <label class="form-check-label"
               for="two-factor-auth-strategy-sms">
          Text Message
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input id="two-factor-auth-strategy-app"
               v-model="form.strategy"
               name="two-factor-auth-strategy"
               class="form-check-input"
               type="radio"
               value="app"
               required>
        <label class="form-check-label"
               for="two-factor-auth-strategy-app">
          Authenticator App
        </label>
      </div>
    </div>

    <SmsFields v-if="form.strategy == 'sms'" />
    <AppFields v-if="form.strategy == 'app'" />

    <div class="form-group control-wrapper"
         :class="{'has-error': form.errors.verificationCode}">
      <label class="control-label"
             for="user_verification_code">
        Verification code
      </label>
      <input id="user_verification_code"
             v-model="form.verificationCode"
             class="form-control"
             type="text"
             required>
      <label v-show="form.errors.verificationCode"
             class="error control-label">
        {{ form.errors.verificationCode }}
      </label>
    </div>

    <div class="form-buttons">
      <button type="submit"
              class="btn-primary-cta btn-block"
              :disabled="submitDisabled">
        Turn On
      </button>
      <a class="btn btn-block secondary-button"
         href="/user/edit">Cancel</a>
    </div>
  </form>
</template>

<script>
import AppFields from "./form/app_fields";
import SmsFields from "./form/sms_fields";

import Client from "../client";
import form from "./form.js";

export default {
  name: "TwoFactorAuthSettingsForm",

  components: {
    SmsFields,
    AppFields,
  },

  data() {
    return {
      form: form,
      responseError: null,
    };
  },

  computed: {
    submitDisabled() {
      return !this.form.valid;
    },
  },

  mounted() {
    this.form.verificationCode = "";
  },

  methods: {
    async onFormSubmit(event) {
      event.preventDefault();

      this.responseError = null;

      try {
        const responseJSON = await Client.enable(this.form.toParams());

        if (responseJSON.success) {
          this.$emit("enable");
        } else {
          this.form.updateErrors(responseJSON.errors);
        }
      } catch ({ data }) {
        this.responseError = data.error;
      }
    },
  },
};
</script>
