class DiscountedReturnCalculator {
  constructor({
    investmentAmount,
    notePurchaseRate,
    totalLoanAmount,
    totalInvestmentAmount,
    term,
  }) {
    this.investmentAmount = parseFloat(investmentAmount);
    this.notePurchaseRate = parseFloat(notePurchaseRate);
    this.totalInvestmentAmount = parseFloat(totalInvestmentAmount);
    this.totalLoanAmount = parseFloat(totalLoanAmount);
    this.term = parseFloat(term);
  }

  calculateTotalReturn() {
    return this._monthlyReturn() * this.term + this._principalGain();
  }

  calculatePayouts() {
    let payouts = [];

    _.times(this.term, (month) => payouts.push((month * this._monthlyReturn()).toFixed(2)));

    payouts.push(this._lastMonthReturn().toFixed(2));

    return payouts;
  }

  _monthlyReturn() {
    return this.investmentAmount / this.totalInvestmentAmount *
           this._monthlyRate() * this.totalLoanAmount;
  }

  _principalGain() {
    return this.investmentAmount / this.totalInvestmentAmount *
           (this.totalLoanAmount - this.totalInvestmentAmount);
  }

  _monthlyRate() {
    return this.notePurchaseRate / 100 / 12;
  }

  _lastMonthReturn() {
    return this.calculateTotalReturn();
  }
}

window.PS.Models.DiscountedReturnCalculator = DiscountedReturnCalculator;
