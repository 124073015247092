<template>
  <div>
    <button class="btn-primary-cta btn-block"
            type="submit"
            :disabled="isDisabled"
            @click="onSubmit">
      {{ isDisabled ? disabledSubmitText : submitText }}
    </button>
    <div ref="recaptcha" />
  </div>
</template>

<script>
import { validateFormWithoutSubmitting } from "../peerstreet-validate-forms";

export default {
  name: "SubmitWithRecaptcha",
  props: {
    submitText: {
      type: String,
      default: "Submit",
    },
    disabledSubmitText: {
      type: String,
      default: "Submitting...",
    },
  },
  data() {
    return {
      recaptchaKey: PSData.global.recaptchaSiteKey,
      skipRecaptcha: PSData.global.skipRecaptcha,
      isDisabled: false,
      widgetId: null,
    };
  },
  computed: {
    form() {
      return $("#validate-with-captcha");
    },
  },
  mounted() {
    const script = document.createElement("script");

    script.setAttribute("async", "true");
    script.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    this.$el.appendChild(script);
  },
  methods: {
    initRecaptcha() {
      window.grecaptcha.ready(() => {
        if (this.widgetId === null) {
          this.widgetId = window.grecaptcha.render(this.$refs.recaptcha, {
            sitekey: this.recaptchaKey,
            size: "invisible",
            callback: token => {
              this.sendForm();
            },
          });
        } else {
          window.grecaptcha.reset(this.widgetId);
        }

        window.grecaptcha.execute();
      });
    },
    onSubmit(e) {
      e.preventDefault();

      validateFormWithoutSubmitting();

      if (this.form.valid()) {
        // eslint-disable-next-line no-unused-expressions
        this.skipRecaptcha ? this.sendForm() : this.initRecaptcha();
      }
    },
    sendForm() {
      this.isDisabled = true;
      this.form.off("submit").submit();
    },
  },
};
</script>
