<template>
  <mfe-connect
    :service-url="serviceUrl"
    :application-name="applicationName"
    @ready="mountApp">
    <div ref="entryPoint" />
  </mfe-connect>
</template>

<script>
import MfeConnect from "components/mfe_connect";

export default {
  name: "MfeInvestmentFunds",
  components: {
    MfeConnect
  },
  props: {},
  computed : {
    serviceUrl() {
      return PSData.investmentFunds && PSData.investmentFunds.uiUrl;
    },
    applicationName() {
      return "ui";
    }
  },
  methods: {
    mountApp() {
      MFEInvestmentFundManager.mount({
        element: this.$refs.entryPoint,
      });
    },
  },
};
</script>
