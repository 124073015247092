<template>
  <div v-if="showTable"
       id="buyerLoansTable">
    <bid-confirmation-modal :refresh-loans="refreshLoans"
                            :toggle-form="toggleForm" />
    <h3>Loans Available For Bidding</h3>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            Title
          </th>
          <th scope="col">
            Outstanding<br>Principal
          </th>
          <th scope="col">
            LTV
          </th>
          <th scope="col">
            Rate
          </th>
          <th scope="col">
            Maturity<br>Date
          </th>
          <th scope="col">
            Status
          </th>
          <th scope="col">
            <div class="main-form-block">
              <div class="child-form-block">
                Bid %
                <span class="offer-tooltip">
                  <a v-popover="popoverProperties"
                     href="#">
                    <i class="fa fa-question-circle" />
                  </a>
                  <span class="d-none">
                    This is the bid percentage you are willing to offer for each position. For example, if you
                    enter '90' in this field, a buyout offer worth 90% of the value for each position in the
                    loan will be generated.<br><br>If you would like to place a bid % below 65%, please connect with
                    PeerStreet Capital Markets team.
                  </span>
                </span>
              </div>
              <div class="child-form-block">
                Max<br>Allocation
                <span class="offer-tooltip">
                  <a v-popover="popoverProperties"
                     href="#">
                    <i class="fa fa-question-circle" />
                  </a>
                  <span class="d-none">
                    This is the maximum amount of outstanding principal you are willing to bid on. For example,
                    if the outstanding principal is $200,000 and you enter '100000' in this field, the maximum
                    allocation for this loan will be $100,000.
                  </span>
                </span>
              </div>
              <div class="child-form-block">
                Expiration
                <span class="offer-tooltip">
                  <a v-popover="popoverProperties"
                     href="#">
                    <i class="fa fa-question-circle" />
                  </a>
                  <span class="d-none">
                    This is date you would like the bid to expire on. The increments are '3 days', '5 days',
                    '7 days', and '10 days'.
                  </span>
                </span>
              </div>
              <div class="child-form-block" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="loan in biddableLoans"
            :key="loan.loan_ps_id"
            class="loan-bid-row">
          <td>
            <a :href="loan.loan_path"
               class="link-primary date-input"
               target="_blank">
              {{ loan.loan_title }}
            </a>
          </td>
          <td>{{ loan.outstanding_principal | money }}</td>
          <td>{{ loan.ltv | percent }}</td>
          <td>{{ loan.investor_rate | percent }}</td>
          <td>{{ loan.latest_maturity_date | date }}</td>
          <td>{{ paymentStatus(loan) }}</td>
          <td>
            <BidForm :loan-id="loan.loan_ps_id"
                     :loan-amount="loan.outstanding_principal"
                     :loan-title="loan.loan_title"
                     :refresh-loans="refreshLoans"
                     :disable-form="disableForm"
                     :toggle-form="toggleForm" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BidConfirmationModal from "components/secondary_market/bid_confirmation_modal";

import { date, money, percent } from "filters";
import BidForm from "components/secondary_market/bid_form";
import Popover from "directives/popover";


export default {
  name: "BuyerLoansTable",
  directives: {
    popover: {
      inserted: Popover.inserted,
      unbind: Popover.unbind,
    },
  },
  filters: {
    date,
    money,
    percent,
  },
  components: {
    BidConfirmationModal,
    BidForm,
  },
  props: {
    availableLoans: {
      type: Array,
      default: () => [],
      required: true,
    },
    refreshLoans: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      disableForm: false,
    };
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth <= 768;
    },
    popoverProperties() {
      return { placement: this.isMobileDevice ? "bottom" : "right" };
    },
    showTable() {
      return this.biddableLoans.length > 0;
    },
    biddableLoans() {
      return this.availableLoans.filter( loan => { return loan.secondary_market_bid === null; } );
    },
  },
  methods: {
    paymentStatus(loan) {
      return loan.loan_servicing_state.payment_status.toUpperCase().replace("_"," ");
    },
    toggleForm() {
      this.disableForm = !this.disableForm;
    },
  },
};
</script>
