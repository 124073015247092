<template>
  <div class="card card-active account-details-card total-earned-card">
    <div class="card-heading account-card-heading">
      <h4>
        Total Earned

        <a v-popover="{placement: 'top'}"
           class="tooltip-toggle"
           href="#">

          <i class="fa fa-question-circle" />
        </a>
        <span class="hidden">{{ tooltip }}</span>
      </h4>
    </div>

    <div class="total-earned-tabs-switch">
      <div class="total-earned-tabs-switch-item all-time-earnings-tab-switch-item"
           :class="tabSwitchItemClass(allTimeEarningsTabName)"
           @click="activateTab(allTimeEarningsTabName)">
        All-time
      </div>
      <div class="total-earned-tabs-switch-item this-year-earnings-tab-switch-item"
           :class="tabSwitchItemClass(thisYearEarningsTabName)"
           @click="activateTab(thisYearEarningsTabName)">
        Year-to-Date
      </div>
    </div>

    <div class="total-earned-tabs">
      <earnings-breakdown :breakdown="activeTabBreakdown" />

      <div class="card-body">
        <div :class="tabClass(allTimeEarningsTabName)"
             class="total-earned-graph all-time-earnings-graph">
          <earnings-chart v-if="hasDataPoints(allTimeEarningsTabName)"
                          :ref="allTimeEarningsTabName"
                          name="all-time-earnings-chart"
                          :chart-data-points="allTimeEarningsDataPoints" />
          <div v-else>
            {{ noHistoryMessage }}
          </div>
        </div>

        <div :class="tabClass(thisYearEarningsTabName)"
             class="total-earned-graph this-year-earnings-graph">
          <earnings-chart v-if="hasDataPoints(thisYearEarningsTabName)"
                          :ref="thisYearEarningsTabName"
                          name="this-year-earnings-chart"
                          :chart-data-points="thisYearEarningsDataPoints" />
          <div v-else>
            {{ noHistoryMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EarningsBreakdown from "../dashboard/earnings_breakdown";
import EarningsChart from "../charts/earnings_chart";
import Popover from "directives/popover";

const NO_HISTORY_MESSAGE = "Your earnings history will appear here " +
                           "when you begin to receive distributions.";

const ALL_TIME_EARNINGS_TAB_NAME = "allTimeEarnings";
const THIS_YEAR_EARNINGS_TAB_NAME = "thisYearEarnings";

export default {
  name: "TotalEarnedCard",

  components: {
    EarningsChart,
    EarningsBreakdown,
  },

  directives: {
    popover: {
      inserted: Popover.inserted,
      unbind: Popover.unbind,
    },
  },

  props: {
    tooltip: { type: String, required: true },
  },

  data() {
    return {
      activeTabName: ALL_TIME_EARNINGS_TAB_NAME,
      activeTabBreakdown: PSData.totalEarnedCard.allTimeBreakdown,
      chartsDataPoints: {
        [ALL_TIME_EARNINGS_TAB_NAME]: PSData.totalEarnedCard.allTimeEarnings,
        [THIS_YEAR_EARNINGS_TAB_NAME]: PSData.totalEarnedCard.thisYearEarnings,
      },
      breakdowns: {
        [ALL_TIME_EARNINGS_TAB_NAME]: PSData.totalEarnedCard.allTimeBreakdown,
        [THIS_YEAR_EARNINGS_TAB_NAME]: PSData.totalEarnedCard.thisYearBreakdown,
      },
    };
  },

  computed: {
    noHistoryMessage() {
      return NO_HISTORY_MESSAGE;
    },

    allTimeEarningsTabName() {
      return ALL_TIME_EARNINGS_TAB_NAME;
    },

    thisYearEarningsTabName() {
      return THIS_YEAR_EARNINGS_TAB_NAME;
    },

    allTimeEarningsDataPoints() {
      return this.chartsDataPoints[ALL_TIME_EARNINGS_TAB_NAME];
    },

    thisYearEarningsDataPoints() {
      return this.chartsDataPoints[THIS_YEAR_EARNINGS_TAB_NAME];
    },
  },

  methods: {
    tabSwitchItemClass(tabName) {
      return this.activeTabName === tabName ? "active" : "";
    },

    activateTab(tabName) {
      this.activeTabName = tabName;
      this.activeTabBreakdown = this.breakdowns[tabName];

      Vue.nextTick(() => {
        if (this.hasDataPoints(tabName)) {
          this.$refs[tabName].redraw();
        }
      });
    },

    hasDataPoints(tabName) {
      return this.chartsDataPoints[tabName].length > 0;
    },

    tabClass(tabName) {
      return this.activeTabName === tabName ? "" : "hidden";
    },
  },
};
</script>
