class ReturnCalculator {
  constructor({ investmentAmount, annualizedRate, term }) {
    this.investmentAmount = parseFloat(investmentAmount);
    this.annualizedRate = parseFloat(annualizedRate);
    this.term = parseFloat(term);
  }

  calculateTotalReturn() {
    return this.investmentAmount * this._monthlyRate() * this.term;
  }

  calculatePayouts() {
    let payouts = [];

    const totalReturn = this.calculateTotalReturn();
    const payoutAmount = totalReturn / this.term;

    for (let payout = 0; payout <= this.term; payout++) {
      let stepTotal = payout * payoutAmount;

      payouts.push(stepTotal.toFixed(2));
    }

    return payouts;
  }

  _monthlyRate() {
    return this.annualizedRate / 100 / 12;
  }
}

window.PS.Models.ReturnCalculator = ReturnCalculator;
