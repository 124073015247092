<template>
  <form ref="form"
        class="validate ach-form"
        @submit.prevent.capture="handleSubmit">
    <div class="row">
      <div class="col-lg-6 col-md-5 one-time-deposit-form">
        <div class="ach-form-title">
          One-Time Deposit
        </div>
        <div class="control-wrapper">
          <div class="form-group currency required transaction_amount">
            <label class="ach-form-label">Choose Deposit Amount</label>

            <server-errors v-if="errors"
                           :errors="errors" />

            <div class="radio-buttons-group">
              <div class="radio-buttons-wrapper">
                <input id="amount-1"
                       type="radio"
                       name="amount"
                       value="10000"
                       @change="setAmount">
                <label for="amount-1">$10,000</label>
              </div>
              <div class="radio-buttons-wrapper">
                <input id="amount-2"
                       type="radio"
                       name="amount"
                       value="25000"
                       @change="setAmount">
                <label for="amount-2">$25,000</label>
              </div>
              <div class="radio-buttons-wrapper">
                <input id="amount-3"
                       type="radio"
                       name="amount"
                       value="50000"
                       @change="setAmount">
                <label for="amount-3">$50,000</label>
              </div>
              <div class="radio-buttons-wrapper">
                <input id="amount-4"
                       type="radio"
                       name="amount"
                       @change="toggleCustomAmountShow">
                <label for="amount-4">Custom amount</label>
              </div>

              <div v-if="showAmountError"
                   class="error-wrapper">
                <span class="text-error">Select deposit amount.</span>
              </div>

              <div v-if="showMaxError"
                   class="error-wrapper">
                <span class="text-error">{{ overDepositLimitErrorMessage }}</span>
              </div>
            </div>

            <div v-show="showCustomAmount"
                 class="custom-input-wrapper">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="custom-amount"
                  ref="customAmountField"
                  v-mask:currency
                  min="1"
                  :max="max"
                  :data-msg-max="overDepositLimitErrorMessage"
                  type="text"
                  class="form-control currency required input-lg"
                  @input="setCustomAmount">
              </div>
              <span class="form-helper-label">{{  minimumAmountMessage  }}</span>
            </div>
          </div>
        </div>
        <div class="ach-form-submit">
          <button class="btn-primary-cta"
                  type="submit"
                  @click="handleSubmit">
            Initiate Deposit
          </button>
        </div>
      </div>
      <div class="col-lg-6 col-md-4">
        <auto-deposits-summary />
      </div>
    </div>
  </form>
</template>

<script>
const MINIMUM_DEPOSIT_AMOUNT = 1;

import AjaxService from "../../services/ajax";
import AutoDepositsSummary from "components/auto_deposits/summary";
import Mask from "directives/inputs";
import NumberFormatter from "formatters/number_formatter";
import ServerErrors from "components/server_errors";

export const { formatMoney } = NumberFormatter;

export default {
  name: "AchDepositForm",
  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
  },
  components: { AutoDepositsSummary, ServerErrors },
  props: {
    max: {
      type: String,
      required: true,
    },
    errors: {
      type: String,
      default: null,
    },
    limit: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      amount: 0,
      showCustomAmount: false,
      showAmountError: false,
      showMaxError: false,
      form: null,
      loading: false,
      depositMaxLimit: this.limit || PSData.autoDeposits.settings.depositMaxLimit,
    };
  },
  computed: {
    overDepositLimitErrorMessage() {
      return `Max of ${formatMoney(
        this.depositMaxLimit
      )} in pending transfers. Please select or enter an amount up to ${formatMoney(this.max)}.`;
    },
    minimumAmountMessage() {
      return `Minimum of ${formatMoney(MINIMUM_DEPOSIT_AMOUNT)}`;
    },
  },
  mounted() {
    this.form = $(this.$refs.form);
  },
  methods: {
    setAmount() {
      this.validateAmount(event.target.value);

      this.amount = event.target.value;
      this.showAmountError = false;
      this.showCustomAmount = false;
    },
    setCustomAmount() {
      this.amount = event.target.value;
    },
    toggleCustomAmountShow() {
      this.showCustomAmount = !this.showCustomAmount;
      this.showAmountError = false;
      this.showMaxError = false;

      if (this.showCustomAmount) {
        this.amount = this.$refs.customAmountField.value;
      }
    },
    validateForm() {
      return this.form.valid() && !this.showMaxError;
    },
    validateAmount(amount) {
      if (Number(amount) > Number(this.max)) {
        this.showMaxError = true;

        return;
      }

      this.showMaxError = false;
    },
    handleSubmit() {
      if (this.loading) return;

      // eslint-disable-next-line eqeqeq
      if (!this.showCustomAmount && this.amount == 0) {
        this.showAmountError = true;

        return;
      }

      if (!this.validateForm()) return;

      this.loading = true;
      const data = { amount: this.amount, deposit: "ach" };
      const url = "/transactions/ach_deposits/initiated_deposits_modal";

      AjaxService.post(url, data)
        .then(data => {
          const modal = $(".deposits-modal");

          modal.detach().prependTo("body");
          modal.find(".modal-content").html(data);
          modal.modal();
        })
        .finally(_ => (this.loading = false));
    },
  },
};
</script>
