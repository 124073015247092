import MemoryStorage from "memorystorage";

class LocalStorage {
  constructor() {
    try {
      const x = "peerstreet.test." + Date.now();

      localStorage.setItem(x, x);

      const y = localStorage.getItem(x);

      localStorage.removeItem(x);

      // eslint-disable-next-line eqeqeq
      if (x != y) { throw new Error(); }

      return localStorage;
    } catch (e) {
      return new MemoryStorage("peerstreet-memory-storage");
    }
  }
}

PS.Services.LocalStorage = new LocalStorage();
