<template>
  <div class="auto-investments-item">
    <div class="row">
      <div class="col-md-3">
        <div class="row no-gutters">
          <div class="col-2 col-md-3">
            <label class="control-label checkbox-toggle">
              <input v-model="settings.active"
                     type="checkbox"
                     :disabled="investmentTypeDisabled">
              <span class="toggle-control" />
            </label>
          </div>

          <div class="col-10 col-md-9">
            <label class="control-label auto-investments-item-title">
              {{ title }}
              <a v-popover="{placement: 'bottom'}"
                 href="#">
                <i class="fa fa-question-circle" />
              </a>
              <span class="d-none"
                    v-html="description" />
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-1 offset-md-0">
        <label class="control-label">
          Yield
          <a v-popover="{placement: 'bottom'}"
             href="#">
            <i class="fa fa-question-circle" />
          </a>
          <span class="d-none">
            {{ yieldTooltipText }}
          </span>
        </label>
        <p class="auto-investments-item-description">
          {{ rateSummary }}
        </p>
      </div>
      <div class="col-md-2 offset-md-0">
        <label class="control-label">
          Term
        </label>
        <p class="auto-investments-item-description">
          {{ termSummary }}
        </p>
      </div>
      <div class="col-md-6 offset-md-0">
        <div class="row">
          <div class="col-md-6">
            <div class="investment-amount-option clearfix">
              <div class="control-wrapper currency"
                   :class="{'control-disabled': !settings.active, 'has-error': settings.errors.amount}">
                <label class="control-label">Investment Per Loan</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input v-model="settings.amount"
                         v-mask:currency="{ minus: false, round: 0 }"
                         class="form-control"
                         placeholder="Amount"
                         :disabled="!settings.active"
                         @blur="validate">
                </div>

                <label v-show="settings.errors.amount"
                       class="error control-label">
                  {{ settings.errors.amount }}
                </label>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="control-wrapper currency"
                 :class="{'control-disabled': !settings.active, 'has-error': settings.errors.maxAllocation}">
              <label class="control-label">
                Portfolio Limit (optional)
                <a v-popover="{placement: 'bottom'}"
                   href="#">
                  <i class="fa fa-question-circle" />
                </a>
                <span class="d-none">The maximum amount of funds you want to allocate for Automated Investing across each loan type.</span>
              </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>

                <input v-model="settings.maxAllocation"
                       v-mask:currency="{ minus: false, round: 0 }"
                       class="form-control"
                       placeholder="No limit by default"
                       :disabled="!settings.active"
                       @blur="validate">
              </div>
              <label v-show="settings.errors.maxAllocation"
                     class="error control-label">
                {{ settings.errors.maxAllocation }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <auto-investments-extended-settings
      v-if="settings.showCustomOptions"
      :settings.sync="settings"
      @disable-custom-options="disableCustomOptions" />
  </div>
</template>

<script>
  import AutoInvestmentsExtendedSettings from "components/auto_investments/extended_settings";
  import Mask from "directives/inputs";
  import Popover from "directives/popover";

  export default {
    name: "AutoInvestmentsItem",
    components: {
      AutoInvestmentsExtendedSettings,
    },
    directives: {
      mask: {
        bind: Mask.bind,
        unbind: Mask.unbind,
      },
      popover: {
        inserted: Popover.inserted,
        unbind: Popover.unbind,
      },
    },
    props: {
      userSettings: { type: Object, required: true },
      cashSweep: Boolean,
    },
    data() {
      return {
        settings: this.userSettings,
        title: this.userSettings.autoInvestmentSettings.title,
      };
    },
    computed: {
      rateSummary() {
        return this.minRateChanged ? "Custom" : this.settings.autoInvestmentSettings.rateSummary;
      },
      termSummary() {
        return this.maxTermChanged ? "Custom" : this.settings.autoInvestmentSettings.termSummary;
      },
      description() {
        return `${this.userSettings.autoInvestmentSettings.description} <a href=${this.userSettings.autoInvestmentSettings.descriptionUrl} target="_blank">Read more</a>.`;
      },
      minRateChanged() {
        return this.settings.minRate &&
          this.settings.minRate !== this.settings.autoInvestmentSettings.minRate.default;
      },
      maxTermChanged() {
        return this.settings.maxTerm &&
          this.settings.maxTerm !== this.settings.autoInvestmentSettings.maxTerm.default;
      },
      isStandardInvestment() {
        return this.settings.autoInvestmentSettings.investmentType === "standard_investment";
      },
      yieldTooltipText() {
        if (this.isStandardInvestment) {
          return "The YTM (annualized yield) reflects the expected annual return over the full loan term, assuming the loan pays off on time and all interest payments are made. This yield is only projected and not guaranteed.";
        } else {
          return "The annualized yield of interest expected to be distributed to investors. This yield is only projected and not guaranteed.";
        }
      },
      investmentTypeDisabled() {
        return this.cashSweep;
      }
    },
    methods: {
      disableCustomOptions() {
        this.$emit("disable-custom-options");
      },
      validate() {
        this.settings.validate();
      },
    },
  };
</script>
