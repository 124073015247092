<template>
  <div class="yield-bump-banner">
    <div class="banner-image" />
    <div>
      A {{ formattedYieldBumpRate }} yield bump will be applied!
    </div>
  </div>
</template>

<script>
export default {
  props: {
    yieldBumpRate: { type: Number, default: 0 },
  },

  computed: {
    formattedYieldBumpRate() {
      return `${parseFloat(this.yieldBumpRate)}%`;
    },
  },
};
</script>
