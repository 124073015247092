<template>
  <div class="auth-section-2fa auth-section auth-centered col-10">
    <h1 class="heading">
      Two-Factor Authentication

      <Badge :enabled="enabled" />
    </h1>

    <Show v-if="enabled"
          @disable="onDisable()" />
    <Form v-else
          @enable="onEnable()" />
  </div>
</template>

<script>
import Badge from "./badge";
import Form from "./settings/form.vue";
import Show from "./settings/show";

export default {
  name: "TwoFactorAuthSettings",

  components: {
    Badge,
    Show,
    Form,
  },

  data() {
    return {
      enabled: PSData.twoFactorAuth.enabled,
    };
  },


  methods: {
    onDisable() {
      this.enabled = false;
    },

    onEnable() {
      this.enabled = true;
    },
  },
};
</script>
