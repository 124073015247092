export const FREQUENCY_MAPPING = {
  monthly: "monthly",
  monthly1st15th: "monthly_1st_15th",
  weekly: "weekly",
  everyOtherWeek: "every_other_week",
};

export default class Frequency {
  static localeMapping(frequenciesCopy) {
    if (!frequenciesCopy) {
      return;
    }

    const localeMapping = {};

    Object.keys(frequenciesCopy).forEach(key => {
      const value = FREQUENCY_MAPPING[key];

      localeMapping[value] = {
        key: key,
        name: frequenciesCopy[key].name,
        description: frequenciesCopy[key].description,
        value: value,
      };
    });

    return localeMapping;
  }
}
