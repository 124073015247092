const DOLLARS_OPTIONS = {
  alias: "numeric",
  autoGroup: true,
  groupSeparator: ",",
  digits: 0,
  allowPlus: false,
  allowMinus: false,
  rightAlign: false,
  clearMaskOnLostFocus: false,
  oncomplete: resendChanges,
  showMaskOnHover: false,
};

const DOLLARS_AND_CENTS_OPTIONS = {
  inputmode: "decimal",
  alias: "decimal",
  autoGroup: true,
  groupSeparator: ",",
  digits: 2,
  radixPoint: ".",
  allowPlus: false,
  allowMinus: false,
  rightAlign: false,
  clearMaskOnLostFocus: false,
  oncomplete: resendChanges,
};

const DOLLARS_AND_CENTS_NEGATIVE_OPTIONS = {
  ...DOLLARS_AND_CENTS_OPTIONS,
  allowMinus: true,
};

const PERCENTAGE_OPTIONS = {
  alias: "numeric",
  groupSeparator: ",",
  autoGroup: true,
  digits: 3,
  radixPoint: ".",
  allowPlus: false,
  allowMinus: false,
  rightAlign: false,
  clearMaskOnLostFocus: false,
  oncomplete: resendChanges,
};

// send update for DOM, use with v-model.lazy to receive changes
function resendChanges(e) {
  const event = document.createEvent("HTMLEvents");

  event.initEvent("change", true, true);
  e.currentTarget.dispatchEvent(event);
}

export default function maskMoney() {
  $("input.dollars").inputmask(DOLLARS_OPTIONS);
  $("input.dollars-and-cents").inputmask(DOLLARS_AND_CENTS_OPTIONS);
  $("input.dollars-and-cents-negative").inputmask(DOLLARS_AND_CENTS_NEGATIVE_OPTIONS);
  $("input.percentage").inputmask(PERCENTAGE_OPTIONS);
}

const setupInputMasks = () => {
  const ANDROID_USER = navigator.userAgent.match(/Android/i);

  if (!ANDROID_USER) {
    maskMoney();
  }
};

window.onload = setupInputMasks;
document.addEventListener("DOMContentLoaded", setupInputMasks);
