$buoop = {
  required: {
    e: 17,
    f: 68,
    c: 72,
    o: 68,
    s: 11,
    ios: 10,
    samsung: 9
  },
  insecure: true,
  api: 2020.08
};

function $buo_f(){
  var e = document.createElement("script");
  e.src = "//browser-update.org/update.min.js";
  document.body.appendChild(e);
};

try {
  document.addEventListener("DOMContentLoaded", $buo_f,false);
}

catch(e) {
  window.attachEvent("onload", $buo_f);
}
