<template>
  <div class="loan-invest-disclosure">
    By investing, I acknowledge that I have read and understand the
    <a href="https://peerstreet-static.s3.amazonaws.com/docs/ppm-02.pdf"
       target="_blank">
      Mortgage Payment Dependent Note Private Placement Memorandum
    </a> and <a href="https://peerstreet-static.s3.amazonaws.com/docs/ia-02.pdf"
                target="_blank">Investor Agreement</a>.
  </div>
</template>

<script>
export default {
};
</script>
