<template>
  <div class="pocket-investment-success">
    <img :src="require('icons/icon-deposit-success.svg')">
    <p class="mb-4">
      Your Pocket investment of {{ amount | money }} has been successfully initiated.
    </p>

    <div class="mb-4">
      If you have any questions, please contact us at
      <a href="mailto:support@peerstreet.com">support@peerstreet.com</a>
      or 844-733-7787.
    </div>

    <div class="form-buttons">
      <button class="btn-primary-cta btn-block"
              @click="closeModal">
        Exit
      </button>
    </div>
  </div>
</template>

<script>
import Store from "./store";
import ModalStore from "../../store";

export default {
  data() {
    return {
      storeData: Store.data,
    };
  },

  computed: {
    amount() {
      return this.storeData.amount;
    },
  },

  methods: {
    closeModal() {
      ModalStore.closeModal();
    },
  },
};
</script>
