import InvestButton from "components/invest/button";

$(() => {
  new Vue({
    el: document.querySelector(".v-loan-overview-controller"),
    components: {
      "invest-button": InvestButton,
    },
  });
});
