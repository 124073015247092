<template>
  <div class="banner-v3">
    <slot name="image" />
    <div class="banner-v3-right">
      <h5 class="banner-v3-title">
        <slot name="title" />
      </h5>
      <div class="banner-v3-content">
        <p>
          <slot name="content" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BannerV3",
};
</script>
