import clone from "lodash/clone";
import snakeCase from "lodash/snakeCase";

export default class Beneficiary {
  constructor(beneficiary) {
    this.errors = {};

    this._beneficiary = {};
    Object.assign(this._beneficiary, Beneficiary.default, beneficiary);

    this._originalBeneficiary = clone(beneficiary);
  }

  static get default() {
    return {
      name: undefined,
      relationship: undefined,
      dateOfBirth: undefined,
    };
  }

  get id() {
    return this._beneficiary.id;
  }

  get name() {
    return this._beneficiary.name;
  }

  set name(value) {
    this.setValue(value, "name");
  }

  get relationship() {
    return this._beneficiary.relationship;
  }

  set relationship(value) {
    this.setValue(value, "relationship");
  }

  get relationshipText() {
    return this._beneficiary.relationshipText;
  }

  get dateOfBirth() {
    return this._beneficiary.dateOfBirth;
  }

  set dateOfBirth(value) {
    this.setValue(value, "dateOfBirth");
  }

  setValue(value, field) {
    if (!value) {
      this.errors[field] = "This field is required.";
    }
    else {
      this.errors[field] = undefined;
    }

    this._beneficiary[field] = value;
  }

  get valid() {
    return !Object.values(this.errors).some(message => !!message);
  }

  toServer() {
    const beneficiary = {};

    Object.keys(Beneficiary.default).forEach((key) => {
      beneficiary[snakeCase(key)] = this[key];
    });

    return beneficiary;
  }

  isEmpty() {
    return !this._beneficiary.id;
  }

  delete() {
    this.errors = {};
    this._originalBeneficiary = undefined;
  }

  revert() {
    this.errors = {};
    this._beneficiary = clone(this._originalBeneficiary);
  }
}
