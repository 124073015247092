import camelCase from "lodash/camelCase";

const PHONE_NUMBER_LENGTH = 11;

const SMS_STRATEGY = "sms";
const APP_STRATEGY = "app";

const DEFAULT_STRATEGY = SMS_STRATEGY;

class Form {
  constructor(form) {
    this.initErrors();
    this.initForm(form);
  }

  initErrors() {
    this.smsErrors = {
      phoneNumber: null,
      verificationCode: null,
    };

    this.appErrors = {
      verificationCode: null,
    };

    this.errors = {
      ...this.smsErrors,
      ...this.appErrors,
    };
  }

  initForm(form) {
    this._form = {
      strategy: DEFAULT_STRATEGY,
      phoneNumber: "",
      verificationCode: "",
      qrProvisioningUri: "",
    };

    Object.assign(this._form, form);
  }

  get strategy() {
    return this._form.strategy || DEFAULT_STRATEGY;
  }

  set strategy(value) {
    this.initForm({ ...this._form, strategy: value, verificationCode: "" });
    this.initErrors();

    if (this.isSms) {
      this.errors = this.smsErrors;
    } else if (this.isApp) {
      this.errors = this.appErrors;
    }
  }

  get isSms() {
    return this.strategy === SMS_STRATEGY;
  }

  get isApp() {
    return this.strategy === APP_STRATEGY;
  }

  get phoneNumber() {
    return this._form.phoneNumber || "";
  }

  set phoneNumber(value) {
    const plainPhoneNumber = this.removePhoneNumberFormatting(value);

    switch (true) {
      case !plainPhoneNumber:
        this.errors.phoneNumber = "This field is required";
        break;

      case plainPhoneNumber && plainPhoneNumber.length < PHONE_NUMBER_LENGTH:
        this.errors.phoneNumber = "Invalid format";
        break;

      default:
        this.errors.phoneNumber = undefined;
    }

    this._form.phoneNumber = plainPhoneNumber;
  }

  removePhoneNumberFormatting(formattedPhoneNumber) {
    let result = "";

    if (formattedPhoneNumber && formattedPhoneNumber.toString) {
      result = formattedPhoneNumber.toString().replace(/[-+()_ ]/g, "");
    }

    return result;
  }

  get verificationCode() {
    return this._form.verificationCode;
  }

  set verificationCode(value) {
    if (value && value.length < 1) {
      this.errors.verificationCode = "Verification code is required";
    } else {
      this.errors.verificationCode = null;
    }

    this._form.verificationCode = value;
  }

  get qrProvisioningUri() {
    return this._form.qrProvisioningUri;
  }

  set qrProvisioningUri(value) {
    this._form.qrProvisioningUri = value;
  }

  updateErrors(errorsData) {
    const mapping = {
      two_factor_phone: "phoneNumber",
      verification_code: "verificationCode",
    };

    Object.keys(errorsData).forEach((key) => {
      this.errors[mapping[key]] = errorsData[key].join(", ");
    });
  }

  get valid() {
    return this.isEmpty ? false : !this.hasErrors;
  }

  get isEmpty() {
    if (this.isSms) {
      return this.isEmptySms;
    } else if (this.isApp) {
      return this.isEmptyApp;
    }
  }

  get isEmptySms() {
    return this.phoneNumber.length === 0 && this.verificationCode.length === 0;
  }

  get isEmptyApp() {
    return this.verificationCode.length === 0;
  }

  get hasErrors() {
    return Object.values(this.errors).some(message => !!message);
  }

  phoneNumberLengthValid(phoneNumber) {
    return this.removePhoneNumberFormatting(phoneNumber).length !== PHONE_NUMBER_LENGTH;
  }

  toParams() {
    return {
      strategy: this.strategy,
      two_factor_phone: this.phoneNumber,
      verification_code: this.verificationCode,
    };
  }

  commit() {
    this.errors = {};
  }
}

export default new Form(PSData.twoFactorAuth);
