const MESSAGE_NAME = "message";
const EVENT_LOADED = "session_view.document.loaded";
const EVENT_COMPLETED = "session_view.document.completed";
const EVENT_EXCEPTION = "session_view.document.exception";
const NULL_EVENT = "fail";
const BLACKLISTED_EVENTS = ["recaptcha-setup"];

class DocumentStatus {
  constructor(data) {
    try {
      if (typeof data === "string" && data.length > 0 && !this.isBlackListedEvent(data)) {
        this.message = JSON.parse(data);
      }
    } catch (e) {
      // Not a valid JSON message we're interested in listening to.
    } finally {
      this.message = this.message || { event: NULL_EVENT };
    }
  }

  isBlackListedEvent(data) {
    return BLACKLISTED_EVENTS.includes(data);
  }

  handle() {
    switch (this.message.event) {
    case EVENT_COMPLETED: this.completed(); break;

    case EVENT_EXCEPTION: this.exceptionOccured(); break;

    default:
    }
  }

  isIEBrowser() {
    return ["MSIE", "Trident/", "Edge/"].some(function(ua) {
      return window.navigator.userAgent.indexOf(ua) > 0;
    });
  }

  completed() {
    var container = $("#widget-container");

    $.ajax({
      type: "PUT",
      url: container.data("url"),
      data: this.message.data,
    }).done((data) => {
      if (this.isIEBrowser()) { container.remove(); }

      if (data.location) { window.location = data.location; }
    });
  }

  exceptionOccured() {
    if (console && console.error) {
      console.error("Document completion failed. Document: " + this.message.data.uuid);
    }
  }
}

window.addEventListener(MESSAGE_NAME, (e) => {
  if (e && e.data && typeof e.data === "string") {
    new DocumentStatus(e.data).handle();
  }
});
