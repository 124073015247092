<template>
  <th :class="[selectedColumnClass, columnClass]"
      @click="sortByColumn">
    <span>
      <i :class="sortOrderClass" />
      <slot />
    </span>
  </th>
</template>

<script>
export default {
  name: "AccountPositionsDashboardColumnHeader",
  props: {
    column: { type: String, default: null },
    columnClass: { type: String, default: null },
    sortColumn: { type: String, default: null },
    sortOrder: { type: String, default: null },
  },
  data() {
    return {
      defaultSortOrders: {
        notices: "asc",
        loan: "asc",
        start: "asc",
        status: "asc",
      },
    };
  },
  computed: {
    sortOrderClass() {
      return `fa fa-caret-${this.sortOrder === "asc" ? "up" : "down"}`;
    },
    selectedColumnClass() {
      return this.sortColumn === this.column ? "selected" : "";
    },
  },
  methods: {
    sortByColumn() {
      let order;

      if (this.column !== this.sortColumn) {
        order = this.defaultSortOrders[this.column] || "desc";
      } else {
        order = this.sortOrder === "asc" ? "desc" : "asc";
      }

      this.$emit("sorting-changed", { column: this.column, order: order });
    },
  },
};
</script>
