<template>
  <div class="auto-deposits-summary">
    <div v-if="autoDepositEnabled">
      <h5>
        Recurring Deposit
        <span class="badge badge-success">On</span>
      </h5>
      <div class="row">
        <div class="col-sm-6 col-5">
          <p class="recurring-deposit-details">
            Amount<span>{{ amount | money }}</span>
          </p>
        </div>
        <div class="col-sm-6 col-7">
          <p class="recurring-deposit-details">
            Next Deposit<span>{{ chargeDate }}</span>
          </p>
        </div>
      </div>
      <a href="/transactions/auto_deposits/settings">Edit Settings</a>
    </div>
    <div v-else>
      <h5>
        Recurring Deposit
        <span class="badge badge-warning">Off</span>
      </h5>
      <p>Deposit funds automatically at a frequency you choose.</p>
      <div class="auto-deposits-summary_setup-link">
        <a href="/transactions/auto_deposits/settings">Set Up Now</a>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      const settings = PSData.autoDeposits.settings;

      return {
        settings: settings,
        amount: settings.amount,
        autoDepositEnabled: settings.enabled,
        chargeDate: settings.depositDate,
      };
    },
  };
</script>
