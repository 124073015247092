<template>
  <div class="pocket-modal-available mb-4 rounded">
    <label>Available to Invest</label>
    <a v-if="hasCashAvailable"
       :href="addFundsUrl"
       class="add-funds-link">Add funds</a>
    <span class="available-amount">{{ availableToInvest | formatMoney }}</span>
  </div>
</template>

<script>
import ModalStore from "./store";

import NumberFormatter from "formatters/number_formatter";
export const { formatMoney } = NumberFormatter;

export default {
  filters: {
    formatMoney,
  },

  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    addFundsUrl() {
      return "/transactions/ach_deposits/new";
    },

    hasCashAvailable() {
      return +this.availableToInvest > 0;
    },

    availableToInvest() {
      return this.modalStoreData.availableToInvest;
    },
  },
};
</script>
