import AccountPortfolioChart from "components/account_positions/account_portfolio_chart";
import AccountPositionsDashboardPage from "components/account_positions/dashboard/page";
import AccountPositionsDashboardSection from "components/account_positions/dashboard/section";
import AutoInvestmentsIndicator from "components/auto_investments/indicator";
import EarningsChart from "components/charts/earnings_chart";
import ForeclosureModal from "components/account_positions/foreclosure/modal";
import LearningResourcesSlider from "components/learning_resources_slider";
import PaymentStatus from "components/loans_v2/payment_status";
import MarketingBanner from "components/marketing_banner";
import PromoBanner from "components/promo/banner";
import BankruptcyClaimBanner from "components/bankruptcy_claim_banner";
import BannerV3Slider from "components/banner_v3_slider";

class AccountPositionsController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        AccountPortfolioChart,
        AccountPositionsDashboardPage,
        AccountPositionsDashboardSection,
        AutoInvestmentsIndicator,
        EarningsChart,
        PaymentStatus,
        LearningResourcesSlider,
        MarketingBanner,
        PromoBanner,
        BankruptcyClaimBanner,
        BannerV3Slider,
        ForeclosureModal,
      },
      data: {
        accountPositions: PSData.accountPositions,
        accountPosition: PSData.accountPosition,
        tokens: PSData.tokens,
      },
      computed: {
        loanEarnings() {
          return PSData.loanEarnings;
        },
      },
      methods: {
        openForeclosureModal() {
          PS.globalBus.$emit("openForeclosureModal");
        },
      },
    });
  }
}

window.PS.Controllers.AccountPositionsController = AccountPositionsController;

PS.Components.register(AccountPositionsController, {
  applyOnlyTo: ".v-account-positions-controller",
});
