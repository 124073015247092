import psMarkerIcon from "icons/marker_ps.png"

closeExistingInfowindow = ->
  if window.infoWindow
    window.infoWindow.close()

mapsAddMarker = (markerData, map) ->
  marker = new google.maps.Marker(
    position: new google.maps.LatLng( markerData.lat, markerData.lng )
    zIndex: markerData.zIndex
    icon: psMarkerIcon
  )
  marker.setIcon( markerData.picture.url ) if markerData.picture

  google.maps.event.addListener marker, "mouseover", ->
    closeExistingInfowindow()
    window.infoWindow = new google.maps.InfoWindow({ content: markerData.infowindow })
    window.infoWindow.open map, marker

  google.maps.event.addListener marker, "mouseout", ->
    closeExistingInfowindow()

  marker.setMap(map)

addMarkers = (markers, map) ->
  for i, marker of markers
    mapsAddMarker(marker, map)

extractNewBoundaries = (markers) ->
  latLngBounds = new google.maps.LatLngBounds()
  for i, marker of markers
    latLngBounds.extend( new google.maps.LatLng( marker.lat, marker.lng ) )
  return latLngBounds

window.mapsLoad = (centerLatitude, centerLongitude, markersJson, fitMarkers = true, zoom = 18) ->
  if $("#map").length
    latLngBounds = extractNewBoundaries(markersJson)
    mapOptions =
      center: latLngBounds.getCenter()
      zoom: zoom
      maxZoom: 20
      minZoom: 5
      mapTypeId: google.maps.MapTypeId.ROADMAP
      draggable: !isMobile()
      scrollwheel: false
      styles: [
        featureType: "all"
        stylers: [saturation: -50]
      ,
        featureType: "road.arterial"
        elementType: "geometry"
        stylers: [
          hue: "#00ffee"
        ,
          saturation: 50
        ]
      ,
        featureType: "poi.business"
        elementType: "labels"
        stylers: [visibility: "off"]
      ]

    map = new google.maps.Map(document.getElementById("map"), mapOptions)
    if fitMarkers
      map.fitBounds(latLngBounds, 5)
    addMarkers(markersJson, map)
