import BuyoutOfferComponent from "components/secondary_market/buyout_offer";
import BuyoutOfferModel from "models/secondary_market/buyout_offer";

class BuyoutOfferController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        buyoutOffer: BuyoutOfferComponent,
      },
      data: {
        buyoutOffer: PSData.buyoutOffer ? new BuyoutOfferModel(PSData.buyoutOffer) : null,
      },
    });
  }
}

window.PS.BuyoutOfferController = BuyoutOfferController;
PS.Components.register(BuyoutOfferController, { applyOnlyTo: ".v-buyout-offer-wrapper" });
