class AboutUsController {
  onDomReady($element) {
    var view = new Vue({
      el: $element.get(0),
      data: {
        selectedMember: undefined,
      },
      methods: {
        setMember: function(name) {
          this.selectedMember = name;
        },
      },
    });
  }
}

window.PS.Controllers.AboutUsController = AboutUsController;
PS.Components.register(AboutUsController, { applyOnlyTo: ".v-about-us" });
