<template>
  <thead>
    <tr>
      <account-positions-dashboard-column-header
        column="loan"
        column-class="position-loan"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Property
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="investment"
        column-class="position-investment"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Investment
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="principal"
        column-class="position-principal"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Outstanding Principal
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="rate"
        column-class="position-rate"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Yield
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="earnings"
        column-class="position-earnings"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Earnings
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="start"
        column-class="positions-date"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Start Date
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        v-if="!paidOff"
        column="matures"
        column-class="position-date"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Maturity Date
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        v-if="paidOff"
        column="paidoff"
        column-class="position-date"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Paid Off Date
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="status"
        column-class="position-status"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        <span v-if="pending">Status</span>
        <span v-else>Payment Status</span>
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="notices"
        column-class="position-notices-count"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Notices
      </account-positions-dashboard-column-header>

      <account-positions-dashboard-column-header
        column="lastnotice"
        column-class="position-notices"
        :sort-column="sortColumn"
        :sort-order="sortOrder"
        @sorting-changed="changeSorting">
        Latest Notice
      </account-positions-dashboard-column-header>
    </tr>
  </thead>
</template>

<script>
import AccountPositionsDashboardColumnHeader from "components/account_positions/dashboard/column_header";

export default {
  name: "AccountPositionsDashboardListHeader",
  components: { AccountPositionsDashboardColumnHeader },
  props: {
    paidOff: Boolean,
    pending: Boolean,
    sortColumn: { type: String, default: null },
    sortOrder: { type: String, default: null },
  },
  methods: {
    changeSorting({ column, order }) {
      this.$emit("sorting-changed", column, order);
    },
  },
};
</script>
