export default class BuyerBidsService {
  static getLoans() {
    return PS.Services.Ajax.get("/secondary_market/loans");
  }

  static createBid(bidDetails) {
    if (!bidDetails) { return Promise.resolve({ errors: ["No bid details"] }); }

    const requestBody = {
      "expires_at": bidDetails.expirationDate,
      "offer_rate": bidDetails.offerRate,
      "max_allocation": bidDetails.allocationAmount,
      "loan_ps_id": bidDetails.loanId,
    };

    return PS.Services.Ajax.post("/secondary_market/bids", requestBody);
  }
}
