const DEFAULTS = {
  html: true,
  placement: "bottom",
  trigger: "hover focus",
  containerClass: false,
};

export default class Popover {
  static inserted(el, binding) {
    let options = binding.value ? binding.value : {};

    let popover = $(el)
      .on("click", preventDefault)
      .popover(
        Object.assign({}, DEFAULTS, options, {
          content: function() {
            return (options.content ? $(options.content) : $(el).next()).html();
          },
        })
      );

    if (options.containerClass) {
      let tip = popover.data("bs.popover").getTipElement();

      $(tip).addClass(options.containerClass);
    }
  }

  static unbind(el) {
    $(el)
      .off("click", preventDefault)
      .popover("dispose");
  }
}

Vue.directive("popover", {
  inserted: Popover.inserted,
  unbind: Popover.unbind,
});

function preventDefault(e) {
  $(this).focus();
  e.stopPropagation();
  e.preventDefault();
}
