<template>
  <div class="row filter-unlinked-payments-row">
    <div class="col-md-12">
      <div class="jarviswidget">
        <header>
          <a class="section-filter-link"
             data-toggle="collapse"
             href="#section-filter">
            Filter
          </a>
        </header>
        <div id="section-filter"
             class="collapse section-filter"
             role="content">
          <div class="widget-body">
            <form id="filter-form"
                  class="smart-form"
                  novalidate="novalidate">
              <fieldset>
                <section>
                  <label class="input">
                    <i class="icon-prepend fa fa-calendar" />
                    <input
                      ref="startDateInPicker"
                      v-model="startDate"
                      v-datepicker
                      v-mask:date
                      class="date-picker form-control"
                      placeholder="Start Date:"
                      type="text">
                  </label>
                </section>
                <section>
                  <label class="input">
                    <i class="icon-prepend fa fa-calendar" />
                    <input
                      ref="endDateInPicker"
                      v-model="endDate"
                      v-datepicker
                      v-mask:date
                      class="date-picker form-control"
                      placeholder="End Date:"
                      type="text">
                  </label>
                </section>
                <section>
                  <label class="input">
                    <input v-model="loanIds"
                           name="loan_ids"
                           placeholder="Loans"
                           type="text">
                  </label>
                </section>
              </fieldset>
              <footer>
                <button
                  class="btn btn-primary"
                  type="submit"
                  :disabled="isLoading"
                  @click.prevent="filter">
                  Filter Unlinked Payments
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mask, { DatePickerDirective } from "directives/inputs";
import Events from "components/manage/transactions/unlinked_payment/events";
import moment from "moment";

const US_DATE = "MM/DD/YYYY";

export default {
  name: "UnlinkedPaymentFilter",
  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
    datepicker: {
      bind: DatePickerDirective.bind,
      unbind: DatePickerDirective.unbind,
    },
  },
  data() {
    return {
      isLoading: true,
      loanIds: "",
      startDate: moment()
        .subtract(1, "months")
        .startOf("month")
        .format(US_DATE),
      endDate: moment()
        .subtract(1, "months")
        .endOf("month")
        .format(US_DATE),
    };
  },
  watch: {
    startDate(date) {
      $(this.$refs.endDateInPicker).datepicker("option", "minDate", date);
    },
    endDate(date) {
      $(this.$refs.startDateInPicker).datepicker("option", "maxDate", date);
    },
  },
  mounted() {
    PS.globalBus.$on(Events.recordsUpdated, () => {
      this.isLoading = false;
    });
  },
  methods: {
    filter() {
      this.isLoading = true;

      PS.globalBus.$emit(Events.filter, {
        startDate: this.startDate,
        endDate: this.endDate,
        loanIds: this.loanIds,
      });
    },
  },
};
</script>
