import Ajax from "services/ajax";

export default class Client {
  static async enable(params) {
    const enablePath = PSData.twoFactorAuth.enablePath;

    return Ajax.post(enablePath, { ...params }, {
      "Accept": "application/json",
      "Content-Type": "application/json",
    });
  }

  static async disable() {
    const disablePath = PSData.twoFactorAuth.disablePath;

    return Ajax.delete(disablePath);
  }

  static async sendVerificationCode(phoneNumber = null) {
    const sendVerificationCodePath = PSData.twoFactorAuth.sendVerificationCodePath;
    const payload = phoneNumber ? { two_factor_phone: phoneNumber } : {};

    return Ajax.post(sendVerificationCodePath, payload, {
      "Accept": "application/json",
      "Content-Type": "application/json",
    });
  }
}
