import orderBy from "lodash/orderBy";

Vue.component("LoansList", {
  props: {
    loans: { type: Array, default: () => [] },
    enableSorting: Boolean,
    enablePagination: Boolean,
    enableFeaturedLoans: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
  data: () => {
    return {
      sortColumn: "statusRank",
      sortOrder: {
        title: "asc",
        investmentReturn: "asc",
        ltv: "asc",
        psTerm: "asc",
        investmentAmount: "asc",
        statusRank: "desc",
        timeProgress: "asc",
        paidOffAtMs: "asc",
      },
    };
  },
  computed: {
    sortKey: function() {
      if (this.sortColumn === "statusRank") {
        switch ((this.loans[0] || {}).fundingState) {
          case "available":
            return "statusRank";

          case "closed":
            return "timeProgress";

          default:
            return "paidOffAtMs";
        }
      }
      else {
        return this.sortColumn;
      }
    },
    orderedLoans: function() {
      if (this.enableSorting) {
        return orderBy(this.loans, this.sortKey, this.sortOrder[this.sortKey]);
      } else {
        return this.loans;
      }
    },
  },
  methods: {
    sortBy: function(column) {
      if (!this.enableSorting) { return; }

      this.sortColumn = column;
      if (this.sortOrder[column] === "desc") {
        this.sortOrder[column] = "asc";
      }
      else if (this.sortOrder[column] === "asc") {
        this.sortOrder[column] = "desc";
      }
    },
    sortListClass: function() {
      if (this.enableSorting) {
        return "sorted-list";
      }
    },
  },
  template: "#v-loans-list-template",
});
