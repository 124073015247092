var ANIMATION_DELAY = 777;

class AutoInvestmentScrolling {
  onDomReady($element) {
    $element.on("click", function() {
      if ($(".auto-investment-form").valid() || !isMobile()) {
        return;
      }

      var $errorElement = $(".has-error").first();

      $("html, body").stop().animate({
        "scrollTop" : $($errorElement).offset().top - 50,
      }, ANIMATION_DELAY);
    });
  }
}

PS.Components.register(AutoInvestmentScrolling, { applyOnlyTo: ".btn-automate" });
window.PS.Components.AutoInvestmentScrolling = AutoInvestmentScrolling;
