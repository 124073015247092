<template>
  <div class="app-auth">
    <QrcodeVue :value="form.qrProvisioningUri"
               size="200"
               class="app-auth-qr-code" />
    <div class="app-auth-message px-md-5">
      Scan this QR code in the authenticator app of your choice to generate a verification code.
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import form from "../form.js";

export default {
  name: "AppFields",

  components: {
    QrcodeVue,
  },

  data() {
    return {
      form: form,
    };
  },
};
</script>
