<template>
  <span class="badge"
        :class="settings.enabled ? 'badge-success' : 'badge-neutral'">
    {{ settings.enabled ? 'On' : 'Off' }}
  </span>
</template>

<script>
import RecurringStore from "./recurring_deposits/store";

export default {
  data: function() {
    return {
      storeData: RecurringStore.data,
    };
  },

  computed: {
    settings() {
      return this.storeData.settings;
    },
  },
};
</script>
