<template>
  <div>
    <button id="send-verification-code-btn"
            :class="['btn btn-block secondary-action-btn', buttonClass]"
            :disabled="sendVerificationDisabled"
            type="button"
            @click="sendVerificationCode">
      {{ buttonText }}
    </button>
    <p class="send-verification-code-notice">
      <span :class="{ 'text-danger': sendError }">
        {{ sendNotice || sendError }}
      </span>
    </p>
  </div>
</template>

<script>
import Client from "./client.js";

const RESEND_COUNTDOWN_INTERVAL_MS = 1000;
const RESEND_COUNTDOWN_DURATION_SECONDS = 30;

export default {
  name: "SendVerificationCodeButton",

  props: {
    buttonText: {
      type: String,
      default: "Send Verification Code",
    },
    buttonClass: {
      type: String,
      required: false,
      default: "btn-info-cta",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      sendNotice: null,
      sendTimeout: null,
      sendError: null,
    };
  },

  computed: {
    sendVerificationDisabled() {
      return this.disabled || this.sendTimeout;
    },
  },

  methods: {
    async sendVerificationCode() {
      this.disableWithTimer();

      try {
        const responseJSON = await Client.sendVerificationCode(this.phoneNumber);

        if (!responseJSON.success) {
          this.showUnexpectedError();
        }
      } catch ({ data }) {
        this.showUnexpectedError();
      }
    },

    disableWithTimer() {
      this.sendTimeout = RESEND_COUNTDOWN_DURATION_SECONDS;
      this.startCountdown();
    },

    startCountdown() {
      this.sendError = null;
      this.sendNotice = this.sendNoticeText(RESEND_COUNTDOWN_DURATION_SECONDS);
      this.disableResendButtonTimer = setInterval(
        this.intervalHandler,
        RESEND_COUNTDOWN_INTERVAL_MS
      );
    },

    intervalHandler() {
      if (this.sendTimeout > 1) {
        this.sendTimeout -= 1;
        this.sendNotice = this.sendNoticeText(this.sendTimeout);
      } else {
        this.stopCountdown();
      }
    },

    sendNoticeText(timeLeft) {
      return `Resend available in ${timeLeft} seconds`;
    },

    stopCountdown() {
      clearInterval(this.disableResendButtonTimer);
      this.sendTimeout = null;
      this.sendNotice = null;
    },

    showUnexpectedError() {
      this.stopCountdown();
      this.sendError = "Something went wrong";
    },
  },
};
</script>
