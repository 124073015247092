<template>
  <div class="insufficient-funds">
    <div class="insufficient-funds-inner">
      <i class="fa fa-exclamation-triangle" />
      <div class="insufficient-funds-body">
        <div class="insufficient-funds-title">
          Insufficient Funds
        </div>
        <div class="insufficient-funds-description">
          Please make a new deposit to continue investing
        </div>
      </div>
    </div>
    <a class="btn-primary-cta btn-block"
       href="/transactions/ach_deposits/new">
      Deposit Funds
    </a>
  </div>
</template>

<script>
export default {
};
</script>
