const PRODUCT_TOURS = {
  investorFirstDefault: 114414,
};

export default class TourService {
  static start(tourName) {
    const tourId = PRODUCT_TOURS[tourName];

    if (tourId === undefined) {
      return;
    }

    const tours = window.localStorage.getItem("tours") || "";
    const seenTour = tours.includes(tourName);

    if (!seenTour) {
      PS.Services.IntercomService.startTour(tourId);

      let newValue = tours ? `${tours},${tourName}` : tourName;

      window.localStorage.setItem("tours", newValue);
    }
  }
}
