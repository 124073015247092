<template>
  <h1>
    Automated Investing
    <span v-if="aiEnabled"
          class="badge badge-success">On</span>
    <span v-else
          class="badge badge-warning">Off</span>
  </h1>
</template>

<script>
  export default {
    name: "AutoInvestmentsIndicator",
    data() {
      return {
        aiEnabled: PSData.autoInvestingEnabled,
      };
    },
    created() {
      PS.globalBus.$on("auto-investing-status-updated", (enabled) => {
        this.aiEnabled = enabled;
      });
    },
  };
</script>
