import AccountBar from "components/account_bar";

class AccountBarController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        AccountBar,
      },
    });
  }
}

window.PS.Controllers.AccountBarController = AccountBarController;
PS.Components.register(AccountBarController, { applyOnlyTo: ".v-account-bar-controller" });
