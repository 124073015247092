class DocumentHeight {
  onDomReady($element) {
    $element.css({
      height: $(window).height() - 200,
    });
  }
}

PS.Components.register(DocumentHeight, { applyOnlyTo: ".panda-doc-widget" });
window.PS.Components.DocumentHeight = DocumentHeight;
