<template>
  <div class="recurring-deposit-summary">
    <div class="summary-line-item">
      <span>Offering</span>
      <span class="summary-line-item-value">{{ fixedOfferingName || offeringName }}</span>
    </div>
    <div class="summary-line-item">
      <span>From</span>
      <span class="summary-line-item-value">{{ investmentSourceName }}</span>
    </div>
    <div class="summary-line-item">
      <span>Frequency</span>
      <span class="summary-line-item-value">{{ frequency }}</span>
    </div>
    <div v-for="item in optionalItems"
         :key="item.label"
         class="summary-line-item">
      <span>{{ item.label }}</span>
      <span class="summary-line-item-value">{{ item.value }}</span>
    </div>
    <div class="summary-line-item">
      <span>Amount</span>
      <span class="summary-line-item-value">{{ amount | money }}</span>
    </div>
  </div>
</template>

<script>
import ModalStore from "../store";

export default {
  props: {
    fixedOfferingName: { type: String, default: null },
    investmentSourceName: { type: String, required: true },
    frequency: { type: String, default: "One-time" },
    optionalItems: { type: Array, default: () => [] },
    amount: { type: Number, required: true },
  },

  data() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    offeringName() {
      return this.modalStoreData.offerings.find(
        o => o.globalId === this.modalStoreData.selectedOfferingGlobalId).name;
    },
  },
};
</script>
