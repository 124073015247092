startDatepickers = ->
  $(".has-datepicker").datepicker
    dateFormat: "MM d, yy"
    prevText: "<i class=\"fa fa-chevron-left\"></i>"
    nextText: "<i class=\"fa fa-chevron-right\"></i>"
    onSelect: ->
      $(this).valid()

setupPageListeners = ->
  startDatepickers()

onReady = ->
  setupPageListeners()

window.onLoad = ->
  setupPageListeners()

$(onReady)

$(window).on "load", onLoad
