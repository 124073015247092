<template>
  <div class="investment-options">
    <div class="investment-options-item">
      <div class="investment-options-item-title">
        Available Investment
      </div>
      <div class="investment-options-item-amount">
        {{ availableInvestment | money(2) }}
      </div>
    </div>
    <div class="investment-options-item">
      <div class="investment-options-item-title">
        Your Investable Cash
      </div>
      <div class="investment-options-item-amount">
        {{ availableCash | money(2) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    availableInvestment: { type: Number, default: 0 },
    availableCash: { type: Number, default: 0 },
  },
};
</script>
