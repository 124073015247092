export default {
  props: {
    serviceUrl: String,
    applicationName: String,
  },

  mounted: async function() {
    await this.loadScript();
    this.$emit("ready");
  },

  render() {
    return this.$scopedSlots.default({});
  },

  methods: {
    fetchSkipCache(url) {
      return fetch(url, { cache: "no-store" });
    },

    async loadScript() {
      const response = await this.fetchSkipCache(`${this.serviceUrl}/packs/manifest.json`);
      const manifest = await response.json();

      return new Promise(resolve => {
        let serviceScript = document.createElement("script");

        serviceScript.type = "text/javascript";
        serviceScript.async = true;

        const fileName = `${this.applicationName}.js`;

        serviceScript.src = `${this.serviceUrl}${manifest[fileName]}`;

        serviceScript.addEventListener("load", () => {
          resolve();
        });
        document.head.appendChild(serviceScript);
      });
    },
  },
};
