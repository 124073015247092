import _ from "lodash";
import moment from "moment";

export const DAYS_OF_MONTH = _.times(28, i => i + 1);
export const DAYS_OF_WEEK = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
export const DEFAULT_DAY_OF_MONTH = 1;
export const DEFAULT_DAY_OF_WEEK = 1;
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const SHORT_DATE_FORMAT = "MMMM Do";
export const SHORT_WITH_YEAR_FORMAT = "MMMM Do, YYYY";

export default class CalendarService {
  static _detectWeekdayFor(dayOfWeek) {
    return moment().startOf("isoWeek").day(dayOfWeek);
  }

  static _detectNextWeekdayFor(dayOfWeek, weeksFromNow) {
    return moment().startOf("isoWeek")
      .add(weeksFromNow, "week")
      .add(dayOfWeek.weekday() - 1, "days");
  }

  static weeklyStartDates(dayOfWeek) {
    const weekday = CalendarService._detectWeekdayFor(dayOfWeek);
    const nextDateThisWeek = moment().isBefore(weekday);

    return _.times(6, i => nextDateThisWeek ? i : i + 1).map((weekNumber => {
      const nextDate = CalendarService._detectNextWeekdayFor(weekday, weekNumber);

      return {
        text: nextDate.format(SHORT_DATE_FORMAT),
        value: nextDate.format(SERVER_DATE_FORMAT),
      };
    }));
  }

  static nextMonthlyStartDate(dayOfTheMonth) {
    const useNextMonth = moment().date() >= dayOfTheMonth;

    if (useNextMonth) {
      return moment().add(1, "month").set("date", dayOfTheMonth).format(SHORT_DATE_FORMAT);
    } else {
      return moment().set("date", dayOfTheMonth).format(SHORT_DATE_FORMAT);
    }
  }

  static nextMonthly1st15thStartDate() {
    const isEndOfMonth = moment().date() >= 15;

    if (isEndOfMonth) {
      return moment().add(1, "month").set("date", 1).format(SHORT_DATE_FORMAT);
    } else {
      return moment().set("date", 15).format(SHORT_DATE_FORMAT);
    }
  }

  static convertToShortDate(date) {
    return moment(date).format(SHORT_DATE_FORMAT);
  }

  static convertToShortWithYear(date) {
    return moment(date).format(SHORT_WITH_YEAR_FORMAT);
  }
}
