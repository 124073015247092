<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="auto-deposits">
    <div class="col-sm-8 auto-deposits-settings">
      <div class="auto-deposits-edit-page">
        <h1 v-show="settings.enabled">
          Edit Recurring Deposit
        </h1>
        <h1 v-show="settings.disabled">
          Configure Recurring Deposit
        </h1>

        <p v-show="settings.disabled"
           class="auto-deposits-subheading">
          Add funds from your linked bank account to your PeerStreet account automatically.
        </p>

        <div class="control-wrapper auto-deposits-account">
          <label class="control-label">From</label>
          <p>Funds will be transferred from account {{ account }}.</p>
        </div>

        <div class="control-wrapper"
             :class="{'has-error': settings.errors.frequency}">
          <label class="control-label">Frequency</label>

          <select v-model="settings.frequency"
                  class="form-control select">
            <option v-for="frequency in frequencies"
                    :key="frequency.key"
                    :value="frequency.value">
              {{ frequency.name }}
            </option>
          </select>

          <label v-show="settings.errors.frequency"
                 class="error control-label">
            {{ settings.errors.frequency }}
          </label>
        </div>

        <div v-show="settings.showMonthly">
          <div class="control-wrapper"
               :class="{'has-error': settings.errors.dayOfMonth}">
            <label class="control-label">Day of the Month</label>

            <select v-model="settings.dayOfMonth"
                    class="form-control select">
              <option v-for="day in daysOfMonth"
                      :key="day"
                      :value="day">
                {{ day | ordinalize }}
              </option>
            </select>

            <label v-show="settings.errors.dayOfMonth"
                   class="error control-label">
              {{ settings.errors.dayOfMonth }}
            </label>
          </div>
        </div>

        <div v-show="settings.showWeekly || settings.showEveryOtherWeek">
          <div class="control-wrapper"
               :class="{'has-error': settings.errors.dayOfWeek}">
            <label class="control-label"> Day of the Week </label>

            <select v-model="settings.dayOfWeek"
                    class="form-control select">
              <option
                v-for="(name, number) in daysOfWeek"
                :key="name"
                :value="number + 1">
                {{ name }}
              </option>
            </select>

            <label v-show="settings.errors.dayOfWeek"
                   class="error control-label">
              {{ settings.errors.dayOfWeek }}
            </label>
          </div>

          <div class="control-wrapper"
               :class="{'has-error': settings.errors.startDate}">
            <label class="control-label"> Start Date </label>

            <select v-model="settings.startDate"
                    class="form-control select">
              <option v-for="date in weeklyStartDates"
                      :key="date.value"
                      :value="date.value">
                {{ date.text }}
              </option>
            </select>

            <label v-show="settings.errors.startDate"
                   class="error control-label">
              {{ settings.errors.startDate }}
            </label>
          </div>
        </div>

        <div class="control-wrapper currency"
             :class="{'has-error': settings.errors.amount}">
          <label class="control-label">Amount</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input v-model="settings.amount"
                   v-mask:currency=""
                   class="form-control"
                   placeholder="Amount">
          </div>
          <label v-show="settings.errors.amount"
                 class="error control-label">
            {{ settings.errors.amount }}
          </label>
        </div>

        <div v-if="settings.disabled">
          <slot name="create-deposit-button" />
        </div>

        <div v-if="settings.enabled"
             class="auto-deposits-edit-buttons">
          <slot name="disable-deposit-button" />
          <slot name="update-deposit-button" />
        </div>

        <slot name="cancel-setup-button" />
      </div>
    </div>

    <div class="col-sm-4 security-details-info">
      <security-details />
    </div>
  </div>
</template>
<script>
  /* eslint-disable vue/no-mutating-props */

  import CalendarService, {
    DAYS_OF_MONTH,
    DAYS_OF_WEEK
  } from "services/auto_deposits/calendar_service";

  import SecurityDetails from "components/auto_deposits/security_details";

  import { money, ordinalize } from "filters";
  import Mask from "directives/inputs";

  export default {
    components: {
      "security-details": SecurityDetails,
    },
    filters: {
      ordinalize, money,
    },
    directives: {
      mask: {
        bind: Mask.bind,
        unbind: Mask.unbind,
      },
    },
    props: {
      settings: { type: Object, default: null },
      account: { type: String, default: null },
      frequencies: { type: Object, default: null },
    },
    data() {
      return {
        daysOfMonth: DAYS_OF_MONTH,
        daysOfWeek: DAYS_OF_WEEK,
      };
    },
    computed: {
      weeklyStartDates() {
        return CalendarService.weeklyStartDates(this.settings.dayOfWeek);
      },
    },
    watch: {
      "settings.dayOfWeek": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.settings.startDate = this.weeklyStartDates[0].value;
        }
      },
    },
    mounted() {
      const dateIrrelivant = !this.weeklyStartDates.some(startDate => {
        return startDate.value === this.settings.startDate;
      });

      if (dateIrrelivant) {
        this.settings.startDate = this.weeklyStartDates[0].value;
      }
    },
  };
</script>
