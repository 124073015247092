<template>
  <ul class="learning-resources-slider">
    <li>
      <h3>How It Works</h3>
      <p>
        PeerStreet vets private lenders from across the country to make real estate debt
        investments available to you. Each loan, underwritten by the lender, goes through our
        screening process...
        <a href="/howitworks?ps_src=header"
           target="_blank">Read more</a>
      </p>
    </li>
    <li>
      <h3>How PeerStreet Works to Protect Your Investment</h3>
      <p>
        Investments in loans are held in a bankruptcy-remote entity that is separate from
        our primary corporate entity. Additionally, each investment...
        <a href="https://info.peerstreet.com/faqs/what-investor-protections-does-peerstreet-have-in-place/"
           target="_blank">Read more</a>
      </p>
    </li>
    <li>
      <h3>How PeerStreet Handles Loan Defaults</h3>
      <p>
        PeerStreet’s Asset Management team will lead the loan workout process on behalf of
        investors to protect their investment and maximize proceeds...
        <a href="https://info.peerstreet.com/faqs/how-does-peerstreet-handle-potential-loan-defaults/"
           target="_blank">Read more</a>
      </p>
    </li>
    <li>
      <h3>How Automated Investing Works</h3>
      <p>
        Automated Investing enables you to reserve your spot in investments that meet your
        specific criteria—and easily diversify your portfolio—in just a few clicks.
        You then have 24 hours to...
        <a href="https://info.peerstreet.com/faqs/how-does-automated-investing-work/"
           target="_blank">Read more</a>
      </p>
    </li>
    <li>
      <h3>Availability of New Investments</h3>
      <p>New investments generally get posted every weekday at 12 p.m. PT.</p>
    </li>
  </ul>
</template>

<script>

  export default {
    mounted() {
      const slider = $(this.$el);

      slider.bxSlider({
        auto: true,
        autoHover: true,
        infiniteLoop: true,
        speed: 500,
        pause: 10000,
        useCSS: false,
        controls: true,
        touchEnabled: false,
      });
    },
  };
</script>
