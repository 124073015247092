import InvestButton from "components/invest/button";

class OrganizationsController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: { InvestButton },
    });
  }
}

window.PS.Controllers.OrganizationsController = OrganizationsController;
PS.Components.register(OrganizationsController, {
  applyOnlyTo: ".v-organization-loans-controller",
});
