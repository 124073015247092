import "segment";

export const SEGMENT_EVENTS = {
  areYouALender: "Are you a lender",
  enableRecurringDeposit: "Enable Recurring Deposit",
  disableRecurringDeposit: "Disable Recurring Deposit",
  updateRecurringDeposit: "Update Recurring Deposit",
  Plaid_ERROR: "Plaid_ERROR",
  Plaid_EXIT: "Plaid_EXIT",
  Plaid_HANDOFF: "Plaid_HANDOFF",
  Plaid_OPEN: "Plaid_OPEN",
  Plaid_OPEN_OAUTH: "Plaid_OPEN_OAUTH",
  Plaid_SEARCH_INSTITUTION: "Plaid_SEARCH_INSTITUTION",
  Plaid_SELECT_INSTITUTION: "Plaid_SELECT_INSTITUTION",
  Plaid_SELECT_BRAND: "Plaid_SELECT_BRAND",
  Plaid_TRANSITION_VIEW: "Plaid_TRANSITION_VIEW",
  Plaid_SUBMIT_CREDENTIALS: "Plaid_SUBMIT_CREDENTIALS",
  pageView: "Page View",
  click: "Click",
};

class Segment {
  static load() {
    if (!window.analytics) { return; }

    window.analytics.load(PSData.global.segmentApiKey);

    if (PSData.user.signedIn === "yes") {
      window.analytics.identify(PSData.user.id, PSData.user.properties, {
        Intercom: {
          user_hash: PSData.user.intercomUserHash,
        },
      });
    }

    window.analytics.page(PSData.page.title);
  }

  static track(event, properties) {
    if (!SEGMENT_EVENTS[event]) {
      throw {
        name: "Segment Analytics Error",
        message: `Event is not defined: ${event}`,
      };
    }

    window.analytics.track(SEGMENT_EVENTS[event], Object.assign({
      user: PSData.user ? PSData.user.id : null,
      event: SEGMENT_EVENTS[event],
    }, properties));
  }
}

window.PS.Services.SegmentService = Segment;
export default Segment;
