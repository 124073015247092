<template>
  <div class="investment-options">
    <div class="investment-options-item">
      <div class="investment-options-item-title">
        Available Investment
      </div>
      <div class="investment-options-item-amount">
        {{ availableInvestment | money(2) }}
      </div>
    </div>
    <div class="investment-options-item"
         :class="{disabled: cashSourceDisabled}">
      <div class="investment-options-item-title">
        <input id="funds-source-cash"
               v-model="fundsSource"
               type="radio"
               :disabled="cashSourceDisabled"
               name="funds_source"
               :value="fundsSourceCash">
        <label for="funds-source-cash">Investable Cash</label>
      </div>
      <div class="investment-options-item-amount">
        {{ availableCash | money(2) }}
      </div>
    </div>
    <div class="investment-options-item"
         :class="{disabled: pocketSourceDisabled}">
      <div class="investment-options-item-title">
        <input id="funds-source-pocket"
               v-model="fundsSource"
               type="radio"
               :disabled="pocketSourceDisabled"
               name="funds_source"
               :value="fundsSourcePocket">
        <label for="funds-source-pocket">
          {{ pocketSourceName }}
          <a v-popover="{ placement: 'top' }"
             href="#"
             class="tooltip-toggle">
            <i class="fa fa-question-circle" />
          </a>
          <span class="d-none">
            {{ pocketTooltip }}
          </span>
        </label>
      </div>
      <div class="investment-options-item-amount">
        {{ pocketPrincipal | money(2) }}
      </div>
    </div>
  </div>
</template>

<script>
import InvestmentOfferingsService from "services/investment_offerings_service";

const FUNDS_SOURCE_CASH = "cash";
const FUNDS_SOURCE_POCKET = "pocket";

const MINIMUM_INVESTMENT_AMOUNT = 1000;

export default {
  props: {
    availableInvestment: { type: Number, default: 0 },
    availableCash: { type: Number, default: 0 },
    pocketPrincipal: { type: Number, default: 0 },
  },

  data() {
    return {
      fundsSource: null,
      fundsSourceCash: FUNDS_SOURCE_CASH,
      fundsSourcePocket: FUNDS_SOURCE_POCKET,
      pocketSourceName: InvestmentOfferingsService.pocketClassicOffering().name,
    };
  },

  computed: {
    pocketTooltip() {
      return "Pending Pocket withdrawals may be affected";
    },

    cashSourceDisabled() {
      return this.availableCash < MINIMUM_INVESTMENT_AMOUNT;
    },

    pocketSourceDisabled() {
      return this.pocketPrincipal < MINIMUM_INVESTMENT_AMOUNT;
    },

    isFundsSourceCash() {
      return this.fundsSource === FUNDS_SOURCE_CASH;
    },

    isFundsSourcePocket() {
      return this.fundsSource === FUNDS_SOURCE_POCKET;
    },

    fundsSourceAmount() {
      if (this.isFundsSourceCash) {
        return this.availableCash;
      } else if (this.isFundsSourcePocket) {
        return this.pocketPrincipal;
      } else {
        throw "Invalid funds source";
      }
    },
  },

  watch: {
    fundsSource(value) {
      this.$emit("funds-source-changed", value);
      this.$emit("funds-source-amount-changed", this.fundsSourceAmount);
    },
  },

  mounted() {
    this.setActiveOption();
  },

  methods: {
    setActiveOption() {
      if (this.availableCash > 0) {
        this.fundsSource = FUNDS_SOURCE_CASH;
      } else if (this.pocketPrincipal > 0) {
        this.fundsSource = FUNDS_SOURCE_POCKET;
      }
    },
  },
};
</script>
