<template>
  <div class="offer-reject">
    <h1>Reject Buyout Offer</h1>
    <offer-details :buyout-offer="buyoutOffer" />
    <div v-if="offerRejected"
         class="offer-reject-banner offer-rejected">
      <p>You've rejected the buyout offer.</p>
    </div>
    <ul v-else
        class="offer-reject-actions-list">
      <li>
        <div class="form-group currency minimum-preferred-offer-amount-wrapper">
          <label for="minimum-preferred-offer-amount">
            Minimum Preferred Buyout Amount (optional)
            <span>
              <a v-popover="popoverProperties"
                 href="#">
                <i class="fa fa-question-circle" />
              </a>
              <span class="d-none">
                This information will help us improve the Secondary Marketplace,
                but as such, it is not a counter-bid.
              </span>
            </span>
          </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input id="minimum-preferred-offer-amount"
                   v-model="minimumPreferredOfferAmount"
                   v-mask:currency
                   type="text"
                   autocomplete="off"
                   class="form-control currency required form-control input-lg">
          </div>
        </div>
      </li>
      <li v-if="hasErrors"
          class="error-messages">
        <div class="offer-reject-banner">
          <p>Error while rejecting offer.</p>
        </div>
      </li>
      <li>
        <button type="button"
                class="btn btn-primary-cta confirm-rejection-button"
                @click="confirmRejection">
          Confirm Rejection
        </button>
      </li>
      <li>
        <button type="button"
                class="btn cancel-button"
                @click="cancelRejection">
          Cancel
        </button>
      </li>
    </ul>
  </div>
</template>

<script>

import BuyoutOfferService from "services/secondary_market/buyout_offer_service";
import Mask from "directives/inputs";
import NumberFormatter from "formatters/number_formatter";
import OfferDetails from "components/secondary_market/offer_details";
import Segment from "services/segment";

export const OFFER_REJECTED_EVENT = "offer-rejected";
export const OFFER_REJECTION_CANCELLED_EVENT = "offer-rejection-canceled";

const TRACK_OFFER_REJECT = "Offer Reject";

export default {
  name: "OfferReject",
  components: {
    OfferDetails,
  },
  directives: {
    mask: {
      bind: Mask.bind,
      unbind: Mask.unbind,
    },
  },
  props: {
    buyoutOffer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      minimumPreferredOfferAmount: null,
      offerRejected: false,
      errors: [],
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
    popoverProperties() {
      return { placement: this.isMobileDevice ? "bottom" : "right" };
    },
  },
  mounted() {
    this.trackShowOfferReject();
  },
  methods: {
    sanitizedMinimumPreferredOfferAmount() {
      return NumberFormatter.unmaskNumber(this.minimumPreferredOfferAmount);
    },
    async confirmRejection() {
      this.trackRejectOfferClick();

      let data = await BuyoutOfferService.reject(
        this.buyoutOffer, this.sanitizedMinimumPreferredOfferAmount());

      if (data.errors) {
        this.errors = data.errors;
      } else {
        this.errors = [];
        this.offerRejected = true;
        this.$emit(OFFER_REJECTED_EVENT);
      }
    },
    cancelRejection() {
      this.$emit(OFFER_REJECTION_CANCELLED_EVENT);
    },
    trackShowOfferReject() {
      Segment.track("pageView", { category: TRACK_OFFER_REJECT, label: "Offer Reject Displayed" });
    },
    trackRejectOfferClick() {
      Segment.track("click", { category: TRACK_OFFER_REJECT, label: "Reject Offer Clicked" });
    },
  },
};
</script>
