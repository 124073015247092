<template>
  <div v-cloak
       class="buyout-offer-modal modal fade"
       aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a class="close"
             aria-hidden="true"
             href="#"
             @click.prevent="closeModal">
            <i class="fa fa-times" />
          </a>
        </div>
        <div class="modal-body">
          <offer-review v-if="showOfferReview"
                        :buyout-offer="buyoutOffer"
                        @offer-reject="onOfferReject"
                        @offer-accepted="onOfferAccepted" />
          <offer-reject v-if="showOfferReject"
                        :buyout-offer="buyoutOffer"
                        @offer-rejection-canceled="onOfferRejectionCanceled"
                        @offer-rejected="onOfferRejected" />
          <div v-if="showCloseButton"
               class="close-button-wrapper">
            <button type="button"
                    class="btn"
                    @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import OfferReject from "components/secondary_market/offer_reject";
import OfferReview from "components/secondary_market/offer_review";

export const OPEN_BUYOUT_OFFER_MODAL_EVENT = "open-buyout-offer-modal";

export const OFFER_NONE_STATE = "none";
export const OFFER_REVIEW_STATE = "review";
export const OFFER_REJECT_STATE = "reject";
export const OFFER_ACCEPTED_STATE = "accepted";
export const OFFER_REJECTED_STATE = "rejected";

function defaultData() {
  return {
    state: OFFER_NONE_STATE,
    buyoutOffer: {},
  };
}

export default {
  name: "OfferModal",
  components: {
    OfferReject,
    OfferReview,
  },
  data() {
    return defaultData();
  },
  computed: {
    showOfferReview() {
      return this.state === OFFER_REVIEW_STATE || this.state === OFFER_ACCEPTED_STATE;
    },
    showOfferReject() {
      return this.state === OFFER_REJECT_STATE || this.state === OFFER_REJECTED_STATE;
    },
    hasActedOnOffer() {
      return this.state === OFFER_ACCEPTED_STATE || this.state === OFFER_REJECTED_STATE;
    },
    showCloseButton() {
      return this.hasActedOnOffer;
    },
  },
  mounted() {
    PS.globalBus.$on(OPEN_BUYOUT_OFFER_MODAL_EVENT, (params) => {
      this.openModal(params.buyoutOffer, params.showReject);
    });
  },
  methods: {
    openModal(buyoutOffer, showReject) {
      const modal = $(this.$el);

      this.buyoutOffer = buyoutOffer;
      this.state = showReject ? OFFER_REJECT_STATE : OFFER_REVIEW_STATE;
      modal.one("hide.bs.modal", () => this.closeModal());
      modal.modal("show");
    },
    closeModal() {
      if (this.hasActedOnOffer) {
        PS.Services.LocationService.reload();
      } else {
        $(this.$el).modal("hide");
        Object.assign(this.$data, defaultData());
      }
    },
    onOfferAccepted() {
      this.state = OFFER_ACCEPTED_STATE;
    },
    onOfferReject() {
      this.state = OFFER_REJECT_STATE;
    },
    onOfferRejectionCanceled() {
      this.state = OFFER_REVIEW_STATE;
    },
    onOfferRejected() {
      this.state = OFFER_REJECTED_STATE;
    },
  },
};
</script>
