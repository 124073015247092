<template>
  <div class="pocket-investment-tabs">
    <div
      class="pocket-tab pocket-tab-recurring-deposit"
      :class="showRecurringForm && 'active-pocket-tab'"
      @click="openRecurring">
      Recurring Deposit
      <TabBadge />
    </div>

    <div class="pocket-tab pocket-tab-one-time-deposit"
         :class="!showRecurringForm && 'active-pocket-tab'"
         @click="openOneTime">
      One-Time Deposit
    </div>
  </div>
</template>

<script>
import TabBadge from "./tab_badge.vue";

import ModalStore, { TABS } from "./store";

export default {
  components: {
    TabBadge,
  },

  data: function() {
    return {
      modalStoreData: ModalStore.data,
    };
  },

  computed: {
    showRecurringForm() {
      return this.modalStoreData.activeTab === TABS.RECURRING;
    },
  },

  methods: {
    openOneTime() {
      ModalStore.openOneTimeTab();
    },

    openRecurring() {
      ModalStore.openRecurringTab();
    },
  },
};
</script>
