import InvestButton from "components/invest/button";
import LearningResourcesSlider from "components/learning_resources_slider";
import MarketingBanner from "components/marketing_banner";
import PromoBanner from "components/promo/banner";
import BankruptcyClaimBanner from "components/bankruptcy_claim_banner";
import BannerV3Slider from "components/banner_v3_slider";

class LoansListController {
  onDomReady($element) {
    new Vue({
      el: $element.get(0),
      components: {
        BannerV3Slider,
        InvestButton,
        LearningResourcesSlider,
        MarketingBanner,
        PromoBanner,
        BankruptcyClaimBanner,
      },
      data: {
        loans: PSData.loans || [],
      },
      created: function() {
        PS.globalBus.$on("loanExpanded", this.collapse);
      },
      beforeDestroy: function() {
        PS.globalBus.$off("loanExpanded", this.collapse);
      },
      methods: {
        collapse: function(loanId) {
          PS.globalBus.$emit("closeOthers", loanId);
        },
      },
    });
  }
}

window.PS.Controllers.LoansListController = LoansListController;
PS.Components.register(LoansListController, { applyOnlyTo: ".v-loans-controller" });
