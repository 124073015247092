<template>
  <div class="offer-details">
    <div class="disclosure">
      <p>
        This is a limited time offering from buyers on our marketplace that's filled on a first-come, first-served basis.
        To learn more, click <a target="_blank"
                                :href="faqPath">here</a>.
      </p>
    </div>
    <ul class="offer-details-list">
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Your investment
        </div>
        <div>{{ buyoutOffer.investmentAmount | formatMoney }}</div>
      </li>
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Interest earned $
        </div>
        <div>{{ buyoutOffer.interestEarned | formatMoney }}</div>
      </li>
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Outstanding principal
        </div>
        <div>{{ buyoutOffer.outstandingPrincipal | formatMoney }}</div>
      </li>
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Offer amount
        </div>
        <div>{{ buyoutOffer.offerAmount | formatMoney }}</div>
      </li>
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Discount to par
        </div>
        <div>{{ discountToParPercent | formatPercent }}</div>
      </li>
      <li class="divider" />
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Total returned $
        </div>
        <div>{{ buyoutOffer.totalReturn | formatMoney }}</div>
      </li>
      <li class="offer-details-list-item">
        <div class="offer-details-list-item-label">
          Annual return %
        </div>
        <div>{{ annualReturnRatePercent | formatPercent }}</div>
      </li>
    </ul>
  </div>
</template>

<script>

import BuyoutOffer from "models/secondary_market/buyout_offer";
import NumberFormatter from "formatters/number_formatter";

export const { formatMoney, formatPercent } = NumberFormatter;

export default {
  name: "OfferDetails",
  filters: {
    formatMoney,
    formatPercent,
  },
  props: {
    buyoutOffer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    faqPath() {
      return BuyoutOffer.offerFAQPath;
    },
    discountToParPercent() {
      return this.buyoutOffer.discountToPar * 100;
    },
    annualReturnRatePercent() {
      return this.buyoutOffer.annualReturnRate * 100;
    },
  },
};
</script>
