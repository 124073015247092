<template>
  <table class="auto-deposits-show table table-striped">
    <tbody>
      <tr>
        <th>Deposit Amount</th>
        <td class="deposit-amount">
          {{ settings.amount }}
        </td>
      </tr>
      <tr>
        <th>Frequency</th>
        <td class="deposit-frequency">
          {{ settings.frequencyText }}
        </td>
      </tr>
      <tr>
        <th>Day of the Month</th>
        <td v-if="displayDayOfMonth"
            class="deposit-day-of-month">
          {{ settings.dayOfMonth }}
        </td>
        <td v-else
            class="deposit-day-of-month">
          N/A
        </td>
      </tr>
      <tr>
        <th>Day of the Week</th>
        <td v-if="displayDayOfWeek"
            class="deposit-day-of-week">
          {{ settings.dayOfWeek }}
        </td>
        <td v-else
            class="deposit-day-of-week">
          N/A
        </td>
      </tr>
      <tr>
        <th>Next Deposit Date</th>
        <td class="deposit-next-date">
          {{ settings.nextDepositDate }}
        </td>
      </tr>
      <tr>
        <th>Active?</th>
        <td v-if="active"
            class="deposit-active deposit-enabled">
          <span class="badge badge-success">Yes</span>
        </td>
        <td v-else>
          <span class="badge badge-warning deposit-enabled">No</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AutoDepositsShow",
  data() {
    return {
      settings: PSData.autoDeposits.settings,
    };
  },
  computed: {
    active() {
      return this.settings.enabled === true;
    },
    displayDayOfMonth() {
      return this.settings.frequency === "monthly";
    },
    displayDayOfWeek() {
      return (this.settings.frequency === "weekly" || this.settings.frequency === "every_other_week");
    },
  },
};
</script>
