<template>
  <div class="card-active table-card">
    <div class="card-heading">
      <h4>
        {{ total | money(2, { plus: true }) }}
      </h4>
    </div>
    <table class="table table-hover">
      <tbody>
        <tr class="parent">
          <td class="label">
            Loans Earnings
          </td>
          <td class="value">
            {{ loansTotal | money(2) }}
          </td>
        </tr>

        <tr class="sub-group">
          <td class="label">
            Interest
          </td>
          <td class="value">
            {{ interests | money(2) }}
          </td>
        </tr>

        <tr class="sub-group">
          <td class="label">
            Fees
          </td>
          <td class="value">
            {{ fees | money(2) }}
          </td>
        </tr>

        <tr class="sub-group">
          <td class="label">
            Gain on Sale
          </td>
          <td class="value">
            {{ gainOnSale | money(2) }}
          </td>
        </tr>

        <tr class="sub-group">
          <td class="label">
            Charge Off
          </td>
          <td class="value">
            {{ chargeOffs | money(2) }}
          </td>
        </tr>

        <tr class="sub-group">
          <td class="label">
            Other
            <a v-popover="{placement: 'right'}"
               class="tooltip-toggle"
               href="#">
              <i class="fa fa-question-circle" />
            </a>
            <span class="d-none">
              {{ otherTooltipText }}
            </span>
          </td>
          <td class="value">
            {{ other | money(2) }}
          </td>
        </tr>

        <tr class="parent">
          <td class="label">
            Pocket
          </td>
          <td class="value">
            {{ pocketTotal | money(2) }}
          </td>
        </tr>

        <tr class="sub-group">
          <td class="label">
            Interest
          </td>
          <td class="value">
            {{ pocketInterest | money(2) }}
          </td>
        </tr>

        <tr v-if="showPocketChargeOffs"
            class="sub-group">
          <td class="label">
            Charge Off
          </td>
          <td class="value">
            {{ pocketChargeOffs | money(2) }}
          </td>
        </tr>

        <tr v-if="investmentFundsEnabled"
            class="parent">
          <td class="label">
            Portfolio Earnings
          </td>
          <td class="value">
            {{ portfolioEarnings | money(2) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "EarningsBreakdown",

  props: {
    breakdown: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      investmentFundsEnabled: PSData.investmentFunds.enabled,
    };
  },

  computed: {
    total() {
      return this.breakdown.total;
    },
    loansTotal() {
      return this.breakdown.loansTotal;
    },
    interests() {
      return this.breakdown.interest;
    },
    fees() {
      return this.breakdown.fees;
    },
    gainOnSale() {
      return this.breakdown.gainOnSale;
    },
    chargeOffs() {
      return this.breakdown.chargeOffs;
    },
    pocketTotal() {
      return this.breakdown.pocketTotal;
    },
    pocketInterest() {
      return this.breakdown.pocketInterest;
    },
    pocketChargeOffs() {
      return this.breakdown.pocketChargeOffs;
    },
    portfolioEarnings() {
      return this.breakdown.portfolioEarnings;
    },
    other() {
      return this.breakdown.other;
    },
    showPocketChargeOffs() {
      return this.pocketChargeOffs !== 0;
    },
    otherTooltipText() {
      return "Earnings from miscellaneous and supplemental payments and/or from rental income in other uncommon scenarios";
    },
  },
};
</script>
